import { portalFunctions } from 'core/functions/portalFunctions'

export interface ThumbnailRequest {
  firebasePath: string
  url: string
}

interface ThumbnailsRequest {
  scrapeThumbnailRequests: ThumbnailRequest[]
}

export async function loadThumbnailsinFirebase(thumnailsRequest: ThumbnailsRequest): Promise<null[]> {
  try {
    // const response = await fetch(`${functionUrls.downloadThumbnails}`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(thumnailsRequest),
    // })

    // if (!response.ok) {
    //   throw new Error(`HTTP error! status: ${response.status}`)
    // }

    // const data = await response.json()

    // TODO PORTAL V3 NOT IMPLEMENTED YET

    return []
  } catch (error) {
    console.error('Error downloading thumbnail:', error)
    return []
  }
}
