import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  useTheme,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material'
import { Application, ApplicationStatus } from '@otw/models'
import { formatDateToLocalTime } from '../../../../core/utils/formatDateToLocalTime'
import ViewNotesDialog from './ViewNotesDialog'
import ApproveOrDenyDialog from './ApproveOrDenyDialog'
import ViewDocumentsDialog from './ViewDocumentsDialog'
import { pandaFunctions } from 'core/functions/pandaFunctions'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'
import NotesIcon from '@mui/icons-material/Notes'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import { getStatusColor, getStatusLabel, getStatusDescription } from '../../../../core/constants/applicationStatus'
import InfoIcon from '@mui/icons-material/Info'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface BankApplicationBoardProps {
  applications: Application[]
}

const BankApplicationBoard: React.FC<BankApplicationBoardProps> = ({ applications }) => {
  const theme = useTheme()
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState<Application | null>(null)
  const [viewNotesDialogOpen, setViewNotesDialogOpen] = useState(false)
  const [approveOrDenyDialogOpen, setApproveOrDenyDialogOpen] = useState(false)
  const [viewDocumentsDialog, setViewDocumentsDialog] = useState(false)
  const [documents, setDocuments] = useState<Record<string, string> | undefined>(undefined)
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)

  console.log('applications in board', applications)

  //   const filteredApplications = applications
  //     .filter(app => app.status !== 'signing')
  //     .filter(app => {
  //       if (statusFilter === 'all') return true
  //       return app.status === statusFilter
  //     })

  const handleViewDocument = async (application: Application) => {
    setLoadingDialogOpen(true)
    setSelectedApplication(application)

    try {
      setDocumentUrl(application.pandadoc_url || '')
      setIsDialogOpen(true)
    } catch (error) {
      console.error('Error fetching document:', error)
      setErrorDialogOpen(true)
    } finally {
      setLoadingDialogOpen(false)
    }
  }

  const handleOpenViewDocumentsDialog = async (application: Application) => {
    if (!application.id) {
      throw new Error('Application ID is required')
    }
    setIsLoadingDocuments(true)
    setViewDocumentsDialog(true)
    try {
      const response = await pandaFunctions.shared.getApplicationDocuments(application.id)
      if (!response || Object.keys(response).length === 0) {
        setDocuments(undefined)
      } else {
        setDocuments(response)
      }
      setSelectedApplication(application)
    } catch (error) {
      console.error('Error fetching documents:', error)
      setDocuments(undefined)
    } finally {
      setIsLoadingDocuments(false)
    }
  }

  const canShowActions = (status: ApplicationStatus): boolean => {
    return !['signing', 'ready'].includes(status)
  }

  return (
    <Box>
      <ViewNotesDialog
        application={selectedApplication}
        isOpen={viewNotesDialogOpen}
        onClose={() => setViewNotesDialogOpen(false)}
      />

      <ViewDocumentsDialog
        application={selectedApplication}
        isOpen={viewDocumentsDialog}
        onClose={() => {
          setViewDocumentsDialog(false)
          setDocuments(undefined)
        }}
        documents={documents}
        isLoading={isLoadingDocuments}
      />

      <ApproveOrDenyDialog
        application={selectedApplication}
        isOpen={approveOrDenyDialogOpen}
        onClose={() => setApproveOrDenyDialogOpen(false)}
      />

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth='lg'
        fullWidth
        PaperProps={{
          sx: {
            height: 'calc(100vh - 64px)',
            m: 2,
            borderRadius: 2,
          },
        }}
      >
        <DialogContent sx={{ p: 0, height: '100%' }}>
          {documentUrl && (
            <iframe
              src={documentUrl}
              style={{
                height: '100%',
                width: '100%',
                border: 'none',
              }}
              allowFullScreen
              title='Application Document'
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>
            There was an error loading the document. Please try again or submit a support request.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button href='/support-request'>Submit Support Request</Button>
          <Button onClick={() => setErrorDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={loadingDialogOpen} onClose={() => setLoadingDialogOpen(false)}>
        <DialogTitle>Loading Document..</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      {applications.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 8 }}>
          <Typography variant='h6' color='text.secondary'>
            No applications found
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {applications.map(application => (
            <Grid item xs={12} sm={6} md={4} key={application.id}>
              <Paper
                elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  p: 3,
                  borderRadius: 2,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': canShowActions(application.status as ApplicationStatus) ? {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[8],
                  } : {},
                  cursor: canShowActions(application.status as ApplicationStatus) ? 'pointer' : 'default',
                  border: '1px solid',
                  borderColor: 'divider',
                }}
                onClick={() => canShowActions(application.status as ApplicationStatus) ? setSelectedApplication(application) : null}
              >
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Application ID
                    </Typography>
                    <Typography variant='body1' fontWeight={500}>
                      {application.id}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Client Email
                    </Typography>
                    <Typography variant='body1' fontWeight={500}>
                      {application.user_email}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Submitted
                    </Typography>
                    <Typography variant='body1' fontWeight={500}>
                      {formatDateToLocalTime(application.time_created)}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ position: 'relative' }}>
                      <Chip
                        label={getStatusLabel(application.status as ApplicationStatus)}
                        sx={{
                          px: 1,
                          height: 28,
                          borderRadius: '14px',
                          bgcolor: getStatusColor(application.status as ApplicationStatus).light,
                          color: getStatusColor(application.status as ApplicationStatus).main,
                          fontWeight: 600,
                          fontSize: '0.85rem',
                          '& .MuiChip-label': {
                            px: 1,
                          },
                        }}
                      />
                      <Tooltip 
                        title={getStatusDescription(application.status as ApplicationStatus)} 
                        arrow
                        placement="top"
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: -6,
                            right: -6,
                            bgcolor: 'background.paper',
                            borderRadius: '50%',
                            width: 18,
                            height: 18,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            color: getStatusColor(application.status as ApplicationStatus).main,
                            transition: 'all 0.2s ease',
                            boxShadow: theme.shadows[1],
                            '&:hover': {
                              transform: 'scale(1.1)',
                              boxShadow: theme.shadows[2],
                            },
                            zIndex: 1,
                          }}
                        >
                          <HelpOutlineIcon sx={{ fontSize: 14 }} />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Box 
                  sx={{ 
                    mt: 3,
                    pt: 3,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    minHeight: '52px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {canShowActions(application.status as ApplicationStatus) ? (
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', width: '100%' }}>
                      <Button
                        variant='outlined'
                        size='small'
                        startIcon={<VisibilityIcon />}
                        onClick={e => {
                          e.stopPropagation()
                          handleViewDocument(application)
                        }}
                        sx={{ flex: 1 }}
                      >
                        View
                      </Button>
                      <Button
                        variant='outlined'
                        size='small'
                        startIcon={<DownloadIcon />}
                        onClick={e => {
                          e.stopPropagation()
                          handleOpenViewDocumentsDialog(application)
                        }}
                        sx={{ flex: 1 }}
                      >
                        Documents
                      </Button>
                      <Button
                        variant='outlined'
                        size='small'
                        startIcon={<NotesIcon />}
                        onClick={e => {
                          e.stopPropagation()
                          setSelectedApplication(application)
                          setViewNotesDialogOpen(true)
                        }}
                        sx={{ flex: 1 }}
                      >
                        Notes
                      </Button>
                      {(application.status === 'pending' || application.status === 'viewed') && (
                        <Button
                          variant='contained'
                          size='small'
                          startIcon={<ThumbUpAltIcon />}
                          onClick={e => {
                            e.stopPropagation()
                            setSelectedApplication(application)
                            setApproveOrDenyDialogOpen(true)
                          }}
                          sx={{ 
                            flex: 1,
                            bgcolor: theme.palette.success.main,
                            '&:hover': {
                              bgcolor: theme.palette.success.dark,
                            },
                          }}
                        >
                          Review
                        </Button>
                      )}
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                      {application.status === 'ready' 
                        ? 'Waiting for Optic review'
                        : 'Application is being signed'}
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default BankApplicationBoard
