import { Button, Grid, Typography, Paper, CircularProgress, Box, Alert } from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { PandaDocument } from '@otw/models'
import DescriptionIcon from '@mui/icons-material/Description'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PandadocFrame from './PandadocFrame'
import { useClientApplication } from 'hooks/client/useClientApplication'

const ApplicationNew = () => {
  const [iframeUrl, setIframeUrl] = useState('')
  const [formCompleted, setFormCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()

  const { 
    generateApplication, 
    submitApplication,
    isLoading: isGenerating, 
    error: generateError,
    submitError 
  } = useClientApplication()

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setFormCompleted(true)
    setLoading(true)

    generateApplication(undefined, {
      onSuccess: (url) => {
        if (url) {
          setIframeUrl(url)
          setLoading(false)
        } else {
          showSnackbar('Failed to generate application URL. Please try again.', 'error')
          setFormCompleted(false)
          setLoading(false)
        }
      },
      onError: () => {
        showSnackbar('Failed to generate application. Please try again.', 'error')
        setFormCompleted(false)
        setLoading(false)
      }
    })
  }

  const onFinishAppliction = async (pandaPayload: PandaDocument) => {
    try {
      await submitApplication(pandaPayload)
      showSnackbar('Application Submitted Successfully!', 'success')
      navigate('/client')
    } catch (error) {
      showSnackbar('Failed to submit application. Please try again.', 'error')
    }
  }

  if (isGenerating || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh" flexDirection="column">
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6" mt={3}>
          Preparing your application...
        </Typography>
      </Box>
    )
  }

  return (
    <Box p={3}>
      {!formCompleted ? (
        <Paper elevation={3} sx={{ p: 4, maxWidth: 800, mx: 'auto', borderRadius: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <DescriptionIcon sx={{ fontSize: 60, color: 'primary.main', mb: 3 }} />
            
            <Typography variant="h4" component="h1" gutterBottom fontWeight="500">
              Start Your Financing Application
            </Typography>
            
            <Typography variant="h6" color="text.secondary" paragraph>
              We've partnered with PandaDoc to provide you with a secure and streamlined financing process.
            </Typography>

            <Box my={4}>
              <Typography variant="body1" paragraph>
                Before you begin, please have the following information ready:
              </Typography>
              <Grid container spacing={2} sx={{ textAlign: 'left', mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Typography component="div">
                    • Business Information
                    <Typography variant="body2" color="text.secondary" ml={2}>
                      Legal name, address, tax ID
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography component="div">
                    • Financial Information
                    <Typography variant="body2" color="text.secondary" ml={2}>
                      Bank statements, revenue details
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography component="div">
                    • Personal Information
                    <Typography variant="body2" color="text.secondary" ml={2}>
                      ID, contact details
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography component="div">
                    • Vehicle Information
                    <Typography variant="body2" color="text.secondary" ml={2}>
                      Details of vehicles to be financed
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {(generateError || submitError) && (
              <Alert severity="error" sx={{ mb: 3, width: '100%' }}>
                {generateError instanceof Error ? generateError.message : submitError instanceof Error ? submitError.message : 'An error occurred'}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  px: 6,
                  py: 1.5,
                  borderRadius: 2,
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  boxShadow: theme => theme.shadows[4],
                  '&:hover': {
                    boxShadow: theme => theme.shadows[8],
                  },
                }}
              >
                Begin Application
              </Button>
            </form>
          </Box>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ p: 3, height: 'calc(100vh - 150px)', borderRadius: 2 }}>
          <Typography variant="body1" gutterBottom textAlign="center" sx={{ mb: 3 }}>
            Complete your application below. If you need assistance, please contact{' '}
            <Box component="span" sx={{ color: 'primary.main', fontWeight: 500 }}>
              support@optictruckworks.com
            </Box>
          </Typography>
          
          <Box sx={{ height: 'calc(100% - 60px)' }}>
            <PandadocFrame
              loading={loading}
              iframeUrl={iframeUrl}
              onFormCompleted={onFinishAppliction}
              onIframeLoad={() => setLoading(false)}
            />
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default ApplicationNew
