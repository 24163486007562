import { Box, CircularProgress, Typography } from '@mui/material'
import { PandaDocument } from '@otw/models'
import React, { useEffect } from 'react'

interface PandadocFrameProps {
  iframeUrl: string
  loading: boolean
  onFormCompleted: (payload: PandaDocument) => void
  onIframeLoad: () => void
}

const PandadocFrame: React.FC<PandadocFrameProps> = ({ iframeUrl, onFormCompleted, loading, onIframeLoad }) => {
  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        onFormCompleted(payload)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onFormCompleted])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
        <Typography sx={{ mr: 2 }}>Application Loading..</Typography>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ mb: 10, width: '100%', height: 'calc(100vh - 64px)' }}>
      <iframe
        src={iframeUrl}
        style={{
          width: '100%',
          height: '100%',
          display: iframeUrl ? 'block' : 'none',
        }}
        frameBorder='0'
        allowFullScreen
        scrolling='no'
        title='Embedded Content'
        onLoad={onIframeLoad}
      />
    </Box>
  )
}

export default PandadocFrame
