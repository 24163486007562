import React, { useState, useEffect } from 'react'
import { Box, Typography, Paper } from '@mui/material'
import { NotificationPreferences } from '@otw/models'
import { NotificationType } from '@otw/models/notifications/enums'
import NotificationSection from './components/NotificationSection'
import { useAuth } from 'contexts/AuthContext'
import { useNotifications } from 'hooks/useNotifications'
import { NotificationList } from './components/NotificationList'

interface NotificationCenterProps {
  className?: string
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({ className }) => {
  const { userInfo } = useAuth()
  const { updateNotificationPreferences } = useNotifications(userInfo?.id || '')

  // Get all notification types from the enum
  const notificationTypes: NotificationType[] = Object.values(NotificationType)

  // Initialize preferences with default values for all notification types
  const getInitialPreferences = (): NotificationPreferences => {
    const defaultPreferences = Object.fromEntries(
      notificationTypes.map(type => [type, { email: false, sms: false }])
    ) as NotificationPreferences

    if (userInfo?.notifications) {
      // Merge user preferences with default preferences to ensure all types have values
      return {
        ...defaultPreferences,
        ...userInfo.notifications
      }
    }

    return defaultPreferences
  }

  const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreferences>(getInitialPreferences())

  // Update preferences when userInfo changes
  useEffect(() => {
    setNotificationPreferences(getInitialPreferences())
  }, [userInfo?.notifications])

  const [expandedSubsections, setExpandedSubsections] = useState<Set<string>>(new Set())

  // Dynamically create sections based on notification types
  const notificationSections = [
    {
      title: 'Inventory Notifications',
      types: notificationTypes.filter(type => type.includes('inventory_')),
    },
    {
      title: 'User Notifications',
      types: notificationTypes.filter(type => type.includes('user_')),
    },
    {
      title: 'Application Notifications',
      types: notificationTypes.filter(type => type.includes('application_')),
    },
  ]

  const handleSubsectionExpand = (subsection: string) => {
    setExpandedSubsections(prev => {
      const newSet = new Set(prev)
      if (newSet.has(subsection)) {
        newSet.delete(subsection)
      } else {
        newSet.add(subsection)
      }
      return newSet
    })
  }

  if (userInfo?.role !== 'admin') {
    return null
  }

  return (
    <>
      <Typography variant='h5' sx={{ mb: 4, fontWeight: 600 }}>
        Notification Center
      </Typography>

      <Paper className={className} elevation={2} sx={{ p: 4, borderRadius: 2, mb: 4 }}>
        <Typography variant='h5' sx={{ mb: 4, fontWeight: 600 }}>
          Preferences
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {notificationSections.map(section => (
            <NotificationSection
              key={section.title}
              title={section.title}
              expandedSubsections={expandedSubsections}
              notificationPreferences={notificationPreferences}
              handleSubsectionExpand={handleSubsectionExpand}
              notificationTypes={section.types as NotificationType[]}
              setNotificationPreferences={setNotificationPreferences}
            />
          ))}
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <NotificationList />
      </Box>
    </>
  )
}

export default NotificationCenter
