import React, { useEffect, useState } from 'react'
import { Button, TextField, Link, Grid, Box, useTheme, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { authTextFieldStyle } from 'core/theme'
import { errorMessages } from 'core/errorMessages'
import { FcGoogle } from 'react-icons/fc'
import { AuthErrorDialog } from 'components/AuthErrorDialog'
import { useMFA } from 'hooks/auth/useMFA'
import { MultiFactorError } from '@firebase/auth'
import { FirebaseError } from 'firebase/app'

const Login = () => {
  const navigate = useNavigate()
  const { loginWithEmail, loginWithGoogle, handleVerifyActionCode } = useAuth()
  const { handleMFAError } = useMFA()
  const [openDialog, setOpenDialog] = useState(false)
  const [resetToken, setResetToken] = useState('')
  const [dialogContent, setDialogContent] = useState({
    title: '',
    errorMessage: '',
    showResetPassword: false,
    showResendVerification: false,
    email: '',
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const googleError = searchParams.get('googleError')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const credentials = {
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    }

    try {
      await loginWithEmail(credentials)
    } catch (error: any) {
      handleLoginError(error as MultiFactorError | FirebaseError)
    }
  }

  const openLoginWithGooglePopup = async () => {
    // Clear any previous errors
    setSearchParams(params => {
      params.delete('googleError')
      return params
    })

    try {
      await loginWithGoogle()
    } catch (error: any) {
      handleLoginError(error as MultiFactorError | FirebaseError)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    navigate('/login')
  }

  const handleLoginError = (error: MultiFactorError | FirebaseError) => {
    if (error.code === 'auth/multi-factor-auth-required') {
      const mfaResolver = handleMFAError(error as MultiFactorError)
      if (mfaResolver) {
        navigate('/login-mfa-code')
        return
      }
    }

    if (error.code === 'auth/invalid-credential') {
      setDialogContent({
        title: 'Login Error',
        errorMessage: 'Incorrect email or password. Please try again.',
        showResendVerification: false,
        showResetPassword: false,
        email: '',
      })
      setOpenDialog(true)
      return
    }

    if (error.code === 'auth/invalid-email') {
      setDialogContent({
        title: 'Login Error',
        errorMessage: 'Invalid email address. Please try again.',
        showResendVerification: false,
        showResetPassword: false,
        email: '',
      })
      setOpenDialog(true)
      return
    } else {
      setDialogContent({
        title: 'Login Error',
        errorMessage: 'Incorrect email or password. Please try again.',
        showResendVerification: false,
        showResetPassword: false,
        email: '',
      })
      setOpenDialog(true)
    }
  }

  const handleNavigateForgotPassword = () => {
    navigate('/forgot-password')
  }

  return (
    <>
      <AuthErrorDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogContent.title}
        errorMessage={dialogContent.errorMessage}
        showResetPassword={dialogContent.showResetPassword}
        showResendVerification={dialogContent.showResendVerification}
        resetToken={resetToken}
        email={dialogContent.email}
      />
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ width: '100%', mt: 4 }}>
        <TextField
          required
          fullWidth
          margin='normal'
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          sx={{
            ...authTextFieldStyle,
            mb: 3,
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
              '&.Mui-focused': {
                color: 'black',
              },
            },
          }}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin='normal'
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          sx={{
            ...authTextFieldStyle,
            mb: 4,
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
              '&.Mui-focused': {
                color: 'black',
              },
            },
          }}
        />

        <Button
          fullWidth
          type='submit'
          variant='contained'
          color="primary"
          sx={{
            mt: 2,
            mb: 3,
          }}
        >
          Sign In
        </Button>

        <Button
          fullWidth
          variant='contained'
          sx={{
            backgroundColor: '#4285F4',
            color: 'white',
            '&:hover': {
              backgroundColor: '#357ae8',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1,
            mb: 1,
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          Login with Google
        </Button>

        {googleError && (
          <Typography
            color='error'
            variant='body2'
            sx={{
              mt: 2,
              mb: 1,
              textAlign: 'center',
            }}
          >
            {googleError}
          </Typography>
        )}

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs>
            <Link onClick={handleNavigateForgotPassword} variant='body2' sx={{ color: 'black' }}>
              {'Forgot password?'}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Login
