import { ArrowBack, Close, Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import {
  getVehicleModelOptions,
  getVehicleSizeOptions,
  makeOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'
import { Formula } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useFormulaRules } from 'hooks/graybook/useFormulaRules'
import React, { useState } from 'react'
import DeprecationFormulaBuilder from './views/DepreciationFormulaBuilder'
import FormulaInventoryStats from './components/FormulaInventoryStats'
import { useSessionStorageState } from '@toolpad/core'
import { useFormulas } from 'hooks/graybook/useFormulas'
// TODO: abstract the listItem into a component

const DepreciationFormulas = () => {
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [type, setType] = useState('')
  const [size, setSize] = useState('')
  const [fuelType, setFuelType] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { userInfo } = useAuth()
  const [selectedFormula, setSelectedFormula] = useState<Formula | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedName, setEditedName] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [view, setView] = useState<'create' | 'list'>('list')
  const isMobile = useIsMobile()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('')
  const [viewMode, setViewMode] = useSessionStorageState<'list' | 'coverage'>('formula-view-mode', 'list')

  const { formulas, isLoading, addFormula, updateFormula, deleteFormula } = useFormulas({
    formulasCollection: 'gray_book_formulas',
  })

  const { rules: sourceRules, addRule } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: selectedTemplateId
      ? {
          name: 'rules',
          parentDocId: selectedTemplateId,
        }
      : undefined,
    options: {
      dontShowUpdates: true,
    },
  })

  const filteredFormulas = React.useMemo(() => {
    if (!formulas) return []
    return formulas.filter(formula => formula.name.toLowerCase().includes(searchTerm.toLowerCase()))
  }, [formulas, searchTerm])

  const generateFormulaName = React.useMemo(() => {
    if (!type || !size || !fuelType || !userInfo?.first_name || !userInfo?.last_name) {
      return ''
    }

    const date = new Date()
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })
    const initials = userInfo.first_name.charAt(0).toUpperCase() + userInfo.last_name.charAt(0).toUpperCase()
    const formattedSize = size.charAt(0).toUpperCase() + size.slice(1)
    const formattedFuelType = fuelType.charAt(0).toUpperCase() + fuelType.slice(1)

    if (!make || !model) {
      return `${type} ${formattedSize} ${formattedFuelType} (${initials}-${formattedDate})`
    }

    const formattedMake = make.charAt(0).toUpperCase() + make.slice(1)
    const formattedModel = model.charAt(0).toUpperCase() + model.slice(1)

    return `${formattedMake} ${formattedModel} ${formattedSize} ${formattedFuelType} (${initials}-${formattedDate})`
  }, [model, make, size, fuelType, userInfo?.first_name, userInfo?.last_name])

  const handleSubmitFormula = async (
    formula: Omit<
      Formula,
      | 'id'
      | 'name'
      | 'createdAt'
      | 'updatedAt'
      | 'vehicleType'
      | 'vehicleSize'
      | 'vehicleMake'
      | 'vehicleModel'
      | 'vehicleFuel'
    >,
  ) => {
    if (!selectedFormula) {
      return
    }
    updateFormula({
      id: selectedFormula.id,
      data: {
        ...formula,
        updatedAt: new Date().toISOString(),
      },
    })
  }

  const handleCreateFormula = async () => {
    if (selectedTemplateId) {
      const templateFormula = formulas?.find(f => f.id === selectedTemplateId)
      if (templateFormula) {
        const newFormula = await addFormula({
          name: generateFormulaName,
          maxPrice: templateFormula.maxPrice,
          minPrice: templateFormula.minPrice,
          wholesaleDiscount: templateFormula.wholesaleDiscount,
          vehicleType: type,
          vehicleSize: size,
          vehicleMake: make,
          vehicleModel: model,
          vehicleFuel: fuelType,
        })
        if (sourceRules && newFormula) {
          for (const rule of sourceRules) {
            const { id, createdAt, createdBy, ...ruleData } = rule
            await addRule(ruleData, ['gray_book_formulas', newFormula.id, 'rules'])
          }
        }
      }
    } else {
      await addFormula({
        name: generateFormulaName,
        maxPrice: 100000,
        vehicleType: type,
        vehicleSize: size,
        vehicleMake: make,
        vehicleModel: model,
        vehicleFuel: fuelType,
      })
    }
    setActiveStep(0)
    setType('')
    setSize('')
    setMake('')
    setModel('')
    setFuelType('')
    setSelectedTemplateId('')
    if (isMobile) {
      setView('list')
    }
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box>
      {selectedFormula ? (
        <Box>
          <Box sx={{ mb: 3 }}>
            {isEditing ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5' component='span' sx={{ mr: 1 }}>
                  Edit Formula:{' '}
                </Typography>
                <TextField
                  autoFocus
                  value={editedName}
                  onChange={e => setEditedName(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      updateFormula({
                        id: selectedFormula.id,
                        data: {
                          ...selectedFormula,
                          name: editedName,
                          updatedAt: new Date().toISOString(),
                        },
                      })
                      setIsEditing(false)
                    }
                  }}
                  onBlur={() => setIsEditing(false)}
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: '1.5rem',
                      fontWeight: 400,
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: 0,
                    },
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? 2 : 4,
                }}
              >
                <Button variant='outlined' onClick={() => setSelectedFormula(null)} startIcon={<ArrowBack />}>
                  Back to Formula List
                </Button>

                <Typography
                  onDoubleClick={() => {
                    setIsEditing(true)
                    setEditedName(selectedFormula.name)
                  }}
                  variant='h4'
                  sx={{
                    textAlign: isMobile ? 'left' : 'inherit',
                  }}
                >
                  {editedName || selectedFormula.name}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant='h4'>Optic Gray Book</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={(e, newView) => newView && setViewMode(newView)}
                  size='small'
                >
                  <ToggleButton value='list'>Formula List</ToggleButton>
                  <ToggleButton value='coverage'>Coverage</ToggleButton>
                </ToggleButtonGroup>

                {isMobile && (
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, newView) => newView && setView(newView)}
                    size='small'
                  >
                    <ToggleButton value='list'>List</ToggleButton>
                    <ToggleButton value='create'>Create</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 4, flexDirection: isMobile ? 'column' : 'row' }}>
            {(!isMobile || view === 'create') && (
              <Box sx={{ width: isMobile ? '100%' : '25%' }}>
                <Stepper activeStep={activeStep} orientation='vertical' sx={{ mb: 4 }}>
                  <Step>
                    <StepLabel>Vehicle Type & Size</StepLabel>
                    <StepContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Select
                          value={type}
                          onChange={e => setType(e.target.value)}
                          required
                          displayEmpty
                          variant='outlined'
                        >
                          <MenuItem value=''>
                            <em>Select Type</em>
                          </MenuItem>
                          {vehicleTypeOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {type && (
                          <Select
                            value={size}
                            onChange={e => setSize(e.target.value)}
                            required
                            displayEmpty
                            variant='outlined'
                          >
                            <MenuItem value=''>
                              <em>Select Size</em>
                            </MenuItem>
                            {getVehicleSizeOptions(type).map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant='contained'
                          onClick={() => setActiveStep(prev => prev + 1)}
                          sx={{ mr: 1 }}
                          disabled={!type || !size}
                        >
                          Continue
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel>Make & Model</StepLabel>
                    <StepContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Select value={make} onChange={e => setMake(e.target.value)} required displayEmpty>
                          <MenuItem value=''>
                            <em>Select Make</em>
                          </MenuItem>
                          {makeOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {make && (
                          <Select
                            value={model}
                            onChange={e => setModel(e.target.value)}
                            required
                            displayEmpty
                            variant='outlined'
                          >
                            <MenuItem value=''>
                              <em>Select Model</em>
                            </MenuItem>
                            {getVehicleModelOptions(make).map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant='contained'
                          onClick={() => setActiveStep(prev => prev + 1)}
                          sx={{ mr: 1 }}
                          disabled={!make || !model}
                        >
                          Continue
                        </Button>
                        <Button onClick={() => setActiveStep(prev => prev - 1)} sx={{ mr: 1 }}>
                          Back
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel>Fuel Type</StepLabel>
                    <StepContent>
                      <Box>
                        <FormControl>
                          <RadioGroup value={fuelType} onChange={e => setFuelType(e.target.value)}>
                            <FormControlLabel value='gas' control={<Radio />} label='Gas' />
                            <FormControlLabel value='diesel' control={<Radio />} label='Diesel' />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant='contained'
                          onClick={() => setActiveStep(prev => prev + 1)}
                          sx={{ mr: 1 }}
                          disabled={!fuelType}
                        >
                          Continue
                        </Button>
                        <Button onClick={() => setActiveStep(prev => prev - 1)} sx={{ mr: 1 }}>
                          Back
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel>
                      Select Template{' '}
                      {activeStep === 3 && (
                        <>
                          for{' '}
                          <Typography
                            component='span'
                            sx={{
                              fontStyle: 'italic',
                              fontWeight: 500,
                              display: 'inline',
                            }}
                          >
                            {make && model
                              ? `${make.charAt(0).toUpperCase() + make.slice(1)} ${model.charAt(0).toUpperCase() + model.slice(1)} ${size.charAt(0).toUpperCase() + size.slice(1)} ${fuelType.charAt(0).toUpperCase() + fuelType.slice(1)}`
                              : `${type} ${size.charAt(0).toUpperCase() + size.slice(1)} ${fuelType.charAt(0).toUpperCase() + fuelType.slice(1)}`}
                          </Typography>
                        </>
                      )}{' '}
                      (Optional)
                    </StepLabel>
                    <StepContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant='body2' color='text.secondary'>
                          Click a formula to use as template, or continue to create empty formula
                        </Typography>

                        {selectedTemplateId && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              backgroundColor: 'grey.100',
                              borderRadius: 1,
                              p: 1,
                            }}
                          >
                            <Typography
                              variant='body2'
                              sx={{
                                flex: 1,
                                color: 'text.primary',
                                fontWeight: 500,
                              }}
                            >
                              <strong>Selected template:</strong>{' '}
                              {formulas?.find(f => f.id === selectedTemplateId)?.name}
                            </Typography>
                            <IconButton
                              size='small'
                              onClick={() => setSelectedTemplateId('')}
                              sx={{ color: 'text.secondary' }}
                            >
                              <Close fontSize='small' />
                            </IconButton>
                          </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                          <Button variant='contained' onClick={handleCreateFormula} sx={{ mr: 1 }}>
                            Create {selectedTemplateId ? 'from Template' : 'Empty'}
                          </Button>
                          <Button onClick={() => setActiveStep(prev => prev - 1)} sx={{ mr: 1 }}>
                            Back
                          </Button>
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                </Stepper>
              </Box>
            )}

            <Box sx={{ width: isMobile ? '100%' : '75%' }}>
              {viewMode === 'list' ? (
                <>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      placeholder='Search formulas...'
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      size='small'
                    />
                  </Box>

                  <List>
                    {filteredFormulas.map((formula, index) => (
                      <ListItem
                        key={formula.id}
                        onClick={() => {
                          if (activeStep === 3) {
                            // Toggle selection
                            setSelectedTemplateId(current => (current === formula.id ? '' : formula.id))
                          } else {
                            setSelectedFormula(formula)
                          }
                        }}
                        sx={{
                          backgroundColor:
                            selectedTemplateId === formula.id
                              ? 'rgba(25, 118, 210, 0.08)'
                              : index % 2 === 0
                                ? 'background.paper'
                                : 'action.hover',
                          '&:hover': {
                            backgroundColor:
                              selectedTemplateId === formula.id ? 'rgba(25, 118, 210, 0.12)' : 'action.selected',
                            cursor: 'pointer',
                          },
                          borderBottom: '1px solid',
                          borderColor: 'divider',
                          py: 2,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography variant='subtitle1' component='div' sx={{ fontWeight: 'medium' }}>
                              {formula.name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant='body2' color='text.secondary' sx={{ mt: 0.5 }}>
                              Created: {new Date(formula.createdAt || '').toLocaleString()}
                            </Typography>
                          }
                        />
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title='Delete'>
                            <IconButton
                              aria-label='delete'
                              onClick={event => {
                                event.stopPropagation()
                                deleteFormula(formula.id)
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </>
              ) : (
                <FormulaInventoryStats
                  formulas={formulas || []}
                  onFormulaClick={formula => setSelectedFormula(formula)}
                  onClickNewFormula={({ type, size, make, model, fuelType }) => {
                    setType(type)
                    setSize(size)
                    setMake(make)
                    setModel(model)
                    setFuelType(fuelType)
                    setViewMode('list')
                    setActiveStep(3)
                    if (isMobile) {
                      setView('create')
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        </>
      )}

      {selectedFormula && (
        <DeprecationFormulaBuilder formula={selectedFormula} submitFormula={handleSubmitFormula} />
      )}
    </Box>
  )
}

export default DepreciationFormulas
