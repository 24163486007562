import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  Security as SecurityIcon,
  QrCode2 as QrCodeIcon,
} from "@mui/icons-material";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  MultiFactorInfo,
} from "firebase/auth";
import { useAuth } from "hooks/auth/useAuth";
import { useMFA } from "hooks/auth/useMFA";
import * as QRCode from "qrcode";

const steps = ["Verify Identity", "Scan QR Code", "Enter Code"];

interface FormResponse {
  message: string;
  type: "success" | "error" | "info";
}

export const SelfEnrollMFA = () => {
  const { currentUser } = useAuth();
  const {
    disableMFA,
    handleMFAError,
    verifyCodeAndSignIn,
    isCurrentUserMfaEnrolled,
  } = useMFA();
  const [activeStep, setActiveStep] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [totpSecret, setTotpSecret] = React.useState<TotpSecret | null>(null);
  const [response, setResponse] = React.useState<FormResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [enrolledFactors, setEnrolledFactors] = useState<MultiFactorInfo[]>([]);
  const [isDisablingMFA, setIsDisablingMFA] = useState(false);
  const qrCanvasRef = React.useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (currentUser) {
      const factors = multiFactor(currentUser).enrolledFactors;
      setEnrolledFactors(factors);

      // If no MFA factors are enrolled and we have a TOTP secret, generate QR code
      if (factors.length === 0 && totpSecret && qrCanvasRef.current) {
        const totpUri = totpSecret.generateQrCodeUrl(
          `Optic Truck Works Portal ${process.env.VITE_FREETECH_ENV}: ${currentUser.email}`
        );
        QRCode.toCanvas(qrCanvasRef.current, totpUri).catch(console.error);
      }
    }
  }, [currentUser, totpSecret]);

  const handleVerifyIdentity = async () => {
    try {
      setIsLoading(true);
      if (!currentUser?.email) {
        throw new Error("User email not found");
      }

      const credential = EmailAuthProvider.credential(
        currentUser.email,
        password
      );
      await reauthenticateWithCredential(currentUser, credential);

      // Generate TOTP secret
      const multiFactorSession = await multiFactor(currentUser).getSession();
      const secret =
        await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      setTotpSecret(secret);

      setActiveStep(1);
      setResponse(null);
    } catch (error) {
      console.error(error);
      setResponse({
        type: "error",
        message: "Failed to verify identity. Please check your password.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    try {
      setIsLoading(true);
      if (!totpSecret || !currentUser) {
        throw new Error("Missing required data");
      }

      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          totpSecret,
          verificationCode
        );
      await multiFactor(currentUser).enroll(
        multiFactorAssertion,
        "Authenticator App"
      );

      // Update enrolled factors after successful enrollment
      setEnrolledFactors(multiFactor(currentUser).enrolledFactors);
      setActiveStep(2);
    } catch (error) {
      console.error(error);
      setResponse({
        type: "error",
        message: "Invalid verification code. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableMFA = async () => {
    try {
      setIsLoading(true);
      await disableMFA(password);
      setEnrolledFactors([]);
      setResponse({
        type: "success",
        message: "Two-factor authentication has been disabled.",
      });
      setPassword("");
      setVerificationCode("");
      setIsDisablingMFA(false);
    } catch (error: any) {
      console.error(error);
      if (error?.code === "auth/multi-factor-auth-required") {
        handleMFAError(error);
        setIsDisablingMFA(true);
        setResponse({
          type: "info",
          message:
            "Please enter the verification code from your authenticator app to disable MFA.",
        });
      } else {
        setResponse({
          type: "error",
          message:
            error.message ||
            "Failed to disable MFA. Please check your password and try again.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyAndDisable = async () => {
    try {
      setIsLoading(true);
      await verifyCodeAndSignIn(verificationCode);

      // After successful verification, try disabling MFA again
      const mfa = multiFactor(currentUser!);
      await mfa.unenroll(mfa.enrolledFactors[0]);

      setEnrolledFactors([]);
      setResponse({
        type: "success",
        message: "Two-factor authentication has been disabled.",
      });
      setPassword("");
      setVerificationCode("");
      setIsDisablingMFA(false);
    } catch (error: any) {
      console.error(error);
      setResponse({
        type: "error",
        message: "Invalid verification code. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Typography variant="body1" gutterBottom>
              Please verify your identity by entering your password to continue
              setting up two-factor authentication.
            </Typography>
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleVerifyIdentity}
              disabled={!password || isLoading}
              sx={{ mt: 2 }}
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <SecurityIcon />
              }
            >
              Verify Identity
            </Button>
          </Box>
        );

      case 1:
        return (
          <Box>
            <Typography variant="body1" gutterBottom>
              Scan this QR code with your authenticator app (e.g., Google
              Authenticator, Authy).
            </Typography>
            <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
              <canvas ref={qrCanvasRef} />
            </Box>
            {totpSecret && (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Manual entry code: {totpSecret.secretKey}
              </Typography>
            )}
            <TextField
              fullWidth
              label="Enter 6-digit code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleVerifyCode}
              disabled={verificationCode.length !== 6 || isLoading}
              sx={{ mt: 2 }}
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <QrCodeIcon />
              }
            >
              Verify Code
            </Button>
          </Box>
        );

      case 2:
        return (
          <Box>
            <Typography variant="body1" gutterBottom>
              Two-factor authentication has been successfully enabled for your
              account. You'll need to enter a verification code from your
              authenticator app the next time you sign in.
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  if (enrolledFactors.length > 0) {
    return (
      <Paper sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}
        >
          <SecurityIcon />
          Two-Factor Authentication
        </Typography>

        <Alert severity="success" sx={{ mb: 3 }}>
          Two-factor authentication is currently enabled on your account.
        </Alert>

        {!isDisablingMFA ? (
          <>
            <Typography variant="body1" gutterBottom>
              To disable two-factor authentication, please verify your identity:
            </Typography>

            <TextField
              fullWidth
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mt: 2 }}
            />
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Enter the verification code from your authenticator app to
              continue:
            </Typography>

            <TextField
              fullWidth
              label="Enter 6-digit code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{ mt: 2 }}
            />
          </>
        )}

        {response && (
          <Alert
            severity={response.type}
            sx={{ mt: 2, mb: 2 }}
            onClose={() => setResponse(null)}
          >
            {response.message}
          </Alert>
        )}

        <Button
          variant="contained"
          color="error"
          onClick={isDisablingMFA ? handleVerifyAndDisable : handleDisableMFA}
          disabled={
            (!password && !isDisablingMFA) ||
            (isDisablingMFA && verificationCode.length !== 6) ||
            isLoading
          }
          sx={{ mt: 2 }}
          startIcon={
            isLoading ? <CircularProgress size={20} /> : <SecurityIcon />
          }
        >
          {isDisablingMFA
            ? "Verify and Disable"
            : "Disable Two-Factor Authentication"}
        </Button>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      {!isCurrentUserMfaEnrolled() && activeStep === 0 ? (
        <Alert severity="warning" sx={{ mb: 3 }}>
          <Typography variant="body1" gutterBottom>
            Please set up two-factor authentication on your account.
          </Typography>
        </Alert>
      ) : (
        <></>
      )}

      <Typography
        variant="h6"
        sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}
      >
        <SecurityIcon />
        Two-Factor Authentication
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {response && (
        <Alert
          severity={response.type}
          sx={{ mb: 2 }}
          onClose={() => setResponse(null)}
        >
          {response.message}
        </Alert>
      )}

      {renderStepContent()}
    </Paper>
  );
};

export default SelfEnrollMFA;
