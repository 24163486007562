import { Add, Launch } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { vehicleTypeOptions } from 'core/constants/inventory'
import { Formula, Vehicle } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useInventory } from 'hooks/inventory/useInventory'
import React, { useState } from 'react'

interface FormulaInventoryStatsProps {
  formulas: Formula[]
  onFormulaClick?: (formula: Formula) => void
  onClickNewFormula?: (params: { type: string; size: string; make: string; model: string; fuelType: string }) => void
}

interface VehicleGroup {
  make: string
  model: string
  type: string
  size: string
  fuelType: string
  count: number
}

const FormulaInventoryStats: React.FC<FormulaInventoryStatsProps> = ({
  formulas,
  onFormulaClick,
  onClickNewFormula,
}) => {
  const { vehicles: inventory } = useInventory('master_inventory')
  const [filterType, setFilterType] = useState<string>('all')
  const [showWithFormula, setShowWithFormula] = useState<'all' | 'with' | 'without'>('all')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const isMobile = useIsMobile()

  const groupedVehicles = React.useMemo(() => {
    if (!inventory) return []

    const groups: { [key: string]: VehicleGroup } = {}

    inventory.forEach((vehicle: Vehicle) => {
      const key = `${vehicle.make}-${vehicle.model}-${vehicle.type}-${vehicle.size}-${vehicle.fuel}`

      if (!groups[key]) {
        groups[key] = {
          make: vehicle.make || '',
          model: vehicle.model || '',
          type: vehicle.type || '',
          size: vehicle.size || '',
          fuelType: vehicle.fuel || '',
          count: 0,
        }
      }
      groups[key].count++
    })

    return Object.values(groups)
      .filter(group => filterType === 'all' || group.type === filterType)
      .sort((a, b) => b.count - a.count)
  }, [inventory, filterType])

  const hasFormula = (vehicle: VehicleGroup) => {
    return formulas.some(
      formula =>
        formula.vehicleMake?.toLowerCase() === vehicle.make.toLowerCase() &&
        formula.vehicleModel?.toLowerCase() === vehicle.model.toLowerCase() &&
        formula.vehicleType?.toLowerCase() === vehicle.type.toLowerCase() &&
        formula.vehicleSize?.toLowerCase() === vehicle.size.toLowerCase() &&
        formula.vehicleFuel?.toLowerCase() === vehicle.fuelType.toLowerCase(),
    )
  }

  const filteredVehicles = React.useMemo(() => {
    return groupedVehicles
      .filter(group => {
        const hasFormulaValue = hasFormula(group)
        if (showWithFormula === 'with') return hasFormulaValue
        if (showWithFormula === 'without') return !hasFormulaValue
        return true
      })
      .filter(group => {
        const searchString = `${group.make} ${group.model} ${group.type} ${group.size}`.toLowerCase()
        return searchString.includes(searchTerm.toLowerCase())
      })
  }, [groupedVehicles, showWithFormula, searchTerm])

  const formatVehicleName = React.useMemo(
    () => (group: VehicleGroup) => {
      const formattedMake = group.make.charAt(0).toUpperCase() + group.make.slice(1)
      const formattedModel = group.model.charAt(0).toUpperCase() + group.model.slice(1)
      const formattedSize = group.size.charAt(0).toUpperCase() + group.size.slice(1)
      const formattedFuel = group.fuelType.charAt(0).toUpperCase() + group.fuelType.slice(1)
      return `${formattedMake} ${formattedModel} ${formattedSize} ${formattedFuel}`
    },
    [],
  )

  const getFormulaForVehicle = (vehicle: VehicleGroup) => {
    return formulas.find(
      formula =>
        formula.vehicleMake?.toLowerCase() === vehicle.make.toLowerCase() &&
        formula.vehicleModel?.toLowerCase() === vehicle.model.toLowerCase() &&
        formula.vehicleType?.toLowerCase() === vehicle.type.toLowerCase() &&
        formula.vehicleSize?.toLowerCase() === vehicle.size.toLowerCase() &&
        formula.vehicleFuel?.toLowerCase() === vehicle.fuelType.toLowerCase(),
    )
  }

  const handleClickNewFormula = (vehicle: VehicleGroup) => {
    onClickNewFormula?.({
      type: vehicle.type,
      size: vehicle.size,
      make: vehicle.make,
      model: vehicle.model,
      fuelType: vehicle.fuelType,
    })
  }

  const coverageStats = React.useMemo(() => {
    const total = filteredVehicles.length
    const withFormula = filteredVehicles.filter(v => hasFormula(v)).length
    const percentage = total ? Math.round((withFormula / total) * 100) : 0
    return { total, withFormula, percentage }
  }, [filteredVehicles])

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant='h4' color='primary' sx={{ fontWeight: 500 }}>
              Inventory Coverage
            </Typography>
            {!isMobile && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Select size='small' value={filterType} onChange={e => setFilterType(e.target.value)}>
                  <MenuItem value='all'>All Types</MenuItem>
                  {vehicleTypeOptions.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  size='small'
                  value={showWithFormula}
                  onChange={e => setShowWithFormula(e.target.value as 'all' | 'with' | 'without')}
                >
                  <MenuItem value='all'>All Vehicles</MenuItem>
                  <MenuItem value='with'>With Formula</MenuItem>
                  <MenuItem value='without'>Without Formula</MenuItem>
                </Select>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 2,
              mb: 2,
            }}
          >
            <Card variant='outlined' sx={{ p: isMobile ? 1 : 2 }}>
              <Typography variant='h4' color='primary' sx={{ fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
                {coverageStats.total}
              </Typography>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
              >
                {isMobile ? 'Total' : 'Total Vehicle Types'}
              </Typography>
            </Card>
            <Card variant='outlined' sx={{ p: isMobile ? 1 : 2 }}>
              <Typography variant='h4' color='primary' sx={{ fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
                {coverageStats.withFormula}
              </Typography>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
              >
                With Formula
              </Typography>
            </Card>
            <Card variant='outlined' sx={{ p: isMobile ? 1 : 2 }}>
              <Typography variant='h4' color='primary' sx={{ fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
                {coverageStats.percentage}%
              </Typography>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
              >
                Coverage
              </Typography>
            </Card>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Autocomplete
              freeSolo
              size='small'
              options={[]}
              value={searchTerm}
              onChange={(_, newValue) => setSearchTerm(newValue || '')}
              onInputChange={(_, newValue) => setSearchTerm(newValue)}
              renderInput={params => <TextField {...params} placeholder='Search vehicles...' fullWidth />}
              sx={{ width: '100%' }}
            />

            {isMobile && (
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
                <Select size='small' value={filterType} onChange={e => setFilterType(e.target.value)} fullWidth>
                  <MenuItem value='all'>All Types</MenuItem>
                  {vehicleTypeOptions.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  size='small'
                  value={showWithFormula}
                  onChange={e => setShowWithFormula(e.target.value as 'all' | 'with' | 'without')}
                  fullWidth
                >
                  <MenuItem value='all'>All Vehicles</MenuItem>
                  <MenuItem value='with'>With Formula</MenuItem>
                  <MenuItem value='without'>Without Formula</MenuItem>
                </Select>
              </Box>
            )}
          </Box>

          <List>
            {filteredVehicles.map((group, index) => {
              const formula = getFormulaForVehicle(group)

              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    isMobile ? (
                      <IconButton
                        size='small'
                        onClick={() => (formula ? onFormulaClick?.(formula) : handleClickNewFormula(group))}
                      >
                        {formula ? <Launch /> : <Add />}
                      </IconButton>
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {formula ? (
                          <Button
                            variant='contained'
                            size='small'
                            startIcon={<Launch />}
                            onClick={() => onFormulaClick?.(formula)}
                          >
                            View Formula
                          </Button>
                        ) : (
                          <Button
                            variant='outlined'
                            size='small'
                            startIcon={<Add />}
                            onClick={() => handleClickNewFormula(group)}
                          >
                            Prefill values
                          </Button>
                        )}
                      </Box>
                    )
                  }
                  divider={index !== filteredVehicles.length - 1}
                  sx={{
                    backgroundColor: index % 2 === 0 ? 'transparent' : 'action.hover',
                  }}
                >
                  <ListItemText
                    primary={formatVehicleName(group)}
                    secondary={
                      <Typography variant='body2' color='text.secondary'>
                        {group.count} {group.count === 1 ? 'unit' : 'units'} in inventory
                      </Typography>
                    }
                  />
                </ListItem>
              )
            })}
          </List>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FormulaInventoryStats
