import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { CombinedUserData, CreateUserType } from '@otw/models/user'
import { portalFunctions } from 'core/functions/portalFunctions'
import { updateUser } from 'core/admin/users'
import { useAuth } from 'contexts/AuthContext'

interface CreateUserResponse {
  userId: string
  success: boolean
}

export const useManageUsers = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()

  // Fetch all users with better error handling and retry logic
  const {
    data: authUsers,
    isLoading: authUsersLoading,
    isError: authUsersError,
    error: authUsersErrorDetails,
    refetch: refetchUsers
  } = useQuery({
    queryKey: ['admin', 'users', 'management'],
    queryFn: async () => {
      try {
        // Check if user has admin permissions
        if (userInfo?.role !== 'admin' && userInfo?.role !== 'babyAdmin') {
          throw new Error('Unauthorized: User does not have admin permissions')
        }

        const users = await portalFunctions.admin.getAuthUsers()
        if (!users || !users.users) {
          throw new Error('No users data returned from API')
        }
        return users
      } catch (error) {
        console.error('Error fetching users:', error)
        throw error
      }
    },
    staleTime: 5 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    enabled: Boolean(userInfo?.role === 'admin' || userInfo?.role === 'babyAdmin'), // Only run query if user is admin
  })

  const createUserMutation = useMutation({
    mutationFn: async (user: CreateUserType) => {
      const response = await portalFunctions.admin.createFirebaseUser(user)
      if (!response.ok) {
        const text = await response.text()
        try {
          const data = JSON.parse(text)
          throw new Error(data.message || 'Failed to create user')
        } catch (e) {
          // If JSON parsing fails, use the raw text
          throw new Error(text || 'Failed to create user')
        }
      }

      const data = (await response.json()) as CreateUserResponse
      if (!data.userId) {
        throw new Error('Failed to create user')
      }
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const updateUserMutation = useMutation({
    mutationFn: async (user: CombinedUserData) => {
      return await updateUser(user)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.deleteFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const disableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.disableFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const enableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.enableFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  return {
    authUsers,
    authUsersLoading,
    authUsersError,
    authUsersErrorDetails,
    refetchUsers,
    createUserMutation,
    updateUserMutation,
    deleteUserMutation,
    disableUserMutation,
    enableUserMutation,
  }
}
