import React, { useMemo, useEffect, useRef } from 'react'
import { Box, Typography, Grid, Chip } from '@mui/material'
import { Application, ApplicationStatus } from '@otw/models'
import { animate, stagger } from 'motion'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import EditIcon from '@mui/icons-material/Edit'
import BuildIcon from '@mui/icons-material/Build'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import { STATUS_COLORS } from '../../constants'

interface StatusSummaryProps {
  applications: Application[]
}

const getStatusIcon = (status: ApplicationStatus) => {
  switch (status) {
    case 'draft':
      return <EditIcon fontSize="small" />
    case 'ready':
      return <TaskAltIcon fontSize="small" />
    case 'corrections_needed':
      return <BuildIcon fontSize="small" />
    case 'pending':
      return <HourglassEmptyIcon fontSize="small" />
    case 'viewed':
      return <VisibilityIcon fontSize="small" />
    case 'approved':
      return <CheckCircleIcon fontSize="small" />
    case 'denied':
      return <ClearIcon fontSize="small" />
    case 'signing':
      return <HourglassEmptyIcon fontSize="small" />
  }
}

const getStatusColor = (status: ApplicationStatus) => {
  return STATUS_COLORS[status] || '#000'
}

const StatusSummary: React.FC<StatusSummaryProps> = ({ applications }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const sortedApplications = useMemo(() => {
    return [...applications].sort((a, b) => {
      if (a.status === 'signing' && b.status !== 'signing') return -1
      if (a.status !== 'signing' && b.status === 'signing') return 1
      return 0
    })
  }, [applications])

  const statusOrder: ApplicationStatus[] = [
    'signing',
    'ready',
    'corrections_needed',
    'pending',
    'viewed',
    'approved',
    'denied',
    'draft',
  ]

  const statusCounts = useMemo(() => {
    const counts: Record<ApplicationStatus, number> = {
      signing: 0,
      draft: 0,
      ready: 0,
      corrections_needed: 0,
      viewed: 0,
      pending: 0,
      approved: 0,
      denied: 0,
    }
    applications.forEach((app) => {
      counts[app.status]++
    })
    return counts
  }, [applications])

  useEffect(() => {
    if (containerRef.current) {
      const chips = containerRef.current.querySelectorAll('.status-chip')
      animate(
        chips,
        { 
          opacity: [0, 1],
          scale: [0.8, 1],
        },
        { 
          delay: stagger(0.1),
          duration: 0.4,
        }
      )
    }
  }, [applications])

  return (
    <Box 
      ref={containerRef}
      sx={{ 
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        mb: 3,
      }}
    >
      {statusOrder.map((status) => (
        statusCounts[status] > 0 && (
          <Chip
            key={status}
            className="status-chip"
            icon={getStatusIcon(status)}
            label={
              <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                  {status.replace(/_/g, ' ')}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(0,0,0,0.08)',
                    px: 1,
                    py: 0.25,
                    borderRadius: 1,
                    minWidth: '24px',
                    textAlign: 'center'
                  }}
                >
                  {statusCounts[status]}
                </Typography>
              </Box>
            }
            sx={{
              bgcolor: `${getStatusColor(status)}15`,
              color: getStatusColor(status),
              border: `1px solid ${getStatusColor(status)}30`,
              '& .MuiChip-icon': {
                color: 'inherit'
              },
              py: 0.5,
              opacity: 0,
            }}
          />
        )
      ))}
    </Box>
  )
}

export default StatusSummary