import { addDoc, collection } from '@firebase/firestore'
import { db } from 'config/firebase'

interface SendPhotoUploadLinkProps {
  link: string
  email: string
  phoneNumber: string
}

export const sendPhotoUploadLink = async (props: SendPhotoUploadLinkProps) => {
  const { link, email, phoneNumber } = props
  try {
    
    if (phoneNumber !== '') {
      
      await addDoc(collection(db, 'messages'), {
        to: phoneNumber,
        body: `Here is your photo upload link: ${link}`,
      })
      
    }

    if (email !== '') {
      
      await addDoc(collection(db, 'mail'), {
        to: email,
        message: {
          subject: 'Optic TruckWorks photo upload',
          html: `Here is your photo upload link: ${link}`,
        },
      })
      
    }

    return true
  } catch (error) {
    console.error('Error in uploading to Firestore:', error)
    return ''
  }
}
