import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material'
import { Application } from '@otw/models'
import { useBankAgent } from 'hooks/bank/useBankAgent'

interface ApproveOrDenyDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
}

const ApproveOrDenyDialog: React.FC<ApproveOrDenyDialogProps> = ({ application, isOpen, onClose }) => {
  const [notes, setNotes] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { handleApproveOrDenyApplication } = useBankAgent()

  useEffect(() => {
    if (application) {
      setNotes(application.bank_decision_notes || '')
    }
  }, [application])

  const handleSubmit = async (status: 'approved' | 'denied') => {
    if (!application?.id) return

    setIsSubmitting(true)
    try {
      await handleApproveOrDenyApplication(application.id, status, notes)
      setNotes('')
      onClose()
    } catch (error) {
      console.error('Error submitting decision:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Application Decision</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Application ID'
          margin='dense'
          value={application?.id || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Client Email'
          margin='dense'
          value={application?.user_email || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Time Created'
          margin='dense'
          value={application?.time_created || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Status'
          margin='dense'
          value={application?.status || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Optic Notes'
          multiline
          rows={2}
          margin='dense'
          value={application?.notes || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />

        <TextField
          fullWidth
          label='Decision Notes'
          multiline
          rows={4}
          margin='dense'
          value={notes}
          onChange={e => setNotes(e.target.value)}
          variant='outlined'
          required
          error={notes.trim() === ''}
          helperText={notes.trim() === '' ? 'Decision notes are required' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit('approved')}
          disabled={isSubmitting || notes.trim() === ''}
          color='success'
          variant='contained'
        >
          Approve
        </Button>
        <Button
          onClick={() => handleSubmit('denied')}
          disabled={isSubmitting || notes.trim() === ''}
          color='error'
          variant='contained'
        >
          Deny
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApproveOrDenyDialog
