/* eslint-disable */

// TODO: @adamsiwiec1

import { doc, updateDoc } from '@firebase/firestore'
import { db } from 'config/firebase'

// update data should be user type
export const updateUser = async (userId: string, updateData: any) => {
  const userRef = doc(db, `users/${userId}`)

  try {
    await updateDoc(userRef, updateData)
    return true
  } catch (error) {
    console.error('Error updating user profile:', error)
    return false
  }
}
