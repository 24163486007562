import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from '@mui/material'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { CreateUserType } from '@otw/models/user'
import { useManageUsers } from 'hooks/admin/useManageUsers'
import { useAdminApplications } from 'hooks/admin/useAdminApplications'
import { Bank } from '@otw/models'

const db = getFirestore()

export const CreateUser = () => {
  const { createUserMutation } = useManageUsers()
  const [formError, setFormError] = useState<string | null>(null)
  const [userData, setUserData] = useState<CreateUserType>({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    role: 'non-admin',
    permissions: [],
    email_verified: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    needsOnboarding: true,
    disabled: false,
    dealership_name: '',
    is_active: true,
    bank_id: '',
    notes: '',
  })
  const [open, setOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isUserTypeSelected, setIsUserTypeSelected] = useState(true)

  const { banks } = useAdminApplications()

  const handleOpen = () => {
    setOpen(true)
    setFormError(null)
  }

  const handleClose = () => {
    setIsEmailValid(true)
    setFormError(null)
    setOpen(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null)
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setFormError(null)
    const value = event.target.value
    console.log('User type change:', value)
    
    setUserData(prevData => ({
      ...prevData,
      role: value as CreateUserType['role'],
      // Clear bank_id if switching to a non-bank/client role
      bank_id: ['client', 'bank'].includes(value) ? prevData.bank_id : '',
    }))
  }

  const handleBankChange = (event: SelectChangeEvent<string>) => {
    console.log('Bank change:', event)
    setFormError(null)
    const value = event.target.value
    console.log('Bank change:', value)
    
    if (!value) return // Don't update if value is empty/undefined
    
    setUserData(prevData => ({
      ...prevData,
      bank_id: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormError(null)

    // Validate required fields
    if (!userData.role || !userData.first_name || !userData.last_name || !userData.email) {
      setFormError('Missing required fields: role, first_name, last_name, email')
      return
    }

    // Validate bank selection for client and bank roles
    if (['client', 'bank'].includes(userData.role) && !userData.bank_id) {
      setFormError('Please select a bank')
      return
    }

    createUserMutation.mutate(userData, {
      onSuccess: () => {
        setUserData({
          email: '',
          password: '',
          first_name: '',
          last_name: '',
          role: 'non-admin',
          permissions: [],
          email_verified: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          needsOnboarding: true,
          disabled: false,
          dealership_name: '',
          is_active: true,
          bank_id: '',
          notes: '',
        })
        handleClose()
      },
      onError: error => {
        console.error('Error creating user', error)
        setFormError(error instanceof Error ? error.message : 'Error creating user')
      },
    })
  }

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Button variant='outlined' onClick={handleOpen}>
          Create User
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  value={userData.email}
                  onChange={handleChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? 'Invalid email address' : ''}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='first_name'
                  label='First Name'
                  name='first_name'
                  autoComplete='fname'
                  value={userData.first_name}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='last_name'
                  label='Last Name'
                  name='last_name'
                  autoComplete='lname'
                  value={userData.last_name}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label='Role'
                    name='role'
                    value={userData.role}
                    onChange={handleUserTypeChange}
                    disabled={createUserMutation.isPending}
                  >
                    <MenuItem value='admin'>Admin</MenuItem>
                    <MenuItem value='babyAdmin'>Baby Admin</MenuItem>
                    <MenuItem value='bankAgent'>Bank Agent</MenuItem>
                    <MenuItem value='client'>Client</MenuItem>
                  </Select>
                  {!isUserTypeSelected && (
                    <Typography color='error' variant='caption'>
                      Please select a role.
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {['client', 'bank'].includes(userData.role) && (
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Bank</InputLabel>
                    <Select
                      label='Bank'
                      name='bank_id'
                      value={userData.bank_id || ''}
                      onChange={handleBankChange}
                      disabled={createUserMutation.isPending}
                    >
                      <MenuItem value=''>Select a Bank</MenuItem>
                      {banks.data?.map((bank: Bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  id='notes'
                  label='Notes'
                  name='notes'
                  value={userData.notes || ''}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                  placeholder="Enter any additional notes about this user..."
                  sx={{ mt: 1 }}
                />
              </Grid>
              {formError && (
                <Grid item xs={12}>
                  <Alert severity='error' sx={{ mt: 2 }}>
                    {formError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={createUserMutation.isPending}
                >
                  {createUserMutation.isPending ? <CircularProgress size={24} color='inherit' /> : 'Create User'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
