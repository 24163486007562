import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'config/firebase'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useState } from 'react'
import { onSnapshot } from 'firebase/firestore'
import { Vehicle } from '@otw/models'
import { vehicleConverter } from '@otw/models/converters'

interface UseInventoryOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
  dontShowUpdates?: boolean
}

interface UpdateDocumentParams {
  id: string
  data: Partial<DocumentData>
}

// Add this interface for filter state

interface HistoryEntry {
  id: string
  documentId: string
  timestamp: any
  alphas?: Record<string, any>
  deltas?: Record<string, any>
  fullData?: Record<string, any>
  modifiedBy: string
  collection: string
  type: 'update' | 'create' | 'delete'
  identifier: string
  docId: string
}

export const useInventory = (
  inventoryCollection: string,
  subcollection?: {
    name: string
    parentDocId: string
  },
  options?: UseInventoryOptions,
) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo, currentUser } = useAuth()

  const [isSnapshotListenerActive, setIsSnapshotListenerActive] = useState(true)

  // Create collection ref based on whether subcollection is specified
  const collectionRef = subcollection
    ? collection(db, inventoryCollection, subcollection.parentDocId, subcollection.name)
    : collection(db, inventoryCollection)

  const queryKey = subcollection
    ? [inventoryCollection, subcollection.parentDocId, subcollection.name]
    : [inventoryCollection]

  const {
    data: vehicles,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async (): Promise<Vehicle[]> => {
      const collectionWithConverter = collectionRef.withConverter(vehicleConverter)
      const snapshot = await getDocs(collectionWithConverter)
      const docs = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))

      // Set up real-time listener after initial fetch
      const unsubscribe = onSnapshot(collectionWithConverter, snapshot => {
        if (!isSnapshotListenerActive) return

        const updatedDocs = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))

        // Update the cache with new data
        queryClient.setQueryData(queryKey, updatedDocs)
      })

      useEffect(() => {
        return () => unsubscribe()
      }, [])

      return docs.sort((a, b) => {
        if (!a.date_added && !b.date_added) return 0
        if (!a.date_added) return 1
        if (!b.date_added) return -1
        return new Date(b.date_added).getTime() - new Date(a.date_added).getTime()
      })
    },
    staleTime: 1000 * 60 * 5,
  })

  const refetchVehicles = useCallback(async () => {
    await refetch()
  }, [refetch, showSnackbar])

  const addDocument = useMutation({
    mutationFn: async (data: DocumentData) => {
      const docRef = await addDoc(collectionRef, { ...data, entered_by: userInfo?.email })
      return docRef.id
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document added successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error adding document', 'error')
      }
      console.error('Error adding document:', error)
      options?.onError?.(error)
    },
  })

  const updateDocument = useMutation({
    mutationFn: async ({ id, data, isRevert = false }: UpdateDocumentParams & { isRevert?: boolean }) => {
      const docRef = doc(collectionRef, id) as DocumentReference

      if (isRevert) {
        await updateDoc(docRef, {
          ...data,
          modified_by: userInfo?.email,
          updated_by: currentUser?.uid,
          updated_at: new Date().toISOString(),
        })
        return { id, data }
      }

      const docSnap = await getDoc(docRef)
      const currentData = docSnap.data()

      const actualChanges = Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (!isEqual(currentData?.[key], value)) {
            acc[key] = value
          }
          return acc
        },
        {} as Record<string, any>,
      )

      if (Object.keys(actualChanges).length > 0) {
        await updateDoc(docRef, {
          ...actualChanges,
          modified_by: userInfo?.email,
          updated_by: currentUser?.uid,
          updated_at: new Date().toISOString(),
        })
      }

      return { id, data: actualChanges }
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousInventory = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old: Vehicle[] | undefined) => {
        if (!old) return old
        return old.map((item: Vehicle) => (item.id === id ? { ...item, ...data } : item))
      })

      return { previousInventory }
    },
    onError: (err, variables, context) => {
      if (context?.previousInventory) {
        queryClient.setQueryData(queryKey, context.previousInventory)
      }
      if (!options?.dontShowUpdates) {
        showSnackbar('Error updating document', 'error')
      }
      console.error('Error updating document:', err)
      options?.onError?.(err)
    },
    onSuccess: result => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Changes saved successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  const deleteDocument = useMutation({
    mutationFn: async (id: string) => {
      const docRef = doc(collectionRef, id)
      await deleteDoc(docRef)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document deleted successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting document', 'error')
      }
      console.error('Error deleting document:', error)
      options?.onError?.(error)
    },
  })

  const bulkDeleteDocuments = useMutation({
    mutationFn: async (ids: string[]) => {
      await Promise.all(ids.map(id => deleteDoc(doc(collectionRef, id))))
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Documents deleted successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting documents', 'error')
      }
      console.error('Error deleting documents:', error)
      options?.onError?.(error)
    },
  })

  const handleCellValueChange = useCallback(
    (id: string, field: string, value: any) => {
      updateDocument.mutate({
        id,
        data: { [field]: value },
      })
    },
    [updateDocument, queryClient],
  )

  const restoreDocumentMutation = useMutation({
    mutationFn: async (data: { id: string; fullData: Record<string, any> }) => {
      const { id, fullData } = data
      await setDoc(doc(collectionRef, id), fullData)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document restored successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error restoring document', 'error')
      }
      console.error('Error restoring document:', error)
      options?.onError?.(error)
    },
  })

  return {
    vehicles,
    isLoading,
    isError,
    error,
    addDocument: addDocument.mutate,
    updateDocument: updateDocument.mutate,
    deleteDocument: deleteDocument.mutate,
    bulkDeleteDocuments: bulkDeleteDocuments.mutate,
    handleCellValueChange,
    queryClient,
    refetchVehicles,
    restoreDocument: restoreDocumentMutation.mutate,
    pauseSnapshotListener: () => setIsSnapshotListenerActive(false),
    resumeSnapshotListener: () => setIsSnapshotListenerActive(true),
  }
}
