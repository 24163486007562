import React from 'react'
import { Navigation } from 'types/navigation'
import { Home } from '@mui/icons-material'
import { DealerHome } from 'views/Dealer'

export const dealerPages: Navigation = [
  { kind: 'header', title: 'Dealer' },
  {
    segment: 'dealer',
    title: 'Home',
    icon: <Home />,
    component: <DealerHome />,
  },
]
