import { FirestoreDataConverter, DocumentSnapshot, SnapshotOptions, WithFieldValue } from 'firebase/firestore'
import { FormulaRule } from '../formulas'

const formulaRuleConverter: FirestoreDataConverter<FormulaRule> = {
  toFirestore(rule: WithFieldValue<FormulaRule>) {
    const cleanedRule = Object.fromEntries(
      Object.entries(rule).filter(([key, value]) => value !== undefined && key !== 'id'),
    )
    return cleanedRule
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): FormulaRule {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      inEditMode: data?.inEditMode || false,
      targetFactor: data?.targetFactor || 'mileage',
      lowerBound: data?.lowerBound || 0,
      ...(data?.upperBound !== undefined && { upperBound: data.upperBound }),
      operationType: data?.operationType || 'subtract',
      operationValue: data?.operationValue || 0,
      order: data?.order || 0,
      status: data?.status || 'inactive',
      createdBy: data?.createdBy || '',
      createdAt: data?.createdAt || '',
    }
  },
}

export { formulaRuleConverter }