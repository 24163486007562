import { FirebaseError } from '@firebase/app'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteStorageFile } from 'core/api/firebaseStorage/deleteStorageFile'
import { uploadFileToInventory } from 'core/api/inventory/addFileToInventory'
import { deletePhotoPosition, updatePhotoPosition } from 'core/api/inventory/updatePhotoPosition'
import { db, storage } from 'config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, listAll, ref } from '@firebase/storage'
import { useInventory } from './inventory/useInventory'
import { VehiclePhoto, PhotoPosition } from '@otw/models'

const getAdditionalPhotosForInventoryObject = async (
  storagePath: string,
  inventoryObjectId: string,
  definedInventoryPhotos: VehiclePhoto[],
): Promise<VehiclePhoto[]> => {
  const storageRef = ref(storage, storagePath)
  const photos: VehiclePhoto[] = []
  const storageObjects = await listAll(storageRef)

  for (const obj of storageObjects.items) {
    const url = await getDownloadURL(obj)
    const photoIsPresent = definedInventoryPhotos.some(photo => photo.url === url)

    if (!photoIsPresent) {
      photos.push({
        id: inventoryObjectId,
        name: obj.name,
        fullPath: obj.fullPath,
        url,
      })
    }
  }
  return photos
}

const fetchPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
  subcollection?: { name: string; parentDocId: string },
  onlyMainPhoto?: boolean,
): Promise<VehiclePhoto[]> => {
  const docRef = subcollection
    ? doc(db, collectionName, subcollection.parentDocId, subcollection.name, inventoryObjectId)
    : doc(db, collectionName, inventoryObjectId)

  const docSnap = await getDoc(docRef)
  if (!docSnap.exists()) {
    console.error('No such document!')
    return []
  }

  const storagePath = subcollection
    ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}/${inventoryObjectId}`
    : `${collectionName}/${inventoryObjectId}`

  const storageRef = ref(storage, storagePath)

  try {
    const definedPhotos: VehiclePhoto[] = []
    if (onlyMainPhoto) {
      const vehicleObject = docSnap.data()

      if (!vehicleObject || !vehicleObject.photos || !vehicleObject.photos.main) {
        console.info(`No main photo found for document ${inventoryObjectId} in collection ${collectionName}`)
        return []
      }
      const photoFiles = await listAll(storageRef)
      if (photoFiles.items.length === 0) {
        console.info(`No photos found in storage at ${storagePath}.`)
        return []
      }

      const mainPhotoFileName = vehicleObject.photos.main
      const fullPath = `${storagePath}/${mainPhotoFileName}`
      const fileRef = ref(storage, fullPath)
      try {
        const url = await getDownloadURL(fileRef)
        definedPhotos.push({
          id: inventoryObjectId,
          name: mainPhotoFileName,
          url: url,
          position: 'main',
          fullPath: fullPath,
        })
      } catch (error) {
        console.info('Error getting download URL for main photo', mainPhotoFileName, error)
      }
    } else {
      const additionalPhotos = await getAdditionalPhotosForInventoryObject(
        storagePath,
        inventoryObjectId,
        definedPhotos,
      )

      return [...definedPhotos, ...additionalPhotos]
    }

    return definedPhotos
  } catch (error) {
    if (error instanceof FirebaseError && error.code === 'storage/object-not-found') {
      console.info(`No photos directory found at ${storagePath}.`)
    } else {
      console.error(`Error listing files in ${storagePath}:`, error)
    }
    return []
  }
}

export const usePhotosForInventoryObject = (
  collectionName: string,
  inventoryObjectId: string,
  options?: { enabled?: boolean; onlyMainPhoto?: boolean },
  subcollection?: { name: string; parentDocId: string },
) => {
  const queryClient = useQueryClient()
  const { updateDocument } = useInventory(collectionName, subcollection, { dontShowUpdates: true })
  const queryKey = subcollection
    ? [
        'photos',
        collectionName,
        subcollection.parentDocId,
        subcollection.name,
        inventoryObjectId,
        options?.onlyMainPhoto,
      ]
    : ['photos', collectionName, inventoryObjectId, options?.onlyMainPhoto]

  const query = useQuery({
    queryKey,
    queryFn: () =>
      fetchPhotosForInventoryObject(collectionName, inventoryObjectId, subcollection, options?.onlyMainPhoto),
    enabled: options?.enabled !== false && !!collectionName && !!inventoryObjectId,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  const deletePhotosMutation = useMutation({
    mutationFn: async (photosToDelete: VehiclePhoto[]) => {
      for (const photo of photosToDelete) {
        await deleteStorageFile(photo.url)
        await deletePhotoPosition(
          subcollection ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}` : collectionName,
          inventoryObjectId,
          photo.name,
        )
      }
      // Check if there are photos left, if not, remove the photo_storage_path field
      const photos = await fetchPhotosForInventoryObject(collectionName, inventoryObjectId, subcollection)
      if (photos.length === 0) {
        await updateDocument({ id: inventoryObjectId, data: { photo_storage_path: '' } })
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const uploadPhotosMutation = useMutation({
    mutationFn: async (photosToUpload: { file: File; previewBlobUrl: string }[]) => {
      const uploadedPhotos = await Promise.all(
        photosToUpload.map(async ({ file }) => {
          const { name, url } = await uploadFileToInventory(
            subcollection ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}` : collectionName,
            inventoryObjectId,
            file,
            true,
          )
          return { name, url }
        }),
      )
      // update vehicle to now have photos
      await updateDocument({
        id: inventoryObjectId,
        data: {
          photo_storage_path: subcollection
            ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}/${inventoryObjectId}`
            : `${collectionName}/${inventoryObjectId}`,
        },
      })

      return uploadedPhotos
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const updatePositionMutation = useMutation({
    mutationFn: async (params: { name: string; position: PhotoPosition }) => {
      await updatePhotoPosition(
        subcollection ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}` : collectionName,
        inventoryObjectId,
        params.name,
        params.position,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  return {
    ...query,
    deletePhotos: deletePhotosMutation.mutate,
    uploadPhotos: uploadPhotosMutation.mutate,
    updatePosition: updatePositionMutation.mutate,
    isDeleting: deletePhotosMutation.isPending,
    isUploading: uploadPhotosMutation.isPending,
    isUpdating: updatePositionMutation.isPending,
  }
}
