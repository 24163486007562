import React, { useState } from 'react'

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { submitSupportRequest } from 'core/api/users'

const SupportRequest = (): React.ReactNode => {
  const issueTypes = [
    {
      value: 'technical',
      label: 'Technical Issue',
    },
    {
      value: 'account',
      label: 'Account Issue',
    },
    {
      value: 'bug',
      label: 'Application Bug',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ]

  const [requestDetails, setRequestDetails] = useState({
    issueType: '',
    description: '',
  })

  const handleChange = (event: { target: { name: string; value: string } }) => {
    setRequestDetails({
      ...requestDetails,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // Extracting issueType and description for clarity
    const { issueType, description } = requestDetails

    const issueLabel = issueTypes.find(type => type.value === issueType)?.label || ''

    try {
      await submitSupportRequest(issueLabel, description)
      setOpen(true)
    } catch (error) {
      console.error('Failed to submit support request:', error)
    }
  }

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setRequestDetails({
      issueType: '',
      description: '',
    })
  }

  const SuccessSentDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Support Request Sent</DialogTitle>
        <DialogContent>
          <Typography>Your support request has been sent. We will get back to you as soon as possible.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      <SuccessSentDialog />
      <Container component='main' maxWidth='sm'>
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component='h1' variant='h5' textAlign='center'>
            Support Request
          </Typography>
          <Typography component='p' textAlign='center' sx={{ mt: 2 }}>
            Please fill out the form below and we will get back to you as soon as possible. This message will be sent
            to the relevant support team.
          </Typography>

          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Issue Type</InputLabel>
              <Select label='Issue Type' name='issueType' value={requestDetails.issueType} onChange={handleChange}>
                {issueTypes.map(issueType => (
                  <MenuItem key={issueType.value} value={issueType.value}>
                    {issueType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin='normal'
              required
              fullWidth
              name='description'
              label='Description'
              type='text'
              id='description'
              multiline
              rows={6}
              value={requestDetails.description}
              onChange={handleChange}
            />
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Submit Request
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default SupportRequest
