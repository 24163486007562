import { db } from 'config/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'

export const photoPositionMainExists = async (collectionName: string, inventoryId: string): Promise<boolean> => {
  const inventoryDocRef = doc(db, collectionName, inventoryId)
  const docSnap = await getDoc(inventoryDocRef)

  if (!docSnap.exists()) {
    console.error('No such document!')
    return false
  }

  const inventoryDocData = docSnap.data()
  return !!inventoryDocData.photos?.main
}

export const updatePhotoPosition = async (
  collectionName: string,
  inventoryId: string,
  photoName: string,
  position: string,
) => {
  const inventoryDocRef = doc(db, collectionName, inventoryId)

  const docSnap = await getDoc(inventoryDocRef)

  if (!docSnap.exists()) {
    console.error('No such document!')
    return
  }

  const inventoryDocData = docSnap.data()

  const photos = {
    ...inventoryDocData.photos,
    [position]: photoName,
  }

  await updateDoc(inventoryDocRef, {
    photos: photos,
  })
}

export const deletePhotoPosition = async (collectionName: string, inventoryId: string, photoName: string) => {
  const inventoryDocRef = doc(db, collectionName, inventoryId)

  const docSnap = await getDoc(inventoryDocRef)

  if (!docSnap.exists()) {
    console.error('No such document!')
    return
  }

  const inventoryDocData = docSnap.data()
  const photos = { ...inventoryDocData.photos }

  // Delete photo from any position where it appears
  Object.entries(photos).forEach(([pos, name]) => {
    if (name === photoName) {
      delete photos[pos]
    }
  })

  await updateDoc(inventoryDocRef, {
    photos: photos,
  })
}
