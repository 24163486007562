import React, { useEffect } from 'react'
import { Container, Box, Paper } from '@mui/material'
import { authBackground, logoNoBackground } from 'assets'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { MainLoading } from 'components/MainLoading'
import { useMFA } from 'hooks/auth/useMFA'
import AuthFooter from './AuthFooter'

const AuthLayout: React.FC = () => {
  const { isLoading, currentUser, userInfo } = useAuth()
  const { resolver } = useMFA()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (userInfo?.role) {
      if (userInfo?.role === 'babyAdmin') {
        navigate(`/admin`)
      } else {
        navigate(`/${userInfo?.role}`)
      }
    }
    if (resolver && location.pathname !== '/login-mfa-code') {
      navigate('/login-mfa-code')
    }
    if (location.pathname === '/' && !currentUser) {
      navigate('/login')
    }
  }, [resolver, location.pathname, navigate, currentUser, userInfo])

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: `url(${authBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingTop: '15vh',
        }}
      >
        <Container component='main' maxWidth='xs'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Box
              component='img'
              src={logoNoBackground}
              alt='Optic Truck Works Logo'
              sx={{
                width: '200px',
                height: 'auto',
                marginBottom: 4,
              }}
            /> */}

            <Paper
              elevation={6}
              sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'white',
                width: '100%',
                borderRadius: 2,
                boxShadow: '0 4px 20px black',
              }}
            >
              <Outlet />
            </Paper>
          </Box>
        </Container>
      </Box>
      <AuthFooter />
    </Box>
  )
}

export default AuthLayout
