/* eslint-disable */

import { getHeaders } from 'core/getHeaders'
import { portalFunctions } from 'core/functions/portalFunctions'

interface GetPhotoUploadLinkProps {
  email: string
  vehicleData: any
}

export const getPhotoUploadLink = async (props: GetPhotoUploadLinkProps): Promise<string> => {
  const { email, vehicleData } = props

  try {
    const response = await portalFunctions.admin.generatePhotoUploadLink(email, vehicleData)

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()

    return data.link
  } catch (error) {
    console.error('Error in send photo upload link:', error)
    throw error
  }
}
