import { Box, Chip, Paper, Typography, Grid } from '@mui/material'
import { ContactSubmission } from 'types/form_submission'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import MessageIcon from '@mui/icons-material/Message'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FlagIcon from '@mui/icons-material/Flag'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'config/firebase'

interface Props {
  submission: ContactSubmission
}

export const ContactDetail = ({ submission }: Props) => {
  useEffect(() => {
    const markAsRead = async () => {
      if (submission.status === 'New') {
        try {
          const submissionRef = doc(db, 'form_contact', submission.id)
          await updateDoc(submissionRef, {
            status: 'Read'
          })
        } catch (error) {
          console.error('Error marking submission as read:', error)
        }
      }
    }

    markAsRead()
  }, [submission])

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Contact Information Card */}
        <Grid item xs={12} md={6}>
          <Paper 
            variant="outlined" 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <PersonIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h6">Contact Information</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 32 }}>
                  <PersonIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                </Box>
                <Box>
                  <Typography variant="body1">
                    {submission.firstName} {submission.lastName}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 32 }}>
                  <EmailIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                </Box>
                <Box>
                  <Typography 
                    variant="body1" 
                    component="a" 
                    href={`mailto:${submission.email}`}
                    sx={{ 
                      color: 'primary.main',
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    {submission.email}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 32 }}>
                  <PhoneIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                </Box>
                <Box>
                  <Typography 
                    variant="body1" 
                    component="a" 
                    href={`tel:${submission.phone}`}
                    sx={{ 
                      color: 'primary.main',
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    {submission.phone}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Submission Details Card */}
        <Grid item xs={12} md={6}>
          <Paper 
            variant="outlined" 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <AccessTimeIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h6">Submission Details</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 32 }}>
                  <AccessTimeIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                </Box>
                <Typography variant="body2">
                  Submitted on {format(new Date(submission.createdAt.seconds * 1000), 'PPpp')}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 32 }}>
                  <FlagIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                </Box>
                <Box>
                  <Chip 
                    label={submission.status === 'New' ? 'Unread' : 'Read'}
                    color={submission.status === 'New' ? 'error' : 'success'}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Message Card */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <MessageIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h6">Message</Typography>
            </Box>
            
            <Typography 
              sx={{ 
                whiteSpace: 'pre-wrap',
                bgcolor: 'grey.50',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace'
              }}
            >
              {submission.message}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
} 