import React from "react";
import { Navigation } from "types/navigation";
import { Home } from "@mui/icons-material";
import { BankHome } from "views/Bank";

export const bankPages: Navigation = [
  { kind: "header", title: "Bank" },
  {
    segment: "bankAgent",
    title: "Home",
    icon: <Home />,
    component: <BankHome />,
    },
  ];