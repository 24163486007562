import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { PrivateUserInfoType } from "../user";

export const privateUserConverter: FirestoreDataConverter<PrivateUserInfoType> =
  {
    toFirestore(user: WithFieldValue<PrivateUserInfoType>): DocumentData {
      return {
        ...user,
      };
    },
    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): PrivateUserInfoType {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        ...data,
        // id: snapshot.id,
        // first_name: data?.first_name,
        // last_name: data?.last_name,
        // email: data?.email,
        // role: data?.role,
        // permissions: data?.permissions,
        // disabled: data?.disabled,
        // email_verified: data?.email_verified,
        // createdAt: data?.createdAt,
        // updatedAt: data?.updatedAt,
        // needsInitialPasswordSet: data?.needsInitialPasswordSet,
        // initialPasswordSetAt: data?.initialPasswordSetAt,
        // lastVerificationEmailRequestAt: data?.lastVerificationEmailRequestAt,
        // lastSignInLinkRequestAt: data?.lastSignInLinkRequestAt,
        // dealership_name: data?.dealership_name,
        // is_active: data?.is_active,
        // displayName: data?.display_name,
        // needsPasswordReset: data?.needsPasswordReset,
        // needsOnboarding: data?.needsOnboarding,
        // onboardingCompletedAt: data?.onboardingCompletedAt,
        // passwordResetAt: data?.passwordResetAt,
        // phone: data?.phone,
        // profilePicture: data?.profilePicture,
        // notes: data?.notes,
        // bank_id: data?.bank_id,
        // isOwner: data?.isOwner,
      } as PrivateUserInfoType;
    },
  };
