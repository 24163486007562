import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
  CircularProgress,
  Box,
} from '@mui/material'
import { Application } from '@otw/models'
import { pandaFunctions } from 'core/functions/pandaFunctions'
interface ViewDocumentsDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
}

const ViewDocumentsDialog: React.FC<ViewDocumentsDialogProps> = ({ application, isOpen, onClose }) => {
  const [documents, setDocuments] = useState<Record<string, string>>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (application) {
      setLoading(true)
      pandaFunctions.shared.getApplicationDocuments(application.id).then(async res => {
        if (!res) {
          setDocuments({})
        } else {
          setDocuments(res)
        }
        setLoading(false)
      })
    }
  }, [application])

  const allValuesNull = (docs: Record<string, string | null>) => {
    //
    // return true
    return Object.values(docs).every(value => value === null)
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Available Documents</DialogTitle>
      <DialogContent sx={{ justifyContent: 'space-between' }}>
        {loading ? (
          <Box display='flex' flexDirection='column' justifyContent='center'>
            <Typography>Loading addtional documents...</Typography>
            <CircularProgress />
          </Box>
        ) : !allValuesNull(documents) ? (
          Object.entries(documents).map(([key, url]) =>
            url ? (
              <Button key={key} component={Link} href={url} target='_blank' style={{ margin: 5 }}>
                {key.replace(/_/g, ' ')} {/* Replacing underscores with spaces for better readability */}
              </Button>
            ) : null,
          )
        ) : (
          <Typography>No documents available for this application.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewDocumentsDialog
