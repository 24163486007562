import { getStorage, ref, deleteObject } from '@firebase/storage'

export const deleteStorageFile = async (fullFirebaseStoragePath: string) => {
  const storage = getStorage()
  const storageRef = ref(storage, fullFirebaseStoragePath)
  try {
    await deleteObject(storageRef)
    
  } catch (error) {
    console.error('Failed to delete image:', error)
  }
}
