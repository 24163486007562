import { ApplicationStatus } from '@otw/models'

export interface StatusColor {
  main: string
  light: string
}

export const APPLICATION_STATUS_COLORS: Record<ApplicationStatus, StatusColor> = {
  pending: {
    main: '#FFA726', // Orange
    light: '#FFE0B2',
  },
  approved: {
    main: '#66BB6A', // Green
    light: '#C8E6C9',
  },
  denied: {
    main: '#EF5350', // Red
    light: '#FFCDD2',
  },
  viewed: {
    main: '#42A5F5', // Blue
    light: '#BBDEFB',
  },
  ready: {
    main: '#AB47BC', // Purple
    light: '#E1BEE7',
  },
  signing: {
    main: '#78909C', // Blue Grey
    light: '#CFD8DC',
  },
  draft: {
    main: '#9E9E9E', // Grey
    light: '#F5F5F5',
  },
  corrections_needed: {
    main: '#FF7043', // Deep Orange
    light: '#FFCCBC',
  },
}

export const getStatusColor = (status: ApplicationStatus): StatusColor => {
  return APPLICATION_STATUS_COLORS[status] || APPLICATION_STATUS_COLORS.signing
}

export const getStatusLabel = (status: ApplicationStatus): string => {
  return status.charAt(0).toUpperCase() + status.slice(1).replace(/_/g, ' ')
}

export const getStatusDescription = (status: ApplicationStatus): string => {
  switch (status) {
    case 'ready':
      return 'Optic Truck Works is currently reviewing this application before assigning it to your bank.'
    case 'signing':
      return 'The client is currently signing the application.'
    case 'pending':
      return 'Application is pending your review.'
    case 'viewed':
      return 'Application has been viewed and is under review.'
    case 'approved':
      return 'Application has been approved.'
    case 'denied':
      return 'Application has been denied.'
    case 'draft':
      return 'Application is in draft state.'
    case 'corrections_needed':
      return 'Application requires corrections from the client.'
    default:
      return ''
  }
} 