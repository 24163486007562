import React, { useState } from 'react'
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  DialogActions,
} from '@mui/material'
import { useListBuilder } from 'hooks/useListBuilder'
import { useInventory } from 'hooks/inventory/useInventory'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Vehicle, InventoryList } from '@otw/models/inventory'

interface ViewListDialogProps {
  open: boolean
  onClose: () => void
  list: InventoryList
  vehicles: Vehicle[]
}

const ViewListDialog: React.FC<ViewListDialogProps> = ({ open, onClose, list, vehicles }) => {
  const listVehicles = vehicles.filter(vehicle => list.inventoryIds.includes(vehicle.id))

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>{list.name}</DialogTitle>
      <DialogContent>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          {list.description}
        </Typography>
        <List>
          {listVehicles.map(vehicle => (
            <ListItem key={vehicle.id} divider>
              <ListItemText
                primary={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                secondary={
                  <>
                    <Typography component='span' variant='body2' color='textSecondary'>
                      Stock #: {vehicle.stock_number} | VIN: {vehicle.vin}
                    </Typography>
                    <br />
                    <Typography component='span' variant='body2' color='textSecondary'>
                      Status: {vehicle.status || 'Available'}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          startIcon={<PictureAsPdfIcon />}
          variant='contained'
          onClick={async () => {
            await downloadShareableInventoryPdf('master_inventory', list.inventoryIds)
          }}
        >
          Download PDF
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UserListBuilder = () => {
  const { lists, loading } = useListBuilder()
  const { vehicles } = useInventory('master_inventory')
  const [selectedList, setSelectedList] = useState<InventoryList | null>(null)

  if (loading) {
    return (
      <Container>
        <Typography>Loading lists...</Typography>
      </Container>
    )
  }

  return (
    <Container maxWidth='lg'>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h4' gutterBottom>
          My Vehicle Lists
        </Typography>
        <Grid container spacing={3}>
          {lists?.map(list => (
            <Grid item xs={12} md={6} key={list.id}>
              <Card>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    {list.name}
                    {list.isArchived && <Chip label='Archived' size='small' color='default' sx={{ ml: 1 }} />}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' gutterBottom>
                    {list.description}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Vehicles: {list.inventoryIds.length}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Created: {list.dateCreated ? new Date(list.dateCreated).toLocaleDateString() : ''}
                  </Typography>
                  <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                    <Button
                      size='small'
                      variant='contained'
                      startIcon={<VisibilityIcon />}
                      onClick={() => setSelectedList(list)}
                    >
                      View List
                    </Button>
                    <Button
                      size='small'
                      variant='outlined'
                      startIcon={<PictureAsPdfIcon />}
                      onClick={async () => {
                        await downloadShareableInventoryPdf('master_inventory', list.inventoryIds)
                      }}
                    >
                      Download PDF
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {lists?.length === 0 && (
            <Grid item xs={12}>
              <Typography variant='body1' color='textSecondary' align='center'>
                No lists available. Lists shared with you will appear here.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      {selectedList && vehicles && (
        <ViewListDialog
          open={!!selectedList}
          onClose={() => setSelectedList(null)}
          list={selectedList}
          vehicles={vehicles}
        />
      )}
    </Container>
  )
}

export default UserListBuilder
