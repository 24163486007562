import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import { Warning } from '@mui/icons-material';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  id: string;
  code: string;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  id,
  code
}) => {
  const baseUrl = process.env.VITE_ENV === 'production' 
    ? 'https://otw-production-91ffb.web.app'
    : 'https://otw-stg.web.app'

  const fullLink = `${baseUrl}/access/${id}`

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <Warning color="warning" />
          <Typography variant="h6">Confirm Access Revocation</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to revoke access to this photo upload link?
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            bgcolor: 'grey.100',
            p: 1,
            borderRadius: 1,
            fontFamily: 'monospace',
            mt: 1,
            wordBreak: 'break-all'
          }}
        >
          {fullLink}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            bgcolor: 'grey.100',
            p: 1,
            borderRadius: 1,
            fontFamily: 'monospace',
            mt: 1,
            mb: 2,
            color: 'text.secondary'
          }}
        >
          Access Code: {code}
        </Typography>
        <Typography color="warning.main">
          This action cannot be undone. The link will immediately become invalid and users will no longer be able to access it.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={onConfirm} 
          variant="contained" 
          color="error"
        >
          Revoke Access
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 