import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material'
import { PandaDocument, ApplicationStatus } from '@otw/models'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useAdminApplications } from 'hooks/admin/useAdminApplications'
import { useSnackbar } from 'contexts/snackBarContext'

interface AdminPandadocDialogProps {
  open: boolean
  onClose: () => void
  iframeUrl: string
  loading?: boolean
  onFormCompleted: (payload: PandaDocument) => void
  onIframeLoad?: () => void
  status?: ApplicationStatus
  applicationId?: string
}

const getDialogTitle = (status: ApplicationStatus | undefined) => {
  switch (status) {
    case 'ready':
      return 'View Ready Application'
    case 'pending':
      return 'View Pending Application'
    case 'viewed':
      return 'View Application Under Review'
    case 'approved':
      return 'View Approved Application'
    case 'denied':
      return 'View Declined Application'
    case 'corrections_needed':
      return 'View Application Needing Corrections'
    case 'draft':
      return 'View Draft Application'
    case 'signing':
      return 'View Application Being Signed'
    default:
      return 'View Application'
  }
}

const AdminPandadocDialog: React.FC<AdminPandadocDialogProps> = ({
  open,
  onClose,
  iframeUrl,
  loading = false,
  onFormCompleted,
  onIframeLoad,
  status,
  applicationId,
}) => {
  const [correctionNotes, setCorrectionNotes] = useState('')
  const [iframeError, setIframeError] = useState(false)
  const { sendApplicationBackForCorrections } = useAdminApplications()
  const { showSnackbar } = useSnackbar()

  console.log('iframeUrl in admin pandadoc dialog', iframeUrl)

  // Cleanup blob URL when dialog closes or URL changes
  useEffect(() => {
    return () => {
      if (iframeUrl && iframeUrl.startsWith('blob:')) {
        URL.revokeObjectURL(iframeUrl)
      }
    }
  }, [iframeUrl])

  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        onFormCompleted(payload)
        onClose()
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onFormCompleted, onClose])

  const handleSendCorrections = async () => {
    if (!applicationId) {
      showSnackbar('No application ID provided', 'error')
      return
    }

    if (!correctionNotes.trim()) {
      showSnackbar('Please enter correction notes', 'error')
      return
    }

    try {
      await sendApplicationBackForCorrections.mutateAsync({
        applicationId,
        correctionNotes: correctionNotes.trim(),
      })
      showSnackbar('Application sent back for corrections', 'success')
      setCorrectionNotes('')
      onClose()
    } catch (error) {
      showSnackbar('Failed to send application back for corrections', 'error')
    }
  }

  const handleIframeLoad = (event: React.SyntheticEvent<HTMLIFrameElement>) => {
    setIframeError(false)
    onIframeLoad?.()
  }

  const handleIframeError = () => {
    setIframeError(true)
    showSnackbar('Failed to load PDF document', 'error')
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100vh - 64px)',
          m: 2,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6'>{getDialogTitle(status)}</Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {status === 'ready' && (
        <Box sx={{ px: 2, py: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant='outlined'
              label='Correction Notes'
              value={correctionNotes}
              onChange={e => setCorrectionNotes(e.target.value)}
              placeholder='Enter notes for corrections needed...'
              size='small'
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleSendCorrections}
              disabled={sendApplicationBackForCorrections.isPending || !correctionNotes.trim()}
              sx={{ mt: 0.5 }}
            >
              Send for Corrections
            </Button>
          </Box>
        </Box>
      )}

      <DialogContent dividers sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Typography sx={{ mr: 2 }}>Loading application...</Typography>
            <CircularProgress />
          </Box>
        ) : iframeError ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Typography color='error'>Failed to load PDF document. Please try again.</Typography>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <iframe
              src={iframeUrl}
              style={{
                width: '100%',
                height: '100%',
                display: iframeUrl ? 'block' : 'none',
                border: 'none',
              }}
              frameBorder='0'
              allowFullScreen
              onLoad={handleIframeLoad}
              onError={handleIframeError}
              title='Application Form'
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AdminPandadocDialog
