import React from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Box from '@mui/material/Box'
import { CoreAppToolbar } from './CoreAppToolbar'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: '100%',
}))

interface CoreAppBarProps {
  open: boolean
  onDrawerToggle: () => void
}

export const CoreAppBar: React.FC<CoreAppBarProps> = ({ open, onDrawerToggle }) => {
  return (
    <AppBar position='fixed'>
      <Toolbar sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton
          color='inherit'
          aria-label={open ? 'close drawer' : 'open drawer'}
          onClick={onDrawerToggle}
          edge='start'
        >
          {open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Box
          component='img'
          src='https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2FIcon-OpticTruckWorks-03.png?alt=media&token=ca5d0d5d-088e-46b5-957d-0ed5b93c3acc'
          alt='FreeTech'
          sx={{
            maxHeight: '40px',
            width: 'auto',
            objectFit: 'contain',
            borderRadius: '10px',
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <CoreAppToolbar />
      </Toolbar>
    </AppBar>
  )
}
