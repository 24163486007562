import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ImageList,
  ImageListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { PhotoSubmissionObject } from '@otw/models'
import { useSnackbar } from 'contexts/snackBarContext'
import { copyStorageFileToNewLocation } from 'core/api/firebaseStorage/copyStorageFileToNewLocation'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

interface ViewPhotoSubmissionTabProps {
  rowId: string
  photoSubmission: PhotoSubmissionObject | null
  onBack: () => void
}

const ViewPhotoSubmissionTab: React.FC<ViewPhotoSubmissionTabProps> = ({ rowId, photoSubmission, onBack }) => {
  const [selectedPhotoPaths, setSelectedPhotoPaths] = useState<string[]>([])
  const { showSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const { mutate: uploadSubmittedPhotos } = useMutation(
    async (photoPaths: string[]) => {
      photoPaths.forEach(photoPath => {
        copyStorageFileToNewLocation(photoPath, `master_inventory/${rowId}/}`)
      })
    },
    {
      onError: () => {
        showSnackbar('Error uploading photos', 'error')
      },
      onSuccess: () => {
        setSelectedPhotoPaths([])
        queryClient.refetchQueries(['photos', rowId])
        showSnackbar('Photos uploaded succejjjjjjjjjjjssfully!', 'success')
      },
    },
  )

  const handleSelectPhoto = (fullPhotoPath: string) => {
    if (selectedPhotoPaths.includes(fullPhotoPath)) {
      setSelectedPhotoPaths(selectedPhotoPaths.filter(p => p !== fullPhotoPath))
    } else {
      setSelectedPhotoPaths([...selectedPhotoPaths, fullPhotoPath])
    }
  }

  return (
    <>
      {photoSubmission && (
        <Box>
          <Grid container alignItems='center'>
            <Grid item>
              <Button onClick={onBack}>Go Back</Button>
            </Grid>
            <Grid item container justifyContent='flex-end'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>UserId:</TableCell>
                    <TableCell>
                      {photoSubmission.user?.first_name} {photoSubmission.user?.last_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PhotoId:</TableCell>
                    <TableCell>{photoSubmission.photoId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Submitted At:</TableCell>
                    <TableCell>{photoSubmission.dateUploaded}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Submitted Photos:</TableCell>
                    <TableCell>{photoSubmission.photos.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      )}

      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        <>
          {photoSubmission?.photos.map((photo, index) => (
            <ImageListItem key={index}>
              <img src={`${photo.blobUrl}?w=164&h=164&fit=crop&auto=format`} alt={`Photo ${index}`} loading='lazy' />
              <Checkbox
                checked={selectedPhotoPaths.includes(photo.fullPath)}
                onChange={() => handleSelectPhoto(photo.fullPath)}
                inputProps={{ 'aria-label': 'Select photo' }}
              />
            </ImageListItem>
          ))}
        </>
      </ImageList>
      {selectedPhotoPaths.length > 0 && (
        <Button
          onClick={() => uploadSubmittedPhotos(selectedPhotoPaths)}
          startIcon={<SendIcon />}
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
        >
          Upload Selected Photos
        </Button>
      )}
    </>
  )
}

export default ViewPhotoSubmissionTab
