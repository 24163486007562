import { Application } from '@otw/models'
import { useQuery } from '@tanstack/react-query'
import { db } from 'config/firebase'
import { useAuth } from 'contexts/AuthContext'
import { pandaFunctions } from 'core/functions/pandaFunctions'
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { getDateTimeNowISO } from '@otw/models/utils'

export const useBankAgent = () => {
  const { userInfo, currentUser } = useAuth()

  const {
    data: bankData,
    isLoading: bankLoading,
    error: bankError,
  } = useQuery({
    queryKey: ['bank-agent', userInfo?.bank_id],
    queryFn: async () => {
      if (!userInfo?.bank_id) throw new Error('No bank ID found')
      const userBank = await getDoc(doc(collection(db, 'banks'), userInfo.bank_id))
      const data = userBank.data()
      if (!data) throw new Error('Bank not found')
      return data
    },
    enabled: !!userInfo?.bank_id,
  })

  const {
    data: applications,
    isLoading: applicationsLoading,
    error: applicationsError,
  } = useQuery({
    queryKey: ['applications', userInfo?.bank_id],
    queryFn: async () => {
      if (!userInfo?.bank_id) throw new Error('No bank ID found')
      try {
        console.log('userInfo?.bank_id', userInfo?.bank_id)
        const applications = query(collection(db, 'applications'), where('bank_id', '==', userInfo.bank_id))
        const applicationsDocs = await getDocs(applications)
        console.log('applicationsDocs', applicationsDocs)
        const applicationsData = applicationsDocs.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as Application[]
        console.log('applicationsData', applicationsData)
        return applicationsData
      } catch (error) {
        console.error('Error fetching applications:', error)
        throw error
      }
    },
    enabled: !!userInfo?.bank_id,
  })

  const getApplicationDocuments = (applicationId: string) =>
    useQuery({
      queryKey: ['applicationDocuments', applicationId],
      queryFn: async ({ queryKey }) => {
        const [_, appId] = queryKey
        const response = await pandaFunctions.shared.getApplicationDocuments(appId as string)
        return response
      },
      enabled: !!applicationId,
    })

  const handleApproveOrDenyApplication = async (
    applicationId: string,
    status: 'approved' | 'denied',
    decisionNotes: string,
  ) => {
    const applicationRef = doc(collection(db, 'applications'), applicationId)

    const applicationUpdate: Partial<Application> = {
      status,
      status_updated_at: getDateTimeNowISO(),
      status_updated_by_user_id: currentUser?.uid,
      bank_decision_notes: decisionNotes,
    }

    await updateDoc(applicationRef, applicationUpdate)
  }

  return {
    bankData,
    bankLoading,
    bankError,
    applications,
    applicationsLoading,
    applicationsError,
    handleApproveOrDenyApplication,
    getApplicationDocuments,
  }
}
