import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material'
import { BankUser } from '@otw/models'
import { useAdminApplications } from 'hooks/admin/useAdminApplications'

interface AssignAgentDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const AssignAgentDialog: React.FC<AssignAgentDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const [selectedAgentId, setSelectedAgentId] = useState('')
  const { bankAgents, bankAgentsIsLoading, bankAgentsError, editApplication, applications } = useAdminApplications()

  // Get the selected application from the parent component's state
  const selectedApplication = applications?.find(app => app.status === 'ready')
  const selectedBankId = selectedApplication?.bank_id || ''
  const selectedBankName = selectedApplication?.bank_id || ''

  const agentsForBank = bankAgents?.filter((agent: BankUser) => agent.bank_id === selectedBankId)

  const handleAgentChange = (event: { target: { value: string } }) => {
    setSelectedAgentId(event.target.value as string)
  }

  const handleAssign = async () => {
    if (!selectedApplication || !selectedAgentId) return

    try {
      await editApplication.mutateAsync({
        ...selectedApplication,
        bank_agent_id: selectedAgentId,
        status: 'pending',
      })
      setSelectedAgentId('')
      onClose()
    } catch (error) {
      console.error('Error during agent assignment:', error)
    }
  }

  const renderSelectContent = () => {
    if (bankAgentsIsLoading) {
      return <MenuItem disabled><CircularProgress size={20} sx={{ mx: 2 }} /> Loading agents...</MenuItem>
    }

    if (bankAgentsError) {
      return <MenuItem disabled>Error loading agents</MenuItem>
    }

    if (!agentsForBank || agentsForBank.length === 0) {
      return <MenuItem disabled>No agents available for this bank</MenuItem>
    }

    return agentsForBank.map((agent: BankUser) => (
      <MenuItem key={agent.id} value={agent.id}>
        {agent.email}
      </MenuItem>
    ))
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Assign Agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginBottom: 4, marginTop: 2 }}>
          <TextField
            fullWidth
            label='Bank Name'
            margin='dense'
            value={selectedBankName}
            InputProps={{
              readOnly: true,
              style: { backgroundColor: '#f5f5f5' },
            }}
            variant='outlined'
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='agent-select-label'>Agent</InputLabel>
          <Select
            labelId='agent-select-label'
            id='agent-select'
            value={selectedAgentId}
            label='Agent'
            onChange={handleAgentChange}
            disabled={bankAgentsIsLoading || !!bankAgentsError || !agentsForBank || agentsForBank.length === 0}
          >
            {renderSelectContent()}
          </Select>
          {bankAgentsError && (
            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
              Failed to load agents. Please try again later.
            </Typography>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleAssign} 
          disabled={bankAgentsIsLoading || !!bankAgentsError || !selectedAgentId || editApplication.isPending}
        >
          {editApplication.isPending ? 'Assigning...' : 'Assign'}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignAgentDialog
