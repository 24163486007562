import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, Button, Alert, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { verifyPasswordRequirements } from 'core/auth/utils/verifyPasswordRequirements'
import { authTextFieldStyle } from 'core/theme'
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { portalFunctions } from 'core/functions/portalFunctions'
import { theme } from 'core/theme'

export const NeedsResetPassword: React.FC = () => {
  const navigate = useNavigate()
  const { userInfo, currentUser, refreshUserInfo } = useAuth()
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(null)

    const formData = new FormData(event.currentTarget)
    const currentPassword = formData.get('currentPassword') as string
    const password = formData.get('password') as string
    const passwordConfirmation = formData.get('passwordConfirmation') as string

    if (!verifyPasswordRequirements(password)) {
      setError(
        'Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.',
      )
      return
    }

    if (password !== passwordConfirmation) {
      setError('Passwords do not match.')
      return
    }

    try {
      if (!currentUser) {
        setError('No user found. Please try logging in again.')
        return
      }

      // Check if this is initial password set or regular password reset
      if (userInfo?.needsInitialPasswordSet) {
        const email = currentUser?.email
        if (!email) {
          setError('No email found for current user.')
          return
        }
        // Initial password set flow
        const response = await portalFunctions.shared.setInitialPassword(password)

        if (!response.ok) {
          const errorData = await response.json()
          console.error('Error setting initial password:', errorData)
          setError(errorData.message || 'Failed to set initial password. Please try again.')
          return
        }

        // This will trigger onAuthStateChanged which calls refreshUser
        const credential = EmailAuthProvider.credential(email, password)
        await reauthenticateWithCredential(currentUser, credential)
      } else {
        // Regular password reset flow - need to reauthenticate first
        if (!currentPassword) {
          setError('Current password is required.')
          return
        }

        if (!currentUser.email) {
          setError('No email found for current user.')
          return
        }

        // Reauthenticate user first - this will trigger onAuthStateChanged
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword)
        await reauthenticateWithCredential(currentUser, credential)

        // Then update password
        await updatePassword(currentUser, password)
      }

      setSuccess(true)
      await refreshUserInfo(currentUser)
      setTimeout(() => {
        navigate(`/${userInfo?.role}`)
      }, 2000)
    } catch (error) {
      console.error('Error updating password:', error)
      if (userInfo?.needsInitialPasswordSet) {
        setError('Failed to set initial password. Please try again or contact support if the issue persists.')
        return
      }

      if (error instanceof Error && 'code' in error) {
        const errorCode = (error as { code: string }).code
        switch (errorCode) {
          case 'auth/wrong-password':
            setError('Incorrect current password. Please try again.')
            break
          case 'auth/too-many-requests':
            setError('Too many attempts. Please try again later.')
            break
          default:
            setError('An error occurred while updating your password. Please try again.')
        }
      } else {
        setError('An error occurred while updating your password. Please try again.')
      }
    }
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Typography variant='h4' sx={{ mb: 2, fontWeight: 500, color: theme => theme.palette.primary.main }}>
          {userInfo?.needsInitialPasswordSet ? 'Set Up Your Password' : 'Reset Your Password'}
        </Typography>
        <Typography variant='body1' sx={{ mb: 2, color: 'text.secondary' }}>
          {userInfo?.needsInitialPasswordSet
            ? 'Welcome to Optic Truck Works! Since this is your first time logging in, you need to set up a password for your account.'
            : 'For security purposes, you need to set a new password for your account.'}
        </Typography>
        <Typography variant='body1' sx={{ color: 'text.secondary' }}>
          {userInfo?.needsInitialPasswordSet
            ? 'This password will be used for future logins. You can change it later in your account settings.'
            : 'Please enter your current password and choose a new password.'}
        </Typography>
      </Box>

      {error && (
        <Alert severity='error' sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity='success' sx={{ mb: 3 }}>
          Password {userInfo?.needsInitialPasswordSet ? 'set' : 'updated'} successfully! Redirecting you to your
          dashboard...
        </Alert>
      )}

      <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
        <Box component='form' onSubmit={handleSubmit} noValidate>
          {!userInfo?.needsInitialPasswordSet && (
            <TextField
              margin='normal'
              required
              fullWidth
              name='currentPassword'
              label='Current Password'
              type='password'
              id='currentPassword'
              autoComplete='current-password'
              sx={authTextFieldStyle}
            />
          )}

          <Typography variant='subtitle1' sx={{ mb: 2, mt: 2, fontWeight: 500, color: theme => theme.palette.primary.main }}>
            Password Requirements:
          </Typography>
          <Box sx={{ mb: 3, pl: 2 }}>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
              • Minimum 8 characters long
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
              • At least one uppercase letter
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
              • At least one lowercase letter
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              • At least one number
            </Typography>
          </Box>

          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='New Password'
            type='password'
            id='password'
            autoComplete='new-password'
            sx={authTextFieldStyle}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='passwordConfirmation'
            label='Confirm New Password'
            type='password'
            id='passwordConfirmation'
            autoComplete='new-password'
            sx={authTextFieldStyle}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color="primary"
            disabled={success}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {userInfo?.needsInitialPasswordSet ? 'Set Password' : 'Reset Password'}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}
