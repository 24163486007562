import React from 'react'
import { Box, Typography, useTheme, Stack, Tooltip } from '@mui/material'
import { ApplicationStatus } from '@otw/models'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AssignmentIcon from '@mui/icons-material/Assignment'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { getStatusColor, getStatusLabel, getStatusDescription } from '../core/constants/applicationStatus'

type UserRole = 'admin' | 'client' | 'bank'

interface ApplicationStatusTimelineProps {
  currentStatus?: ApplicationStatus
  role?: UserRole
}

const getStatusDescriptionByRole = (status: ApplicationStatus, role: UserRole) => {
  switch (status) {
    case 'signing':
      return role === 'client' 
        ? 'You are currently signing the application'
        : role === 'bank'
        ? 'Client is signing the application'
        : 'Client is signing the application'
    case 'ready':
      return role === 'client'
        ? 'Your application is ready for review'
        : role === 'bank'
        ? 'Application is ready to be assigned to an agent'
        : 'Application is ready to be assigned to a bank agent'
    case 'corrections_needed':
      return role === 'client'
        ? 'Your application needs some corrections'
        : role === 'bank'
        ? 'Application has been sent back for corrections'
        : 'Application has been sent back for corrections'
    case 'pending':
      return role === 'client'
        ? 'Your application is being reviewed by a bank agent'
        : role === 'bank'
        ? 'Application is assigned and pending your review'
        : 'Application is being reviewed by a bank agent'
    case 'viewed':
      return role === 'client'
        ? 'A bank agent has viewed your application'
        : role === 'bank'
        ? 'You have viewed the application'
        : 'Bank agent has viewed the application'
    case 'approved':
      return role === 'client'
        ? 'Your application has been approved!'
        : role === 'bank'
        ? 'You have approved this application'
        : 'Application has been approved'
    case 'denied':
      return role === 'client'
        ? 'Your application has been denied'
        : role === 'bank'
        ? 'You have denied this application'
        : 'Application has been denied'
    default:
      return getStatusDescription(status)
  }
}

const getStatusLabelByRole = (status: ApplicationStatus, role: UserRole) => {
  switch (status) {
    case 'signing':
      return role === 'client' ? 'Signing' : 'Client Signing'
    case 'ready':
      return role === 'client' ? 'Ready for Review' : 'Ready for Assignment'
    case 'corrections_needed':
      return role === 'client' ? 'Needs Corrections' : 'Corrections Requested'
    case 'pending':
      return role === 'client' ? 'Under Review' : 'Pending Review'
    case 'viewed':
      return role === 'client' ? 'Viewed by Bank' : 'Viewed'
    case 'approved':
      return role === 'client' ? 'Approved!' : 'Approved'
    case 'denied':
      return role === 'client' ? 'Denied' : 'Denied'
    default:
      return getStatusLabel(status)
  }
}

const getStatusInfo = (status: ApplicationStatus, role: UserRole = 'admin') => {
  switch (status) {
    case 'signing':
      return {
        icon: <DriveFileRenameOutlineIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'ready':
      return {
        icon: <EditNoteIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'corrections_needed':
      return {
        icon: <ErrorOutlineIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'pending':
      return {
        icon: <AssignmentIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'viewed':
      return {
        icon: <VisibilityIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'approved':
      return {
        icon: <ThumbUpIcon />,
        title: getStatusLabelByRole(status, role),
      }
    case 'denied':
      return {
        icon: <ThumbDownIcon />,
        title: getStatusLabelByRole(status, role),
      }
    default:
      return {
        icon: <EditNoteIcon />,
        title: getStatusLabelByRole(status, role),
      }
  }
}

const statusOrder: ApplicationStatus[] = ['signing', 'ready', 'corrections_needed', 'pending', 'viewed', 'approved']

export const ApplicationStatusTimeline: React.FC<ApplicationStatusTimelineProps> = ({ role = 'admin' }) => {
  const theme = useTheme()

  return (
    <Box sx={{ mb: 4, py: 2 }}>
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
        sx={{
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '25px',
            height: '2px',
            background: theme.palette.divider,
            zIndex: 0,
          },
        }}
      >
        {statusOrder.map((status, index) => {
          const { icon, title } = getStatusInfo(status, role)
          const colors = getStatusColor(status)

          return (
            <React.Fragment key={status}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  flex: 1,
                  zIndex: 1,
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: colors.main,
                      color: 'white',
                      transition: 'all 0.3s ease',
                      mb: 1,
                      boxShadow: `0 0 0 4px ${colors.light}`,
                    }}
                  >
                    {icon}
                  </Box>
                  <Tooltip title={getStatusDescriptionByRole(status, role)} arrow placement='top'>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        color: colors.main,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      <HelpOutlineIcon sx={{ fontSize: 16 }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography
                  variant='caption'
                  align='center'
                  sx={{
                    fontWeight: 600,
                    color: colors.main,
                    transition: 'all 0.3s ease',
                  }}
                >
                  {title}
                </Typography>
              </Box>
              {index < statusOrder.length - 1 && (
                <ArrowForwardIcon
                  sx={{
                    color: theme.palette.grey[400],
                    transition: 'all 0.3s ease',
                    fontSize: 20,
                    zIndex: 1,
                  }}
                />
              )}
            </React.Fragment>
          )
        })}

        {/* Denied state shown as an alternative endpoint */}
        <>
          <ArrowForwardIcon
            sx={{
              color: theme.palette.grey[400],
              fontSize: 20,
              zIndex: 1,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              flex: 1,
              zIndex: 1,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: getStatusColor('denied').main,
                  color: 'white',
                  mb: 1,
                  boxShadow: `0 0 0 4px ${getStatusColor('denied').light}`,
                }}
              >
                <ThumbDownIcon />
              </Box>
              <Tooltip title={getStatusDescriptionByRole('denied', role)} arrow placement='top'>
                <Box
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    bgcolor: 'background.paper',
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: getStatusColor('denied').main,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  <HelpOutlineIcon sx={{ fontSize: 16 }} />
                </Box>
              </Tooltip>
            </Box>
            <Typography
              variant='caption'
              align='center'
              sx={{
                fontWeight: 600,
                color: getStatusColor('denied').main,
              }}
            >
              {getStatusLabelByRole('denied', role)}
            </Typography>
          </Box>
        </>
      </Stack>
    </Box>
  )
}
