import { Box, Button, Dialog, DialogContent, DialogTitle, InputAdornment, Typography } from '@mui/material'
// import truckLogo from 'assets/Icon-OpticTruckWorks-02.png'
import { opticTruckBlue as truckLogo } from 'assets'
import CustomForm from 'components/CustomForm'
import { useAuth } from 'contexts/AuthContext'
import { fetchVinData } from 'core/api/inventory/fetchVinData'
import {
  getVehicleSizeOptions,
  vehicleFuelOptions,
  vehicleStatusOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'
import { FormField } from 'types/forms'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useInventory } from 'hooks/inventory/useInventory'
import React, { useEffect, useState } from 'react'
import { Vehicle } from '@otw/models/inventory'

interface ImportedVehicleFormProps {
  open: boolean
  vehicle: Vehicle | null
  onTransferVehicle: (
    vehicle: Omit<Vehicle, 'id'>,
    destInventoryCollection: 'master_inventory' | 'shield_inventory',
  ) => void
  onClose: () => void
}

function isValidURL(url: string) {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

const ImportedVehicleForm: React.FC<ImportedVehicleFormProps> = ({ open, onClose, vehicle, onTransferVehicle }) => {
  const [vehicleValues, setVehicleValues] = useState<any>({})
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isAdmin = userInfo?.role === 'admin'
  const isBabyAdmin = userInfo?.role === 'babyAdmin'

  const getMakeAndYearFromVin = async (vin: string) => {
    const vinData = await fetchVinData(vin)
    const make = vinData.find(item => item.Variable === 'Make')?.Value
    const year = vinData.find(item => item.Variable === 'Model Year')?.Value

    // Only update make and year fields, preserving all other values
    //@ts-ignore
    setVehicleValues(prev => ({
      ...prev,
      //@ts-ignore
      make: make || prev.make || '',
      //@ts-ignore
      year: year || prev.year || '',
      //@ts-ignore
      type: prev.type || '',
      //@ts-ignore
      size: prev.size || '',
      //@ts-ignore
      fuel: prev.fuel || '',
      //@ts-ignore
      miles: prev.miles || '',
      //@ts-ignore
      location: prev.location || '',
      //@ts-ignore
      condition: prev.condition || '',
      //@ts-ignore
      status: prev.status || '',
      vin: vin, // Ensure VIN is preserved
    }))
  }

  const fields = getInventoryFormFields(isAdmin, isBabyAdmin, getMakeAndYearFromVin, true)

  const subTitles: { [beforFieldNumber: number]: string } = {
    1: 'Truck Information',
  }
  if (isAdmin) {
    subTitles[11] = 'Contact Information'
    subTitles[14] = 'Finance Information'
  } else if (isBabyAdmin) {
    subTitles[10] = 'Finance Information'
  }

  useEffect(() => {
    if (vehicle) {
      setVehicleValues(vehicle)
    }
  }, [vehicle])

  if (!vehicle && !userInfo) {
    return null
  }

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle p={0} justifyContent='center' alignContent='center' display='flex'>
        <Typography variant='h1'> Transfer Truck</Typography>
        <Box component='img' src={truckLogo} height={48} width={48} alt='' />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '100%', paddingBottom: 0 }}>
        <CustomForm
          fields={fields}
          initialValues={vehicleValues}
          subTitles={subTitles}
          onSubmit={data => onTransferVehicle(data as unknown as Omit<Vehicle, 'id'>, 'master_inventory')}
          submitText={`to Inventory`}
          onSecondarySubmit={data => onTransferVehicle(data as unknown as Omit<Vehicle, 'id'>, 'shield_inventory')}
          secondarySubmitText={`to Shield`}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  )
}

const getInventoryFormFields = (
  isAdmin: boolean,
  isBabyAdmin: boolean,
  getMakeAndYearFromVin: (vin: string) => Promise<void>,
  isEdit: boolean,
) => {
  const { vehicles } = useInventory('master_inventory')
  const fields: FormField[] = [
    ...(isAdmin
      ? [
          {
            name: 'vin',
            type: 'text' as FormField['type'],
            label: 'VIN',
            validation: {
              validateValue: (value: string | number | boolean) => {
                if (typeof value !== 'string') return false
                if (value.length !== 17) return 'VIN must be exactly 17 characters long'
                if (value.length === 17 && !isEdit) {
                  if (vehicles?.some(v => v.vin === value)) {
                    return 'Vehicle already exists in inventory'
                  }
                }
                return true
              },
              errorText: '',
            },
            textFieldProps: {
              InputProps: {
                endAdornment: <InputAdornment position='end'></InputAdornment>,
              },
              helperText: (value: string) => `${String(value || '').length}/17`,
              onInput: (e: React.FormEvent<HTMLInputElement>) => {
                const value = (e.target as HTMLInputElement).value
                if (value.length === 17 && !isEdit) {
                  const validationResult = vehicles?.some(v => v.vin === value)
                  if (!validationResult) {
                    getMakeAndYearFromVin(value).catch(error => {
                      console.error('Error fetching VIN data:', error)
                    })
                  }
                }
              },
            },
            fullWidth: true,
            showTitle: true,
            required: true,
          },
        ]
      : []),
  ]
  const commonFields: FormField[] = [
    { name: 'make', type: 'text', label: 'Make', required: true },
    { name: 'model', type: 'text', label: 'Model', required: true },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      selectValues: ['', ...vehicleTypeOptions, 'Unknown'],
      required: true,
    },
    {
      name: 'size',
      type: 'select',
      label: 'Size',
      selectValues: values => getVehicleSizeOptions(values.type as string),
      required: true,
    },
    { name: 'fuel', type: 'select', label: 'Fuel', selectValues: vehicleFuelOptions, required: true },
    {
      name: 'miles',
      type: 'text',
      label: 'Mileage',
      required: true,
      validation: {
        validateValue: (value: string | number | boolean) => {
          const sanitizedValue = typeof value === 'string' ? value.replace(/,/g, '') : String(value)
          const num = Number(sanitizedValue)
          return !isNaN(num) && num >= 0
        },
        errorText: 'Mileage must be a positive number',
      },
      textFieldProps: {
        type: 'number',
      },
    },
    {
      name: 'year',
      type: 'text',
      label: 'Year',
      required: true,
      validation: {
        validateValue: value => {
          const num = Number(value)
          if (isNaN(num)) return false

          return num >= 2000 && num <= new Date().getFullYear()
        },
        errorText: 'Year must be between 2000 and current year',
      },
    },
    { name: 'location', type: 'text', label: 'Location', required: true },
    { name: 'condition', type: 'select', label: 'Condition', selectValues: ['', 'Used', 'New'], required: true },
    {
      name: 'status',
      type: 'select',
      label: 'Status',
      required: true,
      selectValues: vehicleStatusOptions,
    },
  ]

  const contactFields: FormField[] = [
    {
      name: 'who',
      type: 'text',
      label: 'Who',
      required: false,
    },
    ...(isAdmin
      ? [
          {
            name: 'phone_number',
            type: 'text' as FormField['type'],
            label: 'Phone Number',
            required: false,
            validation: {
              validateValue: (value: string | number | boolean) => {
                const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                return typeof value === 'string' && phoneRegex.test(value)
              },
              errorText: 'Please enter a valid phone number',
            },
          },
        ]
      : []),
    {
      name: 'link',
      type: 'text',
      label: 'Web Link',
      validation: {
        validateValue: value => typeof value === 'string' && isValidURL(value),
        errorText: 'URL is not valid',
      },
      button: value => (value ? <Button onClick={() => window.open(String(value), '_blank')}>View</Button> : null),
      fullWidth: true,
    },
  ]

  const financeFields: FormField[] = [
    {
      name: 'optic_list_price',
      type: 'text',
      label: 'Optic List Price',
      validation: {
        validateValue: (value: string | number | boolean) => {
          const num = Number(value)
          return !isNaN(num) && num >= 0
        },
        errorText: 'Optic List Price must be a positive number',
      },
      textFieldProps: {
        type: 'number',
        InputProps: {
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        },
      },
    },
    ...(isAdmin
      ? [
          {
            name: 'seller_asking_price',
            type: 'text' as FormField['type'],
            label: 'Seller Asking Price',
            required: true,
            validation: {
              validateValue: (value: string | number | boolean) => {
                const num = Number(value)
                return !isNaN(num) && num >= 0
              },
              errorText: 'Seller Asking Price must be a positive number',
            },
            textFieldProps: {
              type: 'number',
              InputProps: {
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              },
            },
          },
        ]
      : []),
    {
      name: 'sold_date',
      type: 'text',
      label: 'Sold Date',
      required: false,
    },
  ]

  fields.push(...commonFields)
  if (!isBabyAdmin) {
    fields.push(...contactFields)
  }
  fields.push(...financeFields)
  fields.push({
    name: 'truck_notes',
    type: 'text',
    label: 'Notes',
    showTitle: true,
    fullWidth: true,
    textFieldProps: {
      multiline: true,
      rows: 4,
    },
  })

  return fields
}

export default ImportedVehicleForm
