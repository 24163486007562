import { useMutation } from '@tanstack/react-query'
import { db } from 'config/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { useAuth } from 'contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'contexts/snackBarContext'
import { useBankAgent } from 'hooks/bank/useBankAgent'

export const useBankAgentOnboarding = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const { bankData } = useBankAgent()

  const completeBankAgentOnboarding = useMutation({
    mutationFn: async () => {
      if (!currentUser?.uid) throw new Error('No user found')
      if (!bankData) throw new Error('No bank data found')

      const userRef = doc(db, 'users', currentUser.uid)
      await updateDoc(userRef, {
        needsOnboarding: false,
        onboardingCompletedAt: new Date().toISOString(),
      })
    },
    onSuccess: () => {
      showSnackbar('Onboarding completed successfully', 'success')
      navigate('/bank')
    },
    onError: error => {
      showSnackbar(error instanceof Error ? error.message : 'Failed to complete onboarding', 'error')
    },
  })

  return {
    completeBankAgentOnboarding,
    bankData,
  }
}
