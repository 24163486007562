import React from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material'
import { Application } from '@otw/models'


interface AddNotesDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
}

const ViewNotesDialog: React.FC<AddNotesDialogProps> = ({ application, isOpen, onClose }) => {
  // TODO: Handle set notes

  // const [notes, setNotes] = useState("");

  // useEffect(() => {
  //     if (application) {
  //         setNotes(application.notes);
  //     }
  // }, [application]);

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Add Notes</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Application ID'
          margin='dense'
          value={application?.id || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Client Email'
          margin='dense'
          value={application?.user_email || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Time Created'
          margin='dense'
          value={application?.time_created || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Status'
          margin='dense'
          value={application?.status || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Notes'
          multiline
          rows={4}
          margin='dense'
          value={application?.notes}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewNotesDialog
