import { useCallback, useState } from 'react'
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  DocumentData,
  query,
  where,
  getDoc,
  QuerySnapshot,
} from 'firebase/firestore'
import { db } from 'config/firebase'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'contexts/snackBarContext'
import { useAuth } from 'contexts/AuthContext'
import { inventoryListConverter, userInventoryListConverter } from 'converters/inventoryListConverter'
import { InventoryList } from '@otw/models'

interface UseListBuilderOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export const useListBuilder = (options?: UseListBuilderOptions) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()
  const [loading, setLoading] = useState(false)

  // Fetch lists based on user type
  const {
    data: lists,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['inventoryLists', userInfo?.id],
    queryFn: async () => {
      if (!userInfo?.id) return []

      let listsQuery
      if (userInfo.role === 'admin') {
        listsQuery = query(collection(db, 'inventoryLists').withConverter(inventoryListConverter))
      } else {
        const userListsQuery = query(
          collection(db, 'userInventoryLists').withConverter(userInventoryListConverter),
          where('userId', '==', userInfo.id),
        )
        const userListsSnapshot = await getDocs(userListsQuery)
        const listIds = userListsSnapshot.docs.map(doc => doc.data().inventoryListId)

        if (listIds.length === 0) return []

        listsQuery = query(
          collection(db, 'inventoryLists').withConverter(inventoryListConverter),
          where('id', 'in', listIds),
        )
      }

      const snapshot = (await getDocs(listsQuery)) as QuerySnapshot<InventoryList>
      return snapshot.docs.map(doc => doc.data())
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  // Create a new list
  const createList = useMutation({
    mutationFn: async ({
      name,
      description,
      initialInventoryIds,
    }: {
      name: string
      description: string
      initialInventoryIds: string[]
    }) => {
      if (!userInfo?.id) throw new Error('User not authenticated')

      const listDoc = await addDoc(collection(db, 'inventoryLists'), {
        dateCreated: new Date().toISOString(),
        createdBy: userInfo.id,
        inventoryIds: initialInventoryIds,
        name,
        description,
        isArchived: false,
      })

      await addDoc(collection(db, 'userInventoryLists'), {
        userId: userInfo.id,
        inventoryListId: listDoc.id,
        dateAdded: new Date().toISOString(),
      })

      return listDoc.id
    },
    onSuccess: () => {
      showSnackbar('List created successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error creating list', 'error')
      console.error('Error creating list:', error)
      options?.onError?.(error)
    },
  })

  // Add vehicles to list
  const addVehiclesToList = useMutation({
    mutationFn: async ({ listId, vehicleIds }: { listId: string; vehicleIds: string[] }) => {
      const listRef = doc(collection(db, 'inventoryLists'), listId)
      const listDoc = await getDoc(listRef)
      const currentList = listDoc.data() as InventoryList

      const updatedIds = [...new Set([...currentList.inventoryIds, ...vehicleIds])]
      await updateDoc(listRef, { inventoryIds: updatedIds })
    },
    onSuccess: () => {
      showSnackbar('Vehicles added to list successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error adding vehicles to list', 'error')
      console.error('Error adding vehicles to list:', error)
      options?.onError?.(error)
    },
  })

  // Remove vehicles from list
  const removeVehiclesFromList = useMutation({
    mutationFn: async ({ listId, vehicleIds }: { listId: string; vehicleIds: string[] }) => {
      const listRef = doc(collection(db, 'inventoryLists'), listId)
      const listDoc = await getDoc(listRef)
      const currentList = listDoc.data() as InventoryList

      const updatedIds = currentList.inventoryIds.filter(id => !vehicleIds.includes(id))
      await updateDoc(listRef, { inventoryIds: updatedIds })
    },
    onSuccess: () => {
      showSnackbar('Vehicles removed from list successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error removing vehicles from list', 'error')
      console.error('Error removing vehicles from list:', error)
      options?.onError?.(error)
    },
  })

  // Archive list
  const archiveList = useMutation({
    mutationFn: async (listId: string) => {
      const listRef = doc(collection(db, 'inventoryLists'), listId)
      await updateDoc(listRef, { isArchived: true })
    },
    onSuccess: () => {
      showSnackbar('List archived successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error archiving list', 'error')
      console.error('Error archiving list:', error)
      options?.onError?.(error)
    },
  })

  // Delete list
  const deleteList = useMutation({
    mutationFn: async (listId: string) => {
      // Delete the list document
      await deleteDoc(doc(collection(db, 'inventoryLists'), listId))

      // Delete user associations
      const userAssociationsQuery = query(
        collection(db, 'userInventoryLists'),
        where('inventoryListId', '==', listId),
      )
      const userAssociations = await getDocs(userAssociationsQuery)
      await Promise.all(userAssociations.docs.map(doc => deleteDoc(doc.ref)))
    },
    onSuccess: () => {
      showSnackbar('List deleted successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error deleting list', 'error')
      console.error('Error deleting list:', error)
      options?.onError?.(error)
    },
  })

  // Share list with user
  const shareListWithUser = useMutation({
    mutationFn: async ({ listId, userId }: { listId: string; userId: string }) => {
      await addDoc(collection(db, 'userInventoryLists'), {
        userId,
        inventoryListId: listId,
        dateAdded: new Date().toISOString(),
      })
    },
    onSuccess: () => {
      showSnackbar('List shared successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['inventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error sharing list', 'error')
      console.error('Error sharing list:', error)
      options?.onError?.(error)
    },
  })

  return {
    lists,
    loading: isLoading || loading,
    isError,
    error,
    createList: createList.mutate,
    addVehiclesToList: addVehiclesToList.mutate,
    removeVehiclesFromList: removeVehiclesFromList.mutate,
    archiveList: archiveList.mutate,
    deleteList: deleteList.mutate,
    shareListWithUser: shareListWithUser.mutate,
    refetchLists: refetch,
  }
}
