import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore'
// these were used previously and were changed to the ones below blindly by @adamsiwiec1
// import logo1 from '../../../assets/LogoVariation-OpticTruckWorks-02.png'
// import icon from '../../../assets/Icon-OpticTruckWorks-03.png'
// import {
//   logoNoBackground,
//   opticTruckBlue,
// } from '../../../assets'
import opticTruckBlue from '../../../assets/icon-otw.png'
import opticTruckRed from '../../../assets/red-icon-otw.png'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

interface InventoryItem {
  stock_number: string
  make: string
  model: string
  size: string
  fuel: string
  miles: number
  year: number
  condition: string
  location: string
  type: string
  status: string
}

interface MasterInventoryItem extends InventoryItem {
  optic_list_price: string
}

interface ShieldInventoryItem extends InventoryItem {
  seller_asking_price: string
  who: string
  email: string
  phone_number: string
}

const formatCurrency = (value: string | number | undefined): string => {
  if (value === undefined) return ''
  const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : value
  return isNaN(numValue) ? '' : numValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

const formatNumber = (value: number | string): string => {
  const numValue = typeof value === 'string' ? parseInt(value.replace(/[^0-9-]+/g, ''), 10) : value
  return numValue.toLocaleString('en-US')
}

const convertToPDF = (items: (MasterInventoryItem | ShieldInventoryItem)[]): jsPDF => {
  const isMasterInventory = 'optic_list_price' in items[0]

  const headers = isMasterInventory
    ? [
        'Stock Number',
        'Make',
        'Model',
        'Size',
        'Fuel',
        'Miles',
        'Year',
        'Condition',
        'Location',
        'Type',
        'Status',
        'Sale Price',
      ]
    : [
        'Stock Number',
        'Make',
        'Model',
        'Size',
        'Fuel',
        'Miles',
        'Year',
        'Condition',
        'Location',
        'Type',
        'Status',
        'Price',
        'Seller Name',
        'Email',
        'Phone',
      ]

  const rows = items.map(item => {
    const baseRow = [
      item.stock_number || '',
      item.make || '',
      item.model || '',
      item.size || '',
      item.fuel || '',
      formatNumber(item.miles || 0),
      item.year || '',
      item.condition || '',
      item.location || '',
      item.type || '',
      item.status || '',
    ]

    if (isMasterInventory) {
      return [...baseRow, formatCurrency((item as MasterInventoryItem).optic_list_price)]
    } else {
      const shieldItem = item as ShieldInventoryItem
      return [
        ...baseRow,
        formatCurrency(shieldItem.seller_asking_price),
        shieldItem.who || '',
        shieldItem.email || '',
        shieldItem.phone_number || '',
      ]
    }
  })

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4',
    compress: true,
  })
  doc.setFontSize(10)

  const addLogos = (doc: jsPDF) => {
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height

    // Add logo1 at the top center
    const logo1Width = 45
    const logo1Height = 12
    const logo1X = (pageWidth - logo1Width) / 2
    doc.addImage(opticTruckBlue, 'PNG', logo1X, 1, logo1Width, logo1Height)

    // Add icon at the bottom center
    const iconWidth = 18
    const iconHeight = 18
    const iconX = (pageWidth - iconWidth) / 2
    doc.addImage(opticTruckRed, 'PNG', iconX, pageHeight - 15, iconWidth, iconHeight)
  }

  let firstPage = true

  doc.autoTable({
    head: [headers],
    body: rows,
    startY: 14,
    theme: 'grid',
    headStyles: { fillColor: [52, 73, 94], textColor: 255 },
    styles: { fontSize: 8, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 'auto' },
      1: { cellWidth: 'auto' },
      2: { cellWidth: 'auto' },
      3: { cellWidth: 'auto' },
      4: { cellWidth: 'auto' },
      5: { cellWidth: 'auto', halign: 'right' },
      6: { cellWidth: 'auto', halign: 'center' },
      7: { cellWidth: 'auto' },
      8: { cellWidth: 'auto' },
      9: { cellWidth: 'auto' },
      10: { cellWidth: 'auto' },
      11: { cellWidth: 'auto', halign: 'right' },
      ...(isMasterInventory
        ? {}
        : {
            12: { cellWidth: 'auto' },
            13: { cellWidth: 'auto' },
            14: { cellWidth: 'auto' },
          }),
    },
    alternateRowStyles: { fillColor: [242, 242, 242] },
    margin: { left: 10, right: 10 },
    didDrawPage: (data: { pageNumber: number; settings: { startY: number } }) => {
      addLogos(doc)
      if (firstPage) {
        firstPage = false
      } else {
        data.settings.startY = 25
      }
    },
  })

  return doc
}

export const downloadShareableInventoryPdf = async (
  collectionName: 'master_inventory' | 'shield_inventory',
  ids: string[],
) => {
  const db = getFirestore()
  const inventoryCollection = collection(db, collectionName)

  const batchSize = 30
  const inventoryItems: (MasterInventoryItem | ShieldInventoryItem)[] = []

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize)
    const inventoryQuery = query(inventoryCollection, where('__name__', 'in', batchIds))
    const querySnapshot = await getDocs(inventoryQuery)

    querySnapshot.forEach(doc => {
      const vehicle = doc.data()
      const fuel = vehicle.fuel === 'Gasoline' ? 'Gas' : vehicle.fuel || ''
      // Common properties for both inventory types
      const baseItem: InventoryItem = {
        stock_number: vehicle.stock_number || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        size: vehicle.size || '',
        fuel: fuel,
        miles: vehicle.miles || 0,
        year: vehicle.year || '',
        condition: vehicle.condition || '',
        location: vehicle.location || '',
        type: vehicle.type || '',
        status: vehicle.status || '',
      }

      const item =
        collectionName === 'master_inventory'
          ? ({
              ...baseItem,
              optic_list_price: vehicle.optic_list_price || '',
            } as MasterInventoryItem)
          : ({
              ...baseItem,
              seller_asking_price: vehicle.seller_asking_price || '',
              who: vehicle.who || '',
              email: vehicle.email || '',
              phone_number: vehicle.phone_number || '',
            } as ShieldInventoryItem)
      inventoryItems.push(item)
    })
  }

  const doc = convertToPDF(inventoryItems)
  doc.save('inventory_data.pdf')
}
