import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material'
import React, { useState } from 'react'

// Icon greedy import
import { useSnackbar } from 'contexts/snackBarContext'
import { getPhotoSubmissionObject } from 'core/api/inventory/getPhotoSubmission'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import { useQuery } from 'react-query'
import ReviewSubmittedPhotosTab from '../Tabs/ReviewSubmittedPhotosTab'
import UploadPhotosTab from '../Tabs/UploadPhotosTab'
import ViewPhotosTab from '../Tabs/ViewPhotosTab'
import { PhotoSubmissionForeignKey, Vehicle } from '@otw/models'

interface ManagePhotosDialogProps {
  collectionName: string
  open: boolean
  onClose: () => void
  vehicle: Vehicle
}

type StagedPhoto = {
  file: File
  previewBlobUrl: string
}

const ManagePhotosDialog: React.FC<ManagePhotosDialogProps> = ({ collectionName, open, onClose, vehicle: row }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { showSnackbar } = useSnackbar()
  const isMobile = useIsMobile()

  const {
    data: photos,
    isLoading: isLoadingPhotos,
    deletePhotos,
    uploadPhotos,
  } = usePhotosForInventoryObject(collectionName, row.id, {
    enabled: row.photo_storage_path !== '',
  })

  const { data: photoSubmissions, isLoading: isLoadingPhotoSubmissions } = useQuery(
    ['photoSubmissions', row.id],
    async () => {
      if (!row.uploadedPhotoIds || row.uploadedPhotoIds.length === 0) return null

      const promisses = row.uploadedPhotoIds.map((photoUploadFKey: string) =>
        getPhotoSubmissionObject({ photoId: photoUploadFKey, userId: row.assigned_user?.id || '' }),
      )
      return Promise.all(promisses)
    },
    {
      enabled: !!row.uploadedPhotoIds && row.uploadedPhotoIds.length > 0,
    },
  )

  const handleUploadPhotos = async (stagedPhotos: StagedPhoto[]) => {
    try {
      await uploadPhotos(stagedPhotos)
      showSnackbar('Photos uploaded successfully!', 'success')
    } catch (error) {
      showSnackbar('Error uploading photos', 'error')
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      sx={{ justifyContent: 'center' }}
    >
      <DialogTitle id='form-dialog-title'>
        {row.make} {row.model}: VIN#{row.vin}
      </DialogTitle>
      {isLoadingPhotos || isLoadingPhotoSubmissions ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 500,
            height: 450,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label={isMobile ? 'View' : 'View Photos'} />
              <Tab label={isMobile ? 'Upload' : 'Upload Photos'} />
              <Tab label='Review Submissions' />
            </Tabs>
          </Box>
          <DialogContent>
            {tabIndex === 0 && (
              <ViewPhotosTab
                collectionName={collectionName}
                vehicleId={row.id}
                photos={photos}
                onDeleteSelectedPhotos={deletePhotos}
              />
            )}
            {tabIndex === 1 && <UploadPhotosTab onSubmitAddedPhotos={handleUploadPhotos} />}
            {tabIndex === 2 && <ReviewSubmittedPhotosTab row={row} photoSubmissions={photoSubmissions} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ManagePhotosDialog
