// import logoDark from './Icon-OpticTruckWorks-03.png'
const logoDarkUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2FIcon-OpticTruckWorks-03.png?alt=media&token=ca5d0d5d-088e-46b5-957d-0ed5b93c3acc'
// import logoLight from './Icon-OpticTruckWorks-03.png'
const logoLightUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2FIcon-OpticTruckWorks-03.png?alt=media&token=ca5d0d5d-088e-46b5-957d-0ed5b93c3acc'
// import logoNoBackground from './Icon-OpticTruckWorks-03.png'
const logoNoBackgroundUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2FIcon-OpticTruckWorks-03.png?alt=media&token=ca5d0d5d-088e-46b5-957d-0ed5b93c3acc'
// import authBackground from './home.jpg'
// import stepVan from './step-van.png'
// import boxTruck from './box-truck.png'

const opticTruckBlueUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2FIcon-OpticTruckWorks-02.png?alt=media&token=acee3440-3dd0-4dbb-b0ee-3a8c9dd320a4'
const stepVanUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2Fstep-van.png?alt=media&token=d82dc584-36bc-4a18-92e5-3da4608f7781'
const authBackgroundUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2Fhome.jpg?alt=media&token=b9e27ace-905a-493c-9b16-68dab76df45c'
const boxTruckUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2Fbox-truck.png?alt=media'
// import cargoVan from './cargo-van.png'
const cargoVanUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2Fcargo-van.png?alt=media'
// import cutaway from './cutaway.png'
const cutawayUrl =
  'https://firebasestorage.googleapis.com/v0/b/otw-production-91ffb.appspot.com/o/public%2Fcutaway.png?alt=media'

const preloadImage = (src: string): string => {
  const img = new Image()
  img.src = src
  return src
}

const logoDark = preloadImage(logoDarkUrl)
const logoLight = preloadImage(logoLightUrl)
const logoNoBackground = preloadImage(logoNoBackgroundUrl)
const authBackground = preloadImage(authBackgroundUrl)
const stepVan = preloadImage(stepVanUrl)
const boxTruck = preloadImage(boxTruckUrl)
const cargoVan = preloadImage(cargoVanUrl)
const cutaway = preloadImage(cutawayUrl)
const opticTruckBlue = preloadImage(opticTruckBlueUrl)
export { logoDark, logoLight, logoNoBackground, authBackground, stepVan, boxTruck, cargoVan, cutaway, opticTruckBlue }
