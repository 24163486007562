import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import ViewPhotoSubmissionTab from './ViewPhotoSubmissionTab'
import { PhotoSubmissionObject } from '@otw/models'
import { Vehicle } from '@otw/models'

interface ReviewSubmittedPhotosTabProps {
  row: Vehicle
  photoSubmissions: PhotoSubmissionObject[] | null | undefined
}

const ReviewSubmittedPhotosTab: React.FC<ReviewSubmittedPhotosTabProps> = ({ row, photoSubmissions }) => {
  const [selectedPhotoSubmission, setSelectedPhotoSumbission] = useState<PhotoSubmissionObject | null>(null)

  return (
    <>
      {selectedPhotoSubmission ? (
        <ViewPhotoSubmissionTab
          onBack={() => setSelectedPhotoSumbission(null)}
          rowId={row.id}
          photoSubmission={selectedPhotoSubmission}
        />
      ) : (
        <>
          {!row.uploadedPhotoIds ||
            (row.uploadedPhotoIds.length === 0 && (
              <Typography>No photo upload submissions for this vehicle</Typography>
            ))}
          {photoSubmissions &&
            photoSubmissions.map((photoSubmissionobj: PhotoSubmissionObject) => (
              <Box key={photoSubmissionobj.photoId}>
                <Grid container spacing={2} key={photoSubmissionobj.photoId}>
                  <Grid item xs={12}>
                    <Card key={photoSubmissionobj.photoId}>
                      <CardContent>
                        <Grid container alignItems='center'>
                          <Grid item xs={10}>
                            <Typography variant='h6'>UserId: {photoSubmissionobj.userId}</Typography>
                            <Typography variant='h6'>PhotoId: {photoSubmissionobj.photoId}</Typography>
                            <Typography variant='h6'>Submitted At: {photoSubmissionobj.dateUploaded}</Typography>
                            <Typography variant='h6'>
                              Submitted Photos: {photoSubmissionobj.photos.length}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Button onClick={() => setSelectedPhotoSumbission(photoSubmissionobj)}>
                              View Photos
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </>
      )}
    </>
  )
}

export default ReviewSubmittedPhotosTab
