import { addDoc, collection } from '@firebase/firestore'
import { db } from 'config/firebase'

export const sendSMS = async (toPhoneNumber: string, message: string) => {

  try {
    const messageDoc = {
      to: toPhoneNumber,
      body: message,
    }

    await addDoc(collection(db, 'messages'), messageDoc)
  
  } catch {
    console.error('Error sending SMS.')
  }
}
