/* eslint-disable */

// TODO: Remove eslint-disable and chanlde user info type in set state

import { getAuth, signInWithEmailAndPassword, MultiFactorError } from '@firebase/auth'
import React from 'react'
import { doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { privateUserConverter } from '@otw/models/converters/privateUserConverter'

export const handleLoginWithEmail = async (
  email: string,
  password: string,
  setUserInfo: React.Dispatch<any>,
): Promise<MultiFactorError | FirebaseError | void> => {
  const auth = getAuth()
  const db = getFirestore()

  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    const userDoc = await getDoc(doc(db, 'users', user.uid).withConverter(privateUserConverter))
    if (!userDoc.exists()) {
      throw new Error('User data not found')
    }

    const userData = userDoc.data()
    setUserInfo(userData)

    return
  } catch (error: any) {
    console.error('Email login error details:', {
      error,
      code: error?.code,
      message: error?.message,
      name: error?.name,
    })

    if (error?.code === 'auth/multi-factor-auth-required') {
      throw error
    }

    if (error?.code === 'auth/invalid-credential') {
      throw error
    }

    // Check if error has a code property (Firebase error) or is a FirebaseError instance
    if (error?.code || error instanceof FirebaseError) {
      throw error
    }

    throw error
  }
}
