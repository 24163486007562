enum NotificationType {
  user_set_initial_password = "user_set_initial_password",
  inventory_events = "inventory_events",
  inventory_published_on_site_event = "inventory_published_on_site_event",
  inventory_sold_event = "inventory_sold_event",
  user_created_event = "user_created_event",
  application_created_event = "application_created_event",
  application_status_change_event = "application_status_change_event",
}

export { NotificationType };
