import { Close, Edit, Add, Save } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import NumberInput from 'components/NumberInput'
import { FormulaRule } from '@otw/models'
import React, { useState } from 'react'

// TODO: add edit mode to rule

interface RuleLineProps {
  rule: FormulaRule
  handleSumbitRule: (rule: FormulaRule) => void
  handleDeleteRule: (rule: FormulaRule) => void
}

const RuleLine: React.FC<RuleLineProps> = ({ rule, handleSumbitRule, handleDeleteRule }) => {
  const [ruleState, setRuleState] = useState(rule)
  const [error, setError] = useState('')
  const currentYear = new Date().getFullYear()

  const handleSubmit = () => {
    if (ruleState.operationValue <= 0) {
      setError('Operation value must be greater than 0')
      return
    }

    if (ruleState.targetFactor === 'year') {
      if ((ruleState.lowerBound && ruleState.lowerBound > currentYear) || 
          (ruleState.upperBound && ruleState.upperBound < 1990)) {
        setError(`Starting year must be before ${currentYear} and ending year must be after 1990`)
        return
      }
      if (ruleState.lowerBound && ruleState.upperBound && ruleState.lowerBound < ruleState.upperBound) {
        setError('Starting year must be higher than ending year (e.g. between 2023 and 2020)')
        return
      }
    }

    setError('')
    handleSumbitRule({...ruleState, inEditMode: false})
  }

  const handleEdit = () => {
    setRuleState(prev => ({ ...prev, inEditMode: true }))
  }

  const renderValue = (value: number | undefined, field: 'lowerBound' | 'upperBound' | 'operationValue') => {
    if (ruleState.inEditMode) {
      return (ruleState.targetFactor === 'mileage') ? (
          <NumberInput
            size='small'
            value={value ?? null}
            onChange={newValue => {
              setRuleState(prev => ({
                ...prev,
                [field]: newValue ?? undefined,
              }))
            }}
            sx={{ width: 120 }}
          />
        ) : (
          <TextField
            size='small'
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*',
            }}
            value={value ?? ''}
            onChange={e => {
              const input = e.target.value;
              if (input === '' || /^\d*$/.test(input)) {
                const newValue = input === '' ? undefined : parseInt(input) || 0;
                setRuleState(prev => ({
                  ...prev,
                  [field]: newValue,
                }))
              }
            }}
            sx={{ width: 120 }}
          />
        )
      }

    return <Typography variant='body2'>
      {ruleState.targetFactor === 'mileage' 
        ? value?.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) ?? '-'
        : value?.toFixed(field === 'operationValue' ? 2 : 0) ?? '-'
      }
    </Typography>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        p: 1.5,
        border: '1px solid #e0e0e0',
        borderRadius: 1,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Typography variant='body2' color='text.secondary'>
            {ruleState.targetFactor === 'mileage' ? 'Mileage' : 'Year'} between
          </Typography>
          {renderValue(ruleState.lowerBound, 'lowerBound')}
          <Typography variant='body2' color='text.secondary'>
            and
          </Typography>
          {renderValue(ruleState.upperBound, 'upperBound')}

          <Box sx={{ display: 'flex', gap: 1, ml: 'auto' }}>
            {rule?.id && !rule.id.startsWith('temp-') && ruleState.inEditMode && (
              <IconButton size='small' color='error' onClick={() => handleDeleteRule?.(rule)}>
                <Close />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, ml: 4 }}>
          <Typography variant='body2' color='text.secondary'>
            Depreciates
          </Typography>

          {ruleState.inEditMode ? (
            <NumberInput
              size='small'
              value={ruleState.operationValue ?? null}
              onChange={value => {
                setRuleState(prev => ({ ...prev, operationValue: value ?? 0 }))
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      {ruleState.operationType === 'subtract' ? '$' : '%'}
                    </InputAdornment>
                  ),
                }
              }}
              decimalScale={ruleState.targetFactor === 'mileage' ? 2 : 0}
              sx={{ width: 120 }}
            />
          ) : (
            <Typography variant='body2'>
              {ruleState.operationType === 'subtract' ? '$' : '%'}
              {ruleState.operationValue?.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) ?? '-'}
            </Typography>
          )}

          <Typography variant='body2' color='text.secondary'>
            per {ruleState.targetFactor === 'mileage' ? 'mile' : 'year'}
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, ml: 'auto' }}>
            {rule?.id && !rule.id.startsWith('temp-') && (
              <>
                {ruleState.inEditMode ? (
                  <IconButton size='small' color='primary' onClick={handleSubmit}>
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton size='small' color='primary' onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                )}
              </>
            )}
            {rule?.id && rule.id.startsWith('temp-') && (
              <IconButton size='small' color='primary' onClick={handleSubmit}>
                <Add />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>

      {error && (
        <Typography color='error' variant='caption'>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default RuleLine
