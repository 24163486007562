import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { pandaFunctions } from 'core/functions/pandaFunctions'
import { db } from 'config/firebase'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useAuth } from 'contexts/AuthContext'
import { Application, ApplicationStatus, Bank, PandaDocument } from '@otw/models'

export const useClientApplication = () => {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()

  const {
    data: banks = [],
    isLoading: isLoadingBanks,
    error: banksError,
  } = useQuery({
    queryKey: ['banks'],
    queryFn: async () => {
      const snapshot = await getDocs(collection(db, 'banks'))
      return snapshot.docs.map(doc => doc.data()) as Bank[]
    },
  })

  const {
    data: applications = [],
    isLoading: isLoadingApplications,
    error: applicationsError,
  } = useQuery({
    queryKey: ['clientApplications', currentUser?.uid],
    queryFn: async () => {
      try {
        if (!currentUser?.uid) return []

        const q = query(collection(db, 'applications'), where('client_id', '==', currentUser?.uid))
        const snapshot = await getDocs(q)
        console.log(
          'applications',
          snapshot.docs.map(doc => doc.data()),
        )
        return snapshot.docs.map(doc => doc.data()) as Application[]
      } catch (error) {
        console.error('Error fetching applications', error)
        return []
      }
    },
    enabled: !!currentUser?.uid,
  })

  const {
    mutate: generateApplication,
    isPending,
    error: generateApplicationError,
  } = useMutation<string, Error, void, unknown>({
    mutationFn: async () => {
      const data = await pandaFunctions.client.generateApplication()
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientApplications'] })
    },
  })

  const {
    mutate: submitApplication,
    isPending: isSubmitting,
    error: submitError,
  } = useMutation({
    mutationFn: async (pandaPayload: PandaDocument) => {
      await pandaFunctions.client.submitApplication(pandaPayload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientApplications'] })
    },
  })

  return {
    applications,
    isLoadingApplications,
    applicationsError: applicationsError instanceof Error ? applicationsError.message : 'An error occurred',
    generateApplication,
    submitApplication,
    isLoading: isPending || isSubmitting,
    error: generateApplicationError,
    submitError: submitError,
    banks,
    isLoadingBanks,
    banksError: banksError instanceof Error ? banksError.message : 'An error occurred',
  }
}
