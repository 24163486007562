import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'config/firebase'
import { PrivateUserInfoType } from '@otw/models/user'

export const updateUser = async (userData: Partial<PrivateUserInfoType>): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userData.id as string)

    // Remove any undefined or null values
    const cleanedData = Object.entries(userData).reduce(
      (acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc[key] = value
        }
        return acc
      },
      {} as Record<string, any>,
    )

    // Convert string boolean values to actual booleans
    if (typeof cleanedData.disabled === 'string') {
      cleanedData.disabled = cleanedData.disabled === 'true'
    }
    if (typeof cleanedData.emailVerified === 'string') {
      cleanedData.emailVerified = cleanedData.emailVerified === 'true'
    }

    await updateDoc(userRef, cleanedData)
    return
  } catch (error) {
    console.error('Error updating user:', error)
    throw error
  }
}
