import { Dialog, DialogContent, IconButton, Box, CircularProgress, Typography, DialogTitle, Alert } from '@mui/material'
import { PandaDocument, ApplicationStatus, Application } from '@otw/models'
import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface PandadocDialogProps {
  open: boolean
  onClose: () => void
  iframeUrl: string
  loading?: boolean
  onFormCompleted: (payload: PandaDocument) => void
  onIframeLoad?: () => void
  status?: ApplicationStatus
  application?: Application
}

const getDialogTitle = (status: ApplicationStatus | undefined) => {
  switch (status) {
    case 'ready':
      return 'Resume Your Application'
    case 'pending':
      return 'Application Awaiting Bank Review'
    case 'viewed':
      return 'Application Under Review'
    case 'approved':
      return 'Approved Application'
    case 'denied':
      return 'Declined Application'
    case 'corrections_needed':
      return 'Application Needs Corrections'
    case 'draft':
      return 'Draft Application'
    case 'signing':
      return 'Application Being Signed'
    default:
      return 'View Application'
  }
}

const PandadocDialog: React.FC<PandadocDialogProps> = ({
  open,
  onClose,
  iframeUrl,
  loading = false,
  onFormCompleted,
  onIframeLoad,
  status,
  application,
}) => {
  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        onFormCompleted(payload)
        onClose()
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onFormCompleted, onClose])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100vh - 64px)',
          m: 2,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{getDialogTitle(status)}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {status === 'corrections_needed' && application?.correction_notes && (
        <Box sx={{ px: 2, py: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Alert 
            severity="warning"
            sx={{ 
              '& .MuiAlert-message': { 
                width: '100%' 
              }
            }}
          >
            <Typography variant="subtitle1" gutterBottom fontWeight={600}>
              Required Corrections:
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
              {application.correction_notes}
            </Typography>
          </Alert>
        </Box>
      )}

      <DialogContent dividers sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Typography sx={{ mr: 2 }}>Loading application...</Typography>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <iframe
              src={iframeUrl}
              style={{
                width: '100%',
                height: '100%',
                display: iframeUrl ? 'block' : 'none',
                border: 'none',
              }}
              frameBorder="0"
              allowFullScreen
              scrolling="no"
              title="Application Form"
              onLoad={onIframeLoad}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PandadocDialog 