import {
  AttachMoney,
  Delete,
  DirectionsCar,
  Link as LinkIcon,
  LocalGasStation,
  Phone,
  LocationOn,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Vehicle } from '@otw/models'
// import icon from 'assets/Icon-OpticTruckWorks-02.png'
import { opticTruckBlue as icon } from 'assets'
import { formatDistance, formatPhoneNumber, formatPrice } from 'core/utils/inventoryUtils'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import React from 'react'

interface ImportedVehicleCardProps {
  collectionName: string
  vehicle: Vehicle
  onClick: () => void
  onDeleteClick: () => void
  cardHeight: number
  subCollection?: { name: string; parentDocId: string }
  isAdmin?: boolean
}

const ImportedVehicleCard: React.FC<ImportedVehicleCardProps> = ({
  collectionName,
  subCollection,
  vehicle,
  onClick,
  onDeleteClick,
  cardHeight,
  isAdmin,
}) => {
  const { data: photos, isLoading: isLoadingPhoto } = usePhotosForInventoryObject(
    collectionName,
    vehicle.id,
    {
      enabled: vehicle.photo_storage_path !== '',
      onlyMainPhoto: true,
    },
    subCollection,
  )
  const isMobile = useIsMobile()

  const VehiclePhoto = () => {
    if (isLoadingPhoto) {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress sx={{ mt: 2 }} />
        </Box>
      )
    }

    return (
      <>
        {photos && photos.length > 0 ? (
          <CardMedia
            component='img'
            srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
            src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
            alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            loading='lazy'
            sx={{
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            sx={{ bgcolor: 'background.default' }}
          >
            <img
              src={icon}
              alt='No Photo Available'
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        )}
      </>
    )
  }

  if (isMobile) {
    return (
      <Card
        sx={{
          width: '100%',
          mb: 2,
        }}
        onClick={onClick}
      >
        <Box sx={{ display: 'flex', height: cardHeight * 0.8 }}>
          <Box sx={{ width: '40%', height: cardHeight * 0.8 }}>
            <VehiclePhoto />
          </Box>
          <Box
            gap={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
              p: 1,
              height: '100%',
            }}
          >
            <Typography component='div' variant='subtitle2' fontWeight='bold' noWrap>
              {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' alignItems='center' color='text.secondary' sx={{ gap: 1 }}>
                <LocationOn fontSize='small' />
                <Typography variant='body2' color='text.secondary'>
                  {vehicle.location || 'N/A'}
                </Typography>
              </Box>
              <Typography variant='body2' color='text.primary'>
                {formatDistance(vehicle.miles)} miles
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.secondary' noWrap>
                {vehicle.who}
              </Typography>
              <Typography variant='body2' color='text.secondary' noWrap>
                {formatPhoneNumber(vehicle.phone_number)}
              </Typography>
            </Box>
            <Typography color='primary' fontWeight='bold' variant='subtitle1' sx={{ textAlign: 'left' }}>
              {formatPrice(vehicle.seller_asking_price)}
            </Typography>
          </Box>
        </Box>
        <CardActions sx={{ justifyContent: 'space-between', px: 1, py: 0.5 }}>
          <IconButton
            aria-label='Delete Vehicle'
            onClick={event => {
              event.stopPropagation()
              
              onDeleteClick()
            }}
            size='small'
          >
            <Delete fontSize='small' />
          </IconButton>
          {(vehicle.link != null) && (
            <Button
              variant='text'
              startIcon={<LinkIcon />}
              onClick={e => {
                e.stopPropagation()
                if (vehicle.link) {
                  window.open(vehicle.link, '_blank')
                }
              }}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
                fontSize: '0.8rem',
                padding: '2px 8px',
              }}
            >
              Go to website
            </Button>
          )}
        </CardActions>
      </Card>
    )
  }

  return (
    <Card
      sx={{ height: cardHeight, minWidth: 275, position: 'relative', '&:hover': { boxShadow: 6 } }}
      onClick={onClick}
    >
      <Box flexGrow={1}>
        <CardHeader
          title={
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6'>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
              {vehicle.status && (
                <Badge
                  color={vehicle.status === 'Available' ? 'success' : 'secondary'}
                  badgeContent={vehicle.status}
                  sx={{
                    '& .MuiBadge-badge': {
                      marginRight: 4,
                      color: 'white',
                      bgcolor: vehicle.status === 'Available' ? '#2e7d32' : '',
                    },
                  }}
                />
              )}
            </Box>
          }
        />
        <Box sx={{ height: 200, flexShrink: 0 }}>
          <VehiclePhoto />
        </Box>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <DirectionsCar fontSize='small' /> Vehicle Info
              </Typography>
              <Typography variant='body2'>
                <strong>VIN:</strong> {vehicle.vin}
              </Typography>
              <Typography variant='body2'>
                <strong>Type:</strong> {vehicle.type}
              </Typography>
              <Typography variant='body2'>
                <strong>Size:</strong> {vehicle.size}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <LocalGasStation fontSize='small' /> Performance
              </Typography>
              <Typography variant='body2'>
                <strong>Fuel:</strong> {vehicle.fuel}
              </Typography>
              <Typography variant='body2'>
                <strong>Miles:</strong> {formatDistance(vehicle.miles)}
              </Typography>
            </Grid>
            {isAdmin && (
              <Grid item xs={6}>
                <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                  <Phone fontSize='small' /> Contact
                </Typography>
                <Typography variant='body2'>
                  <strong>Contact:</strong> {vehicle.who}
                </Typography>
                <Typography variant='body2'>
                  <strong>Location:</strong> {vehicle.location}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <AttachMoney fontSize='small' /> Pricing
              </Typography>
              {isAdmin && (
                <Typography variant='body2'>
                  <strong>Buy Price:</strong> {formatPrice(vehicle.seller_asking_price)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <CardActions>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <IconButton
            aria-label='Delete Vehicle'
            onClick={event => {
              event.stopPropagation()
              
              onDeleteClick()
            }}
          >
            <Delete />
          </IconButton>
          {vehicle.link && (
            <>
              <Button
                variant='text'
                startIcon={<LinkIcon />}
                onClick={e => {
                  e.stopPropagation()
                  if (vehicle.link) {
                    window.open(vehicle.link, '_blank')
                  }
                }}
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                Go to website
              </Button>
            </>
          )}
        </Box>
      </CardActions>
    </Card>
  )
}

export default ImportedVehicleCard
