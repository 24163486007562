import React from 'react'
import { Box, Typography, Switch, FormControlLabel, Tooltip } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { NotificationPreferences, NotificationType } from '@otw/models'
import { useNotifications } from 'hooks/useNotifications'
import { useAuth } from 'hooks/auth/useAuth'

interface NotificationSectionProps {
  title: string
  expandedSubsections: Set<string>
  notificationPreferences: NotificationPreferences
  handleSubsectionExpand: (subsection: string) => void
  notificationTypes: NotificationType[]
  setNotificationPreferences: (preferences: NotificationPreferences) => void
}

const NotificationSection: React.FC<NotificationSectionProps> = ({
  title,
  expandedSubsections,
  notificationPreferences,
  handleSubsectionExpand,
  notificationTypes,
  setNotificationPreferences,
}) => {
  const { currentUser } = useAuth()
  if (!currentUser) {
    return null
  }
  const { updateNotificationPreferences } = useNotifications(currentUser.uid)
  const handleToggle = async (type: NotificationType, channel: 'email' | 'sms') => {
    const updatedPreferences = {
      ...notificationPreferences,
      [type]: {
        ...notificationPreferences[type],
        [channel]: !notificationPreferences[type][channel],
      },
    }
    updateNotificationPreferences.mutate(updatedPreferences)
    setNotificationPreferences(updatedPreferences)
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        onClick={() => handleSubsectionExpand(title)}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          bgcolor: 'background.default',
          borderRadius: 1,
        }}
      >
        <Typography variant='h6'>{title}</Typography>
        {expandedSubsections.has(title) ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {expandedSubsections.has(title) && (
        <Box sx={{ pl: 4, pr: 2, py: 2 }}>
          {notificationTypes.map(type => (
            <Box key={type} sx={{ mb: 2 }}>
              <Typography variant='subtitle1' sx={{ mb: 1 }}>
                {type
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={notificationPreferences[type]?.email || false}
                      onChange={() => handleToggle(type, 'email')}
                    />
                  }
                  label='Email'
                />
                <Tooltip title="SMS notifications are currently disabled">
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={false}
                          disabled
                        />
                      }
                      label='SMS'
                      sx={{ color: 'text.disabled' }}
                    />
                  </span>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default NotificationSection
