import React from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  List, 
  ListItem, 
  ListItemText,
  Box,
  CircularProgress
} from '@mui/material'
import { Application } from '@otw/models'

interface ViewDocumentsDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
  documents?: Record<string, string>
  isLoading?: boolean
}

const ViewDocumentsDialog: React.FC<ViewDocumentsDialogProps> = ({ 
  application, 
  isOpen, 
  onClose, 
  documents,
  isLoading 
}) => {
  if (!application) return null

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Application Documents</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
            <CircularProgress />
          </Box>
        ) : documents ? (
          <List>
            {Object.entries(documents).map(([key, url]) => (
              <ListItem key={key}>
                <ListItemText 
                  primary={key.replace(/_/g, ' ').replace('#', ' Set ')} 
                  secondary={
                    <Button 
                      href={url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      variant="outlined"
                      size="small"
                    >
                      Download
                    </Button>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={2} textAlign="center">No documents available</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewDocumentsDialog
