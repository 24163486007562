import { Box, Grid, List, ListItem } from '@mui/material'
import { ApplicationStatus, Application } from '@otw/models'
import React, { useMemo } from 'react'
import ApplicationCard from './components/cards/ApplicationCard'
import StatusSummary from './components/cards/SummaryCard'
// import ApplicationDialog from './components/components/ApplicationDialog'
import { MainLoading } from 'components/MainLoading'
import { FreeTechLogoLoading } from 'components/FreeTechLogoLoading'
import { useClientApplication } from 'hooks/client/useClientApplication'

interface ApplicationBoardProps {
  statusFilter: ApplicationStatus | 'all'
  view: 'list' | 'card'
  onResumeApplication: (url: string, status: ApplicationStatus) => void
}

const ApplicationBoard: React.FC<ApplicationBoardProps> = ({ statusFilter, view, onResumeApplication }) => {
  const [financeDialogOpen, setFinanceDialogOpen] = React.useState(false)
  const [selectedApplication, setSelectedApplication] = React.useState<Application | null>(null)

  const { applications, isLoading, applicationsError } = useClientApplication()

  const sortedApplications = useMemo(() => {
    if (!applications) return []
    
    return [...applications].sort((a, b) => {
      if (a.status === 'signing' && b.status !== 'signing') return -1
      if (a.status !== 'signing' && b.status === 'signing') return 1
      return new Date(b.time_created).getTime() - new Date(a.time_created).getTime()
    })
  }, [applications])

  const handleDraftClick = (application: Application) => {
    if (application.pandadoc_url) {
      onResumeApplication(application.pandadoc_url, application.status)
    } else {
      setFinanceDialogOpen(true)
      setSelectedApplication(application)
    }
  }

  if (isLoading) {
    return <FreeTechLogoLoading />
  }

  if (applicationsError && !applications) {
    return <div>Error loading applications: {applicationsError}</div>
  }

  return (
    <Box>
      {applications && <StatusSummary applications={applications} />}
      <Box>
        {view === 'card' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <Grid container spacing={2}>
              {sortedApplications
                .filter(application => application.status === statusFilter || statusFilter === 'all')
                .map(application => (
                  <Grid key={application.id} item xs={12} sm={6} md={4} lg={3}>
                    <ApplicationCard application={application} onClick={() => handleDraftClick(application)} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {view === 'list' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <List>
              {sortedApplications
                .filter(application => application.status === statusFilter || statusFilter === 'all')
                .map(application => <ListItem key={application.id} />)}
            </List>
          </Box>
        )}
      </Box>
      {/* {selectedApplication && (
        <ApplicationDialog
          application={selectedApplication}
          open={financeDialogOpen}
          onClose={() => setFinanceDialogOpen(false)}
        />
      )} */}
    </Box>
  )
}

export default ApplicationBoard
