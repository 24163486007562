
import { collection, DocumentData, FirestoreError, QuerySnapshot } from '@firebase/firestore'
import { UseFirestoreHookOptions, useFirestoreQuery } from '@react-query-firebase/firestore'
import { db } from 'config/firebase'
import { UseQueryResult } from 'react-query'

interface useQueryFirestoreDocumentsProps {
  inventoryCollectionName: string
  useQueryOptions?: UseFirestoreHookOptions
  useQueryResult?: UseQueryResult<QuerySnapshot<DocumentData, DocumentData>, FirestoreError>
}

export const useQueryFirestoreDocuments = ({ inventoryCollectionName, useQueryOptions, useQueryResult }: useQueryFirestoreDocumentsProps) => {
   return useFirestoreQuery(
    [inventoryCollectionName],
    collection(db, inventoryCollectionName),
    {
      ...useQueryOptions
    },
    {
      ...useQueryResult,
      select: snapshot => {
        if (!snapshot || !snapshot.docs) return []
        return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      },
      onError: error => {
        console.error(error)
      },
    },
  )}
