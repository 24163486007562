import React, { useEffect, useState, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
} from '@mui/material'
import { Check, Close, ZoomIn, ThumbUp } from '@mui/icons-material'
import { ref, listAll, getDownloadURL } from '@firebase/storage'
import { db, storage } from 'config/firebase'
import PhotoPreviewDialog from './PhotoPreviewDialog'
import { useSnackbar } from 'contexts/snackBarContext'
import { PhotoUploadVehicle } from 'types/photoUpload'
import { sendSMS } from 'core/sms/sendSMS'
import { doc, updateDoc, arrayUnion } from '@firebase/firestore'

interface TransporterPhotoPreviewDialogProps {
  open: boolean
  onClose: () => void
  vehicles: PhotoUploadVehicle[]
  userId: string
  phoneNumber: string
}

interface PhotoItem {
  id: string
  url: string
  label: string
  vehicleId: string
}

const TransporterPhotoPreviewDialog = ({ open, onClose, vehicles, userId, phoneNumber }: TransporterPhotoPreviewDialogProps) => {
  const [photos, setPhotos] = useState<PhotoItem[]>([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [previewPhoto, setPreviewPhoto] = useState<{ url: string; label: string } | null>(null)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (open) {
      fetchAllPhotos().then(setPhotos)
    }
  }, [open, vehicles, userId])

  const fetchAllPhotos = async () => {
    try {
      setLoading(true)
      setError(null)
      const allPhotos: PhotoItem[] = []

      for (const vehicle of vehicles) {
        const storagePath = `users/${userId}/photos/${vehicle.id}`
        const storageRef = ref(storage, storagePath)
        const result = await listAll(storageRef)

        const photos = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item)
            return { id: item.name, url, label: `${vehicle.make} ${vehicle.model} - ${item.name}`, vehicleId: vehicle.id }
          })
        )
        allPhotos.push(...photos)
      }

      return allPhotos
    } catch (err) {
      console.error('Error fetching photos:', err)
      setError('Failed to load photos')
      return []
    } finally {
      setLoading(false)
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handlePhotoClick = (url: string, label: string) => {
    setPreviewPhoto({ url, label })
  }

  const groupPhotosByVehicle = (photos: PhotoItem[]) => {
    return photos.reduce((acc, photo) => {
      if (!acc[photo.vehicleId]) {
        acc[photo.vehicleId] = {
          vehicleInfo: `${vehicles.find(v => v.id === photo.vehicleId)?.make} ${vehicles.find(v => v.id === photo.vehicleId)?.model}`,
          photos: []
        }
      }
      acc[photo.vehicleId].photos.push(photo)
      return acc
    }, {} as Record<string, { vehicleInfo: string, photos: PhotoItem[] }>)
  }

  const groupedPhotos = useMemo(() => groupPhotosByVehicle(photos), [photos])

  const handleGoodToGoClick = async () => {
    if (!phoneNumber) {
      showSnackbar('No phone number found. Please update it in user management.', 'error')
      return
    }

    try {
      await sendSMS(phoneNumber, 'Your photos have been approved, you are authorized to begin shipment!')
      showSnackbar('SMS sent successfully!', 'success')



      for (const vehicle of vehicles) {
        const vehicleRef = doc(db, 'users', userId, 'vehicles', vehicle.id)
        await updateDoc(vehicleRef, {
          'accepted': true
        })
      }
    } catch (error) {
      console.error('Error sending SMS or updating Firestore:', error)
      showSnackbar('Failed to send SMS or update Firestore.', 'error')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          position: 'relative',
          bgcolor: 'background.default',
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
      }}>
        <Typography variant="h6">Vehicle Photo Review</Typography>
        <IconButton onClick={onClose} sx={{ color: 'inherit' }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Box>
            {Object.entries(groupedPhotos).map(([vehicleId, data]) => (
              <Card key={vehicleId} sx={{ mb: 4, bgcolor: 'background.paper' }}>
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'grey.100',
                  borderBottom: 1,
                  borderColor: 'divider'
                }}>
                  <Typography variant="h6" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                    {data.vehicleInfo}
                  </Typography>
                  <Chip 
                    label={`${data.photos.length} photos`}
                    size="small"
                    sx={{ 
                      ml: 1,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText'
                    }}
                  />
                </Box>
                
                <Grid container spacing={2} sx={{ p: 2 }}>
                  {data.photos.map((photo) => (
                    <Grid item xs={12} sm={6} md={4} key={photo.id}>
                      <Card elevation={2}>
                        <CardMedia
                          component="div"
                          sx={{
                            height: 200,
                            position: 'relative',
                            cursor: 'pointer',
                            '&:hover': {
                              '& .zoom-overlay': {
                                opacity: 1,
                              }
                            }
                          }}
                          onClick={() => handlePhotoClick(photo.url, photo.label)}
                        >
                          <img
                            src={photo.url}
                            alt={photo.label}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <Box
                            className="zoom-overlay"
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'rgba(0,0,0,0.5)',
                              opacity: 0,
                              transition: 'opacity 0.2s',
                            }}
                          >
                            <ZoomIn sx={{ color: 'white', fontSize: 40 }} />
                          </Box>
                        </CardMedia>
                        <CardContent sx={{ py: 1 }}>
                          <Typography variant="caption" color="text.secondary">
                            {photo.label}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                <CardActions sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<ThumbUp />}
                    onClick={handleGoodToGoClick}
                    fullWidth
                  >
                    Approve Photos & Send Notification
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        )}
      </DialogContent>

      <PhotoPreviewDialog
        open={!!previewPhoto}
        onClose={() => setPreviewPhoto(null)}
        photoUrl={previewPhoto?.url || ''}
        photoLabel={previewPhoto?.label || ''}
      />
    </Dialog>
  )
}

export default TransporterPhotoPreviewDialog