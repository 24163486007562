import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  getDocs,
  writeBatch,
  onSnapshot,
} from 'firebase/firestore'
import { db } from 'config/firebase'
import { useEffect } from 'react'
import { INotification, NotificationPreferences } from '@otw/models'

export const useNotifications = (userId: string) => {
  const queryClient = useQueryClient()

  const { data: notifications = [] } = useQuery({
    queryKey: ['notifications', userId],
    queryFn: async () => {
      const q = query(
        collection(db, 'notifications'),
        where('recipientId', '==', userId),
        limit(10),
      )

      const snapshot = await getDocs(q)
      const notifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toISOString(),
      })) as INotification[]
      console.debug(notifications)

      return notifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    staleTime: Infinity,
    enabled: !!userId,
  })

  useEffect(() => {
    if (!userId) return

    const q = query(
      collection(db, 'notifications'),
      where('recipientId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(10),
    )

    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added' || change.type === 'modified') {
          queryClient.setQueryData(['notifications', userId], (oldData: INotification[] = []) => {
            const newNotification = {
              id: change.doc.id,
              ...change.doc.data(),
              createdAt: change.doc.data().createdAt,
            } as INotification

            const filteredOldData = oldData.filter(n => n.id !== newNotification.id)
            return [newNotification, ...filteredOldData].slice(0, 10)
          })
        }

        if (change.type === 'removed') {
          queryClient.setQueryData(['notifications', userId], (oldData: INotification[] = []) =>
            oldData.filter(n => n.id !== change.doc.id),
          )
        }
      })
    })

    return () => unsubscribe()
  }, [userId, queryClient])

  const markAsRead = useMutation({
    mutationFn: async (notificationId: string) => {
      const notificationRef = doc(db, 'notifications', notificationId)
      await updateDoc(notificationRef, { read: true })
    },
    onMutate: async (notificationId: string) => {
      await queryClient.cancelQueries({ queryKey: ['notifications', userId] })
      
      const previousNotifications = queryClient.getQueryData(['notifications', userId])
      
      queryClient.setQueryData(['notifications', userId], (old: INotification[] = []) => 
        old.map(notification => 
          notification.id === notificationId 
            ? { ...notification, read: true }
            : notification
        )
      )

      return { previousNotifications }
    },
    onError: (err, variables, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(['notifications', userId], context.previousNotifications)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', userId] })
    },
  })

  const markAllAsRead = useMutation({
    mutationFn: async () => {
      const batch = writeBatch(db)
      notifications
        .filter(n => !n.read)
        .forEach(n => {
          const ref = doc(db, 'notifications', n.id!)
          batch.update(ref, { read: true })
        })
      await batch.commit()
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['notifications', userId] })
      
      const previousNotifications = queryClient.getQueryData(['notifications', userId])
      
      queryClient.setQueryData(['notifications', userId], (old: INotification[] = []) =>
        old.map(notification => ({ ...notification, read: true }))
      )

      return { previousNotifications }
    },
    onError: (err, variables, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(['notifications', userId], context.previousNotifications)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', userId] })
    },
  })

  const updateNotificationPreferences = useMutation({
    mutationFn: async (preferences: NotificationPreferences) => {
      const userRef = doc(db, 'users', userId)
      try {
        await updateDoc(userRef, { notifications: preferences })
      } catch (error) {
        console.error('Error updating notification preferences:', error)
      }
    },
    onMutate: async (newPreferences: NotificationPreferences) => {
      await queryClient.cancelQueries({ queryKey: ['users', userId] })
      
      const previousUser = queryClient.getQueryData(['users', userId])
      
      queryClient.setQueryData(['users', userId], (old: any) => ({
        ...old,
        notifications: newPreferences
      }))

      return { previousUser }
    },
    onError: (err, variables, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(['users', userId], context.previousUser)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users', userId] })
    },
  })

  return { notifications, markAsRead, markAllAsRead, updateNotificationPreferences }
}
