import React from 'react'
import { Box, Typography, Button, Paper, CircularProgress } from '@mui/material'
import { useBankAgentOnboarding } from 'hooks/bank/useBankAgentOnboarding'
import { useAuth } from 'contexts/AuthContext'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

export const BankOnboarding: React.FC = () => {
  const { currentUser, userInfo } = useAuth()
  const { completeBankAgentOnboarding, bankData } = useBankAgentOnboarding()

  const handleVerify = () => {
    completeBankAgentOnboarding.mutate()
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 500,
          mx: 2,
          textAlign: 'center',
        }}
      >
        <VerifiedUserIcon 
          sx={{ 
            fontSize: 64, 
            color: 'primary.main',
            mb: 2 
          }} 
        />
        
        <Typography variant='h4' gutterBottom>
          Welcome to Optic Truck Works
        </Typography>

        <Typography variant='body1' color='text.secondary' sx={{ mb: 4 }}>
          Please verify your information below to complete the onboarding process
        </Typography>

        <Box sx={{ mb: 4, p: 3, bgcolor: 'background.default', borderRadius: 2 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant='overline' color='text.secondary'>
              Your Name
            </Typography>
            <Typography variant='h6'>
              {userInfo?.first_name} {userInfo?.last_name}
            </Typography>
          </Box>

          <Box>
            <Typography variant='overline' color='text.secondary'>
              Bank Name
            </Typography>
            <Typography variant='h6'>
              {bankData?.name || 'Loading...'}
            </Typography>
          </Box>
        </Box>

        <Button
          onClick={handleVerify}
          fullWidth
          variant='contained'
          size='large'
          disabled={completeBankAgentOnboarding.isPending || !bankData}
          sx={{
            mt: 2,
            py: 1.5,
            fontSize: '1.1rem',
            borderRadius: 2,
          }}
        >
          {completeBankAgentOnboarding.isPending ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Verify & Continue'
          )}
        </Button>
      </Paper>
    </Box>
  )
}

export default BankOnboarding
