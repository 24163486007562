import { getAuth, signInWithPopup, GoogleAuthProvider, browserPopupRedirectResolver, User } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { db } from 'config/firebase'
import { getAuthUserProfile } from './getAuthUserProfile'
import { PrivateUserInfoType } from '@otw/models'
import { privateUserConverter } from '@otw/models/converters/privateUserConverter'
import { serverTimestamp } from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { MultiFactorError } from '@firebase/auth'

export const handleLoginWithGoogle = async (
  setUserInfo: React.Dispatch<React.SetStateAction<PrivateUserInfoType>>,
): Promise<MultiFactorError | FirebaseError | void> => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()

  try {
    const { user } = await signInWithPopup(auth, provider, browserPopupRedirectResolver)

    let userProfile = await getAuthUserProfile(user)

    if (!userProfile && user.email) {
      // Create new user profile if it doesn't exist
      const newUserData: PrivateUserInfoType = {
        email: user.email,
        first_name: user.displayName?.split(' ')[0] || '',
        last_name: user.displayName?.split(' ').slice(1).join(' ') || '',
        role: 'non-admin',
        disabled: false,
        email_verified: user.emailVerified,
        permissions: [],
        id: user.uid,
        dealership_name: '',
        is_active: false,
        displayName: user.displayName || '',
      }

      await setDoc(doc(db, `users/${user.uid}`).withConverter(privateUserConverter), newUserData)
      userProfile = await getAuthUserProfile(user)
    }

    if (!userProfile) {
      throw new Error('Failed to get or create user profile')
    }

    setUserInfo(userProfile)

    return
  } catch (error: any) {
    console.error('Google login error details:', {
      error,
      code: error?.code,
      message: error?.message,
      name: error?.name,
    })

    if (error?.code === 'auth/multi-factor-auth-required') {
      throw error
    }

    // Check if error has a code property (Firebase error) or is a FirebaseError instance
    if (error?.code || error instanceof FirebaseError) {
      throw error
    }

    // For network errors or other types of errors
    if (error?.message?.toLowerCase().includes('network')) {
      throw error
    }

    throw error
  }
}
