import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
// import truckLogo from 'assets/Icon-OpticTruckWorks-02.png'
import { opticTruckBlue as truckLogo } from 'assets'
import { useAuth } from 'contexts/AuthContext'
import { fetchVinData } from 'core/api/inventory/fetchVinData'
import {
  getVehicleSizeOptions,
  vehicleFuelOptions,
  vehicleStatusOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import SoldPopup from '../AgGridInventory/SoldPopup'
import { useCalculateDeal } from 'hooks/graybook/useCalculateDeal'
import { Vehicle } from '@otw/models/inventory'
import { useInventory } from 'hooks/inventory/useInventory'
import { useVinDecoder } from 'hooks/useVinDecoder'
import InfoIcon from '@mui/icons-material/Info'

interface VehicleFormProps {
  open: boolean
  vehicle: Vehicle | null
  onClose: () => void
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  inventoryCollection: 'master_inventory' | 'shield_inventory'
  generateStockNumber: () => string
  isEdit?: boolean
}

function isValidURL(url: string) {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

// FIXME: double declaration edit and isnew

const VehicleForm: React.FC<VehicleFormProps> = ({
  open,
  onClose,
  onSubmit,
  vehicle,
  inventoryCollection,
  generateStockNumber,
  isEdit = false,
}) => {
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isNew = vehicle === null
  const isAdmin = userInfo?.role === 'admin'
  const isBabyAdmin = userInfo?.role === 'babyAdmin'
  const [soldPopupOpen, setSoldPopupOpen] = useState(false)
  const [pendingFormData, setPendingFormData] = useState<{ [key: string]: string | number | boolean } | null>(null)
  const { vehicles, pauseSnapshotListener, resumeSnapshotListener } = useInventory(inventoryCollection)
  const minYear = inventoryCollection === 'shield_inventory' ? 1990 : 2000

  const [vinAlreadyExistsError, setVinAlreadyExistsError] = useState(false)

  const formSchema = z.object({
    stock_number: z.string().min(1, 'Stock Number is required'),
    vin: isAdmin
      ? z
          .string()
          .length(17, 'VIN must be exactly 17 characters long')
          .refine(val => {
            // Only validate uniqueness if this is a new vehicle (not editing)
            if (isEdit) return true
            return !vehicles?.some(v => v.vin === val)
          }, 'Vehicle already exists in inventory')
      : z.string().optional(),
    make: z.string().min(1, 'Make is required'),
    model: z.string().min(1, 'Model is required'),
    type: z.string().min(1, 'Type is required') as z.ZodType<Vehicle['type']>,
    size: z.string().min(1, 'Size is required') as z.ZodType<Vehicle['size']>,
    fuel: z.string().min(1, 'Fuel is required') as z.ZodType<Vehicle['fuel']>,
    miles: z
      .number()
      .min(0, 'Mileage must be a positive number')
      .refine(val => !isNaN(val) && val >= 0, 'Mileage must be a positive number')
      .or(z.undefined()) as z.ZodType<Vehicle['miles']>,
    year: z
      .number()
      .min(minYear, 'Year is required')
      .max(new Date().getFullYear(), `Year must be between ${minYear} and current year`)
      .refine(val => !isNaN(val), 'Year must be a valid number')
      .refine(val => {
        const num = Number(val)
        return !isNaN(num) && num >= minYear && num <= new Date().getFullYear()
      }, `Year must be between ${minYear} and current year`)
      .or(z.undefined()) as z.ZodType<Vehicle['year']>,
    location: z.string().min(1, 'Location is required') as z.ZodType<Vehicle['location']>,
    condition: z.string().min(1, 'Condition is required') as z.ZodType<Vehicle['condition']>,
    status: z.string().min(1, 'Status is required') as z.ZodType<Vehicle['status']>,
    who: z.string().optional() as z.ZodType<Vehicle['who']>,
    phone_number: z
      .string()
      .optional()
      .refine(
        val => !val || /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val),
        'Please enter a valid phone number',
      ) as z.ZodType<Vehicle['phone_number']>,
    link: z
      .string()
      .optional()
      .refine(val => !val || isValidURL(val), 'URL is not valid') as z.ZodType<Vehicle['link']>,
    optic_list_price: z
      .number()
      .optional()
      .refine(
        val => !val || (!isNaN(Number(val)) && Number(val) >= 0),
        'Optic List Price must be a positive number',
      ) as z.ZodType<Vehicle['optic_list_price']>,
    seller_asking_price: isAdmin
      ? z
          .number()
          .refine(
            val => !val || (!isNaN(Number(val)) && Number(val) >= 0),
            'Seller Asking Price must be a positive number',
          )
      : (z.number().optional() as z.ZodType<Vehicle['seller_asking_price']>),
    sold_date: z.string().nullable().optional(),
    truck_notes: z.string().optional() as z.ZodType<Vehicle['truck_notes']>,
    dealQuality: z.string().optional() as z.ZodType<Vehicle['dealQuality']>,
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
  })

  // Set initial values when component mounts or when vehicle/isNew changes
  useEffect(() => {
    if (vehicle) {
      reset({
        stock_number: vehicle.stock_number || '',
        vin: vehicle.vin || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        type: vehicle.type || '',
        size: vehicle.size || '',
        fuel: vehicle.fuel || '',
        miles: vehicle.miles || 0,
        year: vehicle.year || 0,
        location: vehicle.location || '',
        condition: vehicle.condition || '',
        status: vehicle.status || '',
        who: vehicle.who || '',
        phone_number: vehicle.phone_number || '',
        link: vehicle.link || '',
        optic_list_price: vehicle.optic_list_price || 0,
        seller_asking_price: vehicle.seller_asking_price || 0,
        sold_date: vehicle.sold_date || null,
        truck_notes: vehicle.truck_notes || '',
        dealQuality: vehicle.dealQuality || '',
      })
    } else if (isNew && userInfo) {
      reset({
        stock_number: generateStockNumber(),
        vin: '',
        make: '',
        model: '',
        type: '',
        size: '',
        fuel: '',
        miles: undefined,
        year: undefined,
        location: '',
        condition: '',
        status: '',
        who: '',
        phone_number: '',
        link: '',
        optic_list_price: undefined,
        seller_asking_price: undefined,
        sold_date: null,
        truck_notes: '',
        dealQuality: '',
      })
    }
  }, [vehicle, isNew, userInfo, generateStockNumber, reset])

  const { grayBookRetailPrice, grayBookWholeSalePrice, dealQuality, dealQualityInfo, matchedFormula } =
    useCalculateDeal({
      make: watch('make'),
      model: watch('model'),
      type: watch('type'),
      size: watch('size'),
      miles: watch('miles'),
      year: watch('year'),
      sellerAskingPrice: watch('seller_asking_price'),
    })

  const vin = watch('vin') || ''

  useEffect(() => {
    setVinAlreadyExistsError(false)
    if (vin.length === 17 && !isEdit) {
      const validationResult = vehicles?.some(v => v.vin === vin)
      if (validationResult) {
        setVinAlreadyExistsError(true)
      } else {
        fetchVinData(vin)
          .then(vinData => {
            const make = vinData.find(item => item.Variable === 'Make')?.Value
            const year = vinData.find(item => item.Variable === 'Model Year')?.Value
            setValue('make', make || '')
            setValue('year', year || '')
          })
          .catch(error => {
            console.error('Error fetching VIN data:', error)
          })
      }
    }
  }, [vin, isEdit, vehicles, setValue])

  const onSubmitForm = (data: any) => {
    const finalData = {
      ...data,
      dealQuality: dealQuality || undefined,
      grayBookWholeSalePrice: grayBookWholeSalePrice || undefined,
      grayBookRetailPrice: grayBookRetailPrice || undefined,
    }

    if (finalData.status?.toString().toLowerCase() === 'sold' && vehicle?.status?.toLowerCase() !== 'sold') {
      setPendingFormData(finalData)
      setSoldPopupOpen(true)
      return
    }
    onSubmit(finalData)
    resumeSnapshotListener()
  }

  const handleSoldSubmit = async (soldTo: string) => {
    if (!pendingFormData) return

    const finalData = {
      ...pendingFormData,
      sold_to: soldTo,
      sold_date: new Date().toISOString(),
    }
    onSubmit(finalData)
    setSoldPopupOpen(false)
    setPendingFormData(null)
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  if (!vehicle && isNew && !userInfo) {
    return null
  }

  // When opening the form
  useEffect(() => {
    pauseSnapshotListener()
  }, [])

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle p={0} justifyContent='center' alignContent='center' display='flex' sx={{ position: 'relative' }}>
        <Typography variant='h1'> {isNew ? 'Add' : 'Edit'} Truck</Typography>
        <Box component='img' src={truckLogo} height={48} width={48} alt='' />
        <Box sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
          <Tooltip 
            title="Real-time updates are paused. Changes by others won't be visible and may cause conflicts." 
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'warning.light',
                  color: 'warning.contrastText',
                  '& .MuiTooltip-arrow': {
                    color: 'warning.light',
                  },
                }
              }
            }}
          >
            <InfoIcon sx={{ color: 'warning.main', cursor: 'help' }} />
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '100%', paddingBottom: 0 }}>
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit(onSubmitForm)}
          height={{ xs: '100%', md: 'auto' }}
          width={{ xs: '100%', md: 'auto' }}
          p={0}
          overflow='hidden'
        >
          <Box flex={1} height={`calc(100% - ${isMobile ? '96px' : '64px'})`} sx={{ overflowY: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' fontWeight='bold'>
                  Basic Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('stock_number')}
                  label='Stock Number'
                  InputProps={{
                    readOnly: true,
                  }}
                  error={!!errors.stock_number}
                  helperText={errors.stock_number?.message?.toString() || ''}
                  defaultValue=''
                />
              </Grid>
              {isAdmin && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('vin')}
                    label='VIN'
                    error={!!errors.vin || vinAlreadyExistsError}
                    helperText={errors.vin?.message?.toString() || `${vin.length}/17`}
                    defaultValue=''
                    InputProps={{
                      endAdornment: vinAlreadyExistsError && (
                        <InputAdornment position='end'>
                          <Tooltip title='This VIN already exists in inventory' arrow>
                            <InfoIcon color='warning' sx={{ fontSize: 20 }} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} mt={2}>
                <Typography variant='h4' fontWeight='bold'>
                  Truck Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('make')}
                  label='Make'
                  error={!!errors.make}
                  helperText={errors.make?.message?.toString() || ''}
                  required
                  defaultValue=''
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('model')}
                  label='Model'
                  error={!!errors.model}
                  helperText={errors.model?.message?.toString() || ''}
                  required
                  defaultValue=''
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.type}>
                  <InputLabel id='type-label'>Type</InputLabel>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId='type-label' label='Type' required>
                        <MenuItem value=''> </MenuItem>
                        {vehicleTypeOptions.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                        <MenuItem value='Unknown'>Unknown</MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.type?.message?.toString() || ''}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.size}>
                  <InputLabel id='size-label'>Size</InputLabel>
                  <Controller
                    name='size'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId='size-label' label='Size' required disabled={!watch('type')}>
                        <MenuItem value=''> </MenuItem>
                        {getVehicleSizeOptions(watch('type')).map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.size?.message?.toString() || ''}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.fuel}>
                  <InputLabel id='fuel-label'>Fuel</InputLabel>
                  <Controller
                    name='fuel'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId='fuel-label' label='Fuel' required>
                        <MenuItem value=''> </MenuItem>
                        {vehicleFuelOptions.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.fuel?.message?.toString() || ''}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('miles', {
                    setValueAs: value => (value === '' ? undefined : parseInt(value, 10)),
                    valueAsNumber: true,
                  })}
                  label='Mileage'
                  error={!!errors.miles}
                  helperText={errors.miles?.message?.toString() || ''}
                  required
                  type='number'
                  defaultValue={''}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('year', {
                    setValueAs: value => (value === '' ? undefined : parseInt(value, 10)),
                    valueAsNumber: true,
                  })}
                  label='Year'
                  error={!!errors.year}
                  helperText={errors.year?.message?.toString() || ''}
                  required
                  type='number'
                  defaultValue={''}
                  inputProps={{ min: minYear, max: new Date().getFullYear() }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('location')}
                  label='Location'
                  error={!!errors.location}
                  helperText={errors.location?.message?.toString() || ''}
                  required
                  defaultValue=''
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.condition}>
                  <InputLabel id='condition-label'>Condition</InputLabel>
                  <Controller
                    name='condition'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId='condition-label' label='Condition' required>
                        <MenuItem value=''> </MenuItem>
                        {['Used', 'New'].map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.condition?.message?.toString() || ''}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.status}>
                  <InputLabel id='status-label'>Status</InputLabel>
                  <Controller
                    name='status'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId='status-label' label='Status' required>
                        <MenuItem value=''> </MenuItem>
                        {vehicleStatusOptions.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.status?.message?.toString() || ''}</FormHelperText>
                </FormControl>
              </Grid>

              {isAdmin && (
                <Grid item xs={12} mt={2}>
                  <Typography variant='h4' fontWeight='bold'>
                    Contact Information
                  </Typography>
                </Grid>
              )}
              {!isBabyAdmin && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      {...register('who')}
                      label='Who'
                      error={!!errors.who}
                      helperText={errors.who?.message?.toString() || ''}
                      defaultValue=''
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      {...register('phone_number')}
                      label='Phone Number'
                      error={!!errors.phone_number}
                      helperText={errors.phone_number?.message?.toString() || ''}
                      defaultValue=''
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  {...register('link')}
                  label='Web Link'
                  error={!!errors.link}
                  helperText={errors.link?.message?.toString() || ''}
                  defaultValue=''
                  InputProps={{
                    endAdornment: watch('link') ? (
                      <InputAdornment position='end'>
                        <Button onClick={() => window.open(watch('link'), '_blank')}>View</Button>
                      </InputAdornment>
                    ) : undefined,
                  }}
                />
              </Grid>

              {isAdmin && (
                <Grid item xs={12} mt={2}>
                  <Typography variant='h4' fontWeight='bold'>
                    Finance Information
                  </Typography>
                </Grid>
              )}
              {!isBabyAdmin && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      {...register('optic_list_price', {
                        setValueAs: value => (value === '' ? null : parseFloat(value)),
                        valueAsNumber: true,
                      })}
                      label='Optic List Price'
                      error={!!errors.optic_list_price}
                      helperText={errors.optic_list_price?.message?.toString() || ''}
                      type='number'
                      defaultValue={null}
                      inputProps={{ min: 0, step: '0.01' }}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      {...register('seller_asking_price', {
                        setValueAs: value => (value === '' ? null : parseFloat(value)),
                        valueAsNumber: true,
                      })}
                      label='Seller Asking Price'
                      error={!!errors.seller_asking_price}
                      helperText={errors.seller_asking_price?.message?.toString() || ''}
                      required
                      type='number'
                      defaultValue={null}
                      inputProps={{ min: 0, step: '0.01' }}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  {matchedFormula && grayBookWholeSalePrice && (
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant='body1' sx={{ color: 'text.primary' }}>
                          Gray Book Price:{' '}
                          <span style={{ fontWeight: 500 }}>${grayBookWholeSalePrice.toLocaleString()}</span>
                        </Typography>
                        {userInfo?.isOwner && grayBookRetailPrice && (
                          <Typography variant='body1' sx={{ color: 'black' }}>
                            (Retail: <span style={{ fontWeight: 500 }}>${grayBookRetailPrice.toLocaleString()}</span>
                            )
                          </Typography>
                        )}
                      </Box>

                      {dealQualityInfo && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                          <Typography variant='body1'>Deal Quality:</Typography>
                          <Chip label={dealQualityInfo.label} color={dealQualityInfo.color} size='small' />
                        </Box>
                      )}
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name='sold_date'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Sold Date'
                        value={value ? dayjs(value) : null}
                        onChange={newValue => {
                          onChange(newValue ? dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null)
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.sold_date,
                            helperText: errors.sold_date?.message?.toString() || '',
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  {...register('truck_notes')}
                  label='Notes'
                  multiline
                  rows={4}
                  error={!!errors.truck_notes}
                  helperText={errors.truck_notes?.message?.toString() || ''}
                  defaultValue=''
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            display='flex'
            flexDirection={isMobile ? 'column-reverse' : 'row'}
            justifyContent='space-between'
            gap={1}
            m={isMobile ? 0 : 2}
            p={isMobile ? 1 : 2}
            alignItems='stretch'
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 'fit-content',
            }}
          >
            <Button type='button' color='secondary' fullWidth onClick={handleClose}>
              Cancel
            </Button>
            <Button type='submit' variant='contained' fullWidth>
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>

      <SoldPopup
        open={soldPopupOpen}
        onClose={() => {
          setSoldPopupOpen(false)
          setPendingFormData(null)
        }}
        onSubmit={handleSoldSubmit}
        itemName={vehicle?.stock_number}
      />
    </Dialog>
  )
}

export default VehicleForm
