import React, { useEffect } from 'react'
import { useAuth } from 'contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

interface AuthRouteProps {
  children: React.ReactNode
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { currentUser, userInfo, isLoading } = useAuth()
  const navigate = useNavigate()
  const userType = userInfo?.role
  useEffect(() => {
    const handleRedirect = () => {
      navigate(`/${userType}`)
    }
    if (currentUser && userType) {
      handleRedirect()
    }
  }, [currentUser, navigate, userType, isLoading])

  return <>{children}</>
}

export default AuthRoute
