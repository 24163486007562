import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { downloadShareableInventoryCsv } from 'core/api/inventory/downloadShareableInventoryCSV'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { useAdminListBuilder } from 'hooks/useAdminListBuilder'
import { useInventory } from 'hooks/inventory/useInventory'
import { useUsers } from 'hooks/useUsers'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InventoryList } from '@otw/models/inventory'
import { Vehicle } from '@otw/models/inventory'
import { PrivateUserInfoType } from '@otw/models'

interface AddToListDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (listId: string | 'new', listName?: string, description?: string, associatedUserIds?: string[]) => void
  lists: InventoryList[]
  loading: boolean
  users: PrivateUserInfoType[]
}

const AddToListDialog: React.FC<AddToListDialogProps> = ({ open, onClose, onSubmit, lists, loading, users }) => {
  const [selectedList, setSelectedList] = useState<string>('new')
  const [newListName, setNewListName] = useState('')
  const [newListDescription, setNewListDescription] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<PrivateUserInfoType[]>([])

  useEffect(() => {
    if (open) {
      setSelectedList('new')
      setNewListName('')
      setNewListDescription('')
      setSelectedUsers([])
    }
  }, [open])

  useEffect(() => {
    if (selectedList !== 'new' && lists) {
      const currentList = lists.find(list => list.id === selectedList)
      const userIds = currentList?.associatedUserIds || []
      const associatedUsers = users.filter(user => userIds.includes(user.id))
      setSelectedUsers(associatedUsers)
    }
  }, [selectedList, lists, users])

  const handleSubmit = () => {
    if (selectedList === 'new' && !newListName) return
    onSubmit(
      selectedList,
      newListName,
      newListDescription,
      selectedUsers.map(user => user.id),
    )
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Add to List</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Select List</InputLabel>
            <Select value={selectedList} onChange={e => setSelectedList(e.target.value)} label='Select List'>
              <MenuItem value='new'>Create New List</MenuItem>
              {lists.map(list => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedList === 'new' && (
            <>
              <TextField
                label='List Name'
                value={newListName}
                onChange={e => setNewListName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label='Description'
                value={newListDescription}
                onChange={e => setNewListDescription(e.target.value)}
                fullWidth
                multiline
                rows={3}
              />
            </>
          )}

          <Autocomplete
            multiple
            options={users}
            value={selectedUsers}
            onChange={(_, newValue) => setSelectedUsers(newValue)}
            getOptionLabel={option =>
              `${option.email || ''}${option.company_name ? ` (${option.company_name})` : ''}`
            }
            renderInput={params => <TextField {...params} label='Share with Users' placeholder='Search users...' />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip label={option.email} {...getTagProps({ index })} key={option.id} />)
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Stack>
                  <Typography>{option.email || 'No email'}</Typography>
                  {option.company_name && (
                    <Typography variant='caption' color='text.secondary'>
                      {option.company_name}
                    </Typography>
                  )}
                </Stack>
              </li>
            )}
            filterOptions={(options, { inputValue }) => {
              const searchTerms = inputValue.toLowerCase().split(' ')
              return options.filter(option =>
                searchTerms.every(
                  term =>
                    (option.email?.toLowerCase() || '').includes(term) ||
                    (option.company_name?.toLowerCase() || '').includes(term) ||
                    (option.first_name?.toLowerCase() || '').includes(term) ||
                    (option.last_name?.toLowerCase() || '').includes(term),
                ),
              )
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={(selectedList === 'new' && !newListName) || loading}
          variant='contained'
        >
          {loading ? 'Adding...' : 'Add to List'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LoadingDialog = ({ open, message }: { open: boolean; message: string }) => (
  <Dialog open={open} PaperProps={{ sx: { backgroundColor: 'background.paper', padding: 2 } }}>
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <CircularProgress size={24} />
        <Typography>{message}</Typography>
      </Box>
    </DialogContent>
  </Dialog>
)

const DeleteConfirmationDialog = ({
  open,
  vehicles,
  onConfirm,
  onCancel,
}: {
  open: boolean
  vehicles: Vehicle[]
  onConfirm: () => void
  onCancel: () => void
}) => (
  <Dialog open={open} onClose={onCancel} maxWidth='sm' fullWidth>
    <DialogTitle sx={{ color: 'error.main' }}>Confirm Delete</DialogTitle>
    <DialogContent>
      <Typography gutterBottom>Are you sure you want to delete the following vehicles?</Typography>
      <List>
        {vehicles.map(vehicle => (
          <ListItem key={vehicle.id} divider>
            <ListItemText
              primary={`${vehicle.stock_number} - ${vehicle.make} ${vehicle.model}`}
              secondary={`Added: ${vehicle.date_added ? new Date(vehicle.date_added).toLocaleDateString() : 'N/A'}`}
            />
          </ListItem>
        ))}
      </List>
      <Typography color='error' sx={{ mt: 2 }}>
        This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} color='error' variant='contained'>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const ActionsPanel = React.memo(
  ({ selectedRows, clearAllFilters }: { selectedRows: Vehicle[]; clearAllFilters: () => void }) => {
    const { userInfo } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const theme = useTheme()
    const location = useLocation()
    const inventoryType = location.pathname.includes('/shield-inventory-v2')
      ? 'shield_inventory'
      : 'master_inventory'
    const { deleteDocument, updateDocument } = useInventory(inventoryType)
    const { showSnackbar } = useSnackbar()
    const [showAddToList, setShowAddToList] = useState(false)
    const {
      lists,
      loading: listsLoading,
      createList,
      addVehiclesToList,
      updateListAssociations,
    } = useAdminListBuilder()
    const { users } = useUsers()

    const handleDownloadCsv = useCallback(async () => {
      try {
        setLoadingMessage('Downloading CSV...')
        setLoading(true)
        const selectedIds = selectedRows.map(row => row.id)
        if (!userInfo?.role) {
          showSnackbar('Unknown error occurred..please contact admin@optictruckworks.com', 'error')
          return
        }
        await downloadShareableInventoryCsv(inventoryType, selectedIds, userInfo?.role)
      } finally {
        setLoading(false)
      }
    }, [selectedRows, userInfo?.role, inventoryType])

    const handleDownloadPdf = useCallback(async () => {
      try {
        setLoadingMessage('Downloading PDF...')
        setLoading(true)
        const selectedIds = selectedRows.map(row => row.id)
        await downloadShareableInventoryPdf(inventoryType, selectedIds)
      } finally {
        setLoading(false)
      }
    }, [selectedRows, inventoryType])

    const handleDelete = useCallback(async () => {
      try {
        setLoadingMessage('Deleting...')
        setLoading(true)
        await Promise.all(selectedRows.map(row => deleteDocument(row.id)))
      } finally {
        setLoading(false)
        setShowDeleteConfirm(false)
      }
    }, [selectedRows, deleteDocument])

    const buttonStyles = {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
      },
      '&:disabled': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(69, 85, 115, 0.3)' : 'rgba(69, 85, 115, 0.12)',
        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(69, 85, 115, 0.26)',
      },
    }

    const deleteButtonStyles = {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.error.dark,
      },
      '&:disabled': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(244, 67, 54, 0.3)' : 'rgba(244, 67, 54, 0.12)',
        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(244, 67, 54, 0.26)',
      },
    }

    const handleMarkSold = useCallback(async () => {
      try {
        setLoadingMessage('Marking sold...')
        setLoading(true)
        await Promise.all(selectedRows.map(row => updateDocument({ id: row.id, data: { status: 'sold' } })))
      } finally {
        setLoading(false)
      }
    }, [selectedRows, updateDocument])

    const handleAddToList = useCallback(
      async (listId: string | 'new', listName?: string, description?: string, associatedUserIds?: string[]) => {
        try {
          setLoadingMessage('Adding to list...')
          setLoading(true)

          const selectedIds = selectedRows.map(row => row.id)

          if (listId === 'new' && listName) {
            await createList({
              name: listName,
              description: description || '',
              initialInventoryIds: selectedIds,
              associatedUserIds: associatedUserIds || [],
            })
          } else if (listId !== 'new') {
            // Add to existing list
            await addVehiclesToList({
              listId,
              vehicleIds: selectedIds,
            })
            // Update user associations if provided
            if (associatedUserIds) {
              await updateListAssociations({
                listId,
                userIds: associatedUserIds,
              })
            }
          }

          showSnackbar('Vehicles added to list successfully', 'success')
          setShowAddToList(false)
        } catch (error) {
          console.error('Error adding to list:', error)
          showSnackbar('Failed to add vehicles to list', 'error')
        } finally {
          setLoading(false)
        }
      },
      [selectedRows, createList, addVehiclesToList, updateListAssociations, showSnackbar],
    )

    return (
      <>
        <LoadingDialog open={loading} message={loadingMessage} />
        <DeleteConfirmationDialog
          open={showDeleteConfirm}
          vehicles={selectedRows}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirm(false)}
        />
        <AddToListDialog
          open={showAddToList}
          onClose={() => setShowAddToList(false)}
          onSubmit={handleAddToList}
          lists={lists || []}
          loading={loading || listsLoading}
          users={users}
        />
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant='h6' color='text.primary'>
            Bulk Actions
          </Typography>
          <Divider />
          <Button
            startIcon={<DeleteIcon />}
            variant='contained'
            onClick={() => setShowDeleteConfirm(true)}
            disabled={!selectedRows.length || loading}
            sx={deleteButtonStyles}
          >
            Delete Selected
          </Button>
          <Button
            startIcon={<FileDownloadIcon />}
            variant='contained'
            onClick={handleDownloadCsv}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Download CSV
          </Button>
          <Button
            startIcon={<PictureAsPdfIcon />}
            variant='contained'
            onClick={handleDownloadPdf}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Download PDF
          </Button>
          <Button
            startIcon={<ArchiveIcon />}
            variant='contained'
            onClick={handleMarkSold}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Mark Sold
          </Button>
          <Button startIcon={<FilterAltOffIcon />} variant='text' onClick={clearAllFilters} sx={buttonStyles}>
            Clear All Filters
          </Button>
          <Button
            startIcon={<PlaylistAddIcon />}
            variant='contained'
            onClick={() => setShowAddToList(true)}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Add to List
          </Button>
        </Stack>
      </>
    )
  },
)

ActionsPanel.displayName = 'ActionsPanel'

export { ActionsPanel }
