import React, { useState, useEffect } from 'react';
import { Box, Link, Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FooterDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}

const FooterDialog: React.FC<FooterDialogProps> = ({ open, onClose, title, content }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {content}
    </DialogContent>
  </Dialog>
);

const AuthFooter: React.FC = () => {
  const [dialogConfig, setDialogConfig] = useState<{
    open: boolean;
    title: string;
    content: React.ReactNode;
  }>({
    open: false,
    title: '',
    content: null,
  });

  const handleClose = () => {
    setDialogConfig({ ...dialogConfig, open: false });
    // Clear hash when dialog is closed
    window.location.hash = '';
  };

  // Function to open dialog based on hash
  const openDialogFromHash = () => {
    const hash = window.location.hash.slice(1); // Remove the # symbol
    switch (hash) {
      case 'privacy-policy':
        setDialogConfig({
          open: true,
          title: 'Privacy Policy',
          content: privacyPolicyContent,
        });
        break;
      case 'cookie-policy':
        setDialogConfig({
          open: true,
          title: 'Cookie Policy',
          content: cookiePolicyContent,
        });
        break;
      case 'terms-of-use':
        setDialogConfig({
          open: true,
          title: 'Terms of Use',
          content: termsOfUseContent,
        });
        break;
      default:
        setDialogConfig({ open: false, title: '', content: null });
    }
  };

  // Handle initial load and hash changes
  useEffect(() => {
    openDialogFromHash();
    // Listen for hash changes
    window.addEventListener('hashchange', openDialogFromHash);
    return () => {
      window.removeEventListener('hashchange', openDialogFromHash);
    };
  }, []);

  const privacyPolicyContent = (
    <>
      <Typography variant="body1" paragraph>
        Last updated December 22, 2023
      </Typography>
      <Typography variant="body1" paragraph>
        This privacy notice for Optic Truck Works describes how and why we might collect,
        store, use, and share your information when you use our portal. This applies when you visit our portal,
        engage with our services, or use any related features.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Key Points:
      </Typography>
      <ul>
        <li>We process personal information based on your interactions with us, the choices you make, and the features you use within our portal.</li>
        <li>We do not process sensitive personal information beyond what is necessary for our services.</li>
        <li>We do not receive information from third parties.</li>
        <li>Our information processing aims to provide, improve, and administer our Services, ensure security, prevent fraud, and comply with the law.</li>
        <li>We share personal information only with authorized partners and service providers.</li>
        <li>We have measures in place to protect your personal information, but no system can guarantee complete security.</li>
      </ul>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        SMS Communications & Opt-Out Rights
      </Typography>
      <Typography variant="body1" paragraph>
        We partner with Twilio to provide SMS messaging services. By using our portal, you may have the option to opt into receiving SMS messages from us. Message and data rates may apply. Message frequency varies.
      </Typography>
      <Typography variant="body1" paragraph>
        In accordance with the Telephone Consumer Protection Act (TCPA), the CAN-SPAM Act, and other applicable laws:
      </Typography>
      <ul>
        <li>You can opt out of SMS messages at any time by replying "STOP" to any message we send.</li>
        <li>After you opt out, we will send you a final message confirming your opt-out status.</li>
        <li>Reply "HELP" for help or contact our support team.</li>
        <li>Consent to receive SMS is not a condition of using our services.</li>
        <li>Your mobile carrier's standard message and data rates may apply.</li>
        <li>We comply with the CTIA Short Code Monitoring Program guidelines.</li>
      </ul>
      <Typography variant="body1" paragraph>
        By opting in to SMS messages, you certify that you are the owner or authorized user of the mobile device and number provided, and are authorized to agree to receive messages at this number.
      </Typography>
      <Typography variant="body1">
        If you have any questions or concerns about our privacy practices or SMS messaging, please contact our support team.
      </Typography>
    </>
  );

  const cookiePolicyContent = (
    <>
      <Typography variant="body1" paragraph>
        Last updated December 22, 2023
      </Typography>
      <Typography variant="body1" paragraph>
        The Optic Truck Works portal uses cookies and similar technologies to improve your experience, understand user behavior, and enhance the security of our services. These technologies help us:
      </Typography>
      <ul>
        <li>Keep you signed in to your account</li>
        <li>Remember your preferences</li>
        <li>Protect against unauthorized access</li>
        <li>Analyze how our portal is used</li>
        <li>Improve our services based on usage data</li>
      </ul>
      <Typography variant="body1">
        You can control cookie settings through your browser preferences.
      </Typography>
    </>
  );

  const termsOfUseContent = (
    <>
      <Typography variant="body1" paragraph>
        Last updated December 22, 2023
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing and using the Optic Truck Works portal, you agree to these terms of use. Our portal is designed to provide efficient fleet management and maintenance services. Users must:
      </Typography>
      <ul>
        <li>Maintain the confidentiality of their account credentials</li>
        <li>Use the portal only for its intended purposes</li>
        <li>Comply with all applicable laws and regulations</li>
        <li>Report any security concerns or unauthorized access</li>
        <li>Respect the intellectual property rights of Optic Truck Works</li>
      </ul>
      <Typography variant="body1">
        We reserve the right to modify these terms at any time. Continued use of the portal constitutes acceptance of any changes.
      </Typography>
    </>
  );

  const handleLinkClick = (title: string, content: React.ReactNode, hash: string, e: React.MouseEvent) => {
    e.preventDefault();
    window.location.hash = hash;
    setDialogConfig({
      open: true,
      title,
      content,
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        py: 2,
        mt: 'auto',
        background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4))',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            color: 'white',
            '& a': {
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          <Link 
            href="#privacy-policy" 
            onClick={(e) => handleLinkClick('Privacy Policy', privacyPolicyContent, 'privacy-policy', e)}
          >
            Privacy Policy
          </Link>
          <Typography>|</Typography>
          <Link 
            href="#cookie-policy" 
            onClick={(e) => handleLinkClick('Cookie Policy', cookiePolicyContent, 'cookie-policy', e)}
          >
            Cookie Policy
          </Link>
          <Typography>|</Typography>
          <Link 
            href="#terms-of-use" 
            onClick={(e) => handleLinkClick('Terms of Use', termsOfUseContent, 'terms-of-use', e)}
          >
            Terms of Use
          </Link>
        </Box>
        <Typography variant="body2" align="center" sx={{ color: 'white' }}>
          © Optic Truck Works {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </Box>

      <FooterDialog
        open={dialogConfig.open}
        onClose={handleClose}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
    </Box>
  );
};

export default AuthFooter; 