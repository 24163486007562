import { getHeaders } from 'core/getHeaders'
import { CombinedUserData, CreateUserType, ApplicationStatus } from '@otw/models'
import { functionsBaseUrl } from 'config/functionsBaseUrl'
import { sharedFunctions } from './sharedFunctions'
import { getApplicationDocuments } from 'core/api/application/bank'

export interface GetAllUsersResponse {
  users: CombinedUserData[]
  total: number
}

export const portalFunctions = {

  admin: {
    getAuthUsers: async () => {
      const url = `${functionsBaseUrl}/portal/admin/getAuthUsers`
      const method = 'GET'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
      })
      if (!response.ok) {
        throw new Error('Failed to get auth users')
      }
      const data: GetAllUsersResponse = await response.json()
      return data
    },
    createFirebaseUser: async (userToCreate: CreateUserType) => {
      const url = `${functionsBaseUrl}/portal/admin/createFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(userToCreate),
      })
      return response
    },
    deleteFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/deleteFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    disableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/disableFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    enableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/enableFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    resendVerificationEmail: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/resendVerificationEmail`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    generatePhotoUploadLink: async (email: string, vehicleData: any) => {
      const url = `${functionsBaseUrl}/portal/admin/generatePhotoUploadLink`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ email, vehicleData }),
      })
      return response
    },
  },
  public: {
    generatePasswordReset: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generatePasswordReset`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log(response)

      if (response.status === 429) {
        throw new Error('Too many requests')
      }

      if (!response.ok) {
        throw new Error('Failed to send password reset email')
      }
    },
    resendVerificationEmail: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/resendVerificationEmail`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    generateSignInLink: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generateSignInLink`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: { 'Content-Type': 'application/json' },
      })
    },
  },
  shared: {
    setInitialPassword: async (password: string) => {
      const url = `${functionsBaseUrl}/portal/shared/setInitialPassword`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ password }),
      })
      return response
    },
  },
}
