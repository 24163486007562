import React from 'react'
import { Box, CircularProgress, Stack } from '@mui/material'
import { logoNoBackground } from 'assets'
import * as motion from 'motion/react-client'

interface FreeTechLogoLoadingProps {
  // fullscreen?: boolean
}

export const FreeTechLogoLoading: React.FC<FreeTechLogoLoadingProps> = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: { xs: 0, md: "240px" },
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "background.default",
      zIndex: (theme) => theme.zIndex.drawer - 1,
    }}
  >
    <Stack spacing={3} alignItems='center'>
      <motion.img
        src={logoNoBackground}
        alt='Optic Truck Works Logo'
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.2,
          scale: { type: 'spring', visualDuration: 0.2, bounce: 0.3 },
        }}
        style={{
          width: '100px',
          height: 'auto',
        }}
      />
      <CircularProgress size={30} />
    </Stack>
  </Box>
)
