import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'config/firebase'
import { useAuth } from 'hooks/auth/useAuth'

interface ClientOnboardingData {
  company_name: string
  entity_structure: string
  address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
    formattedAddress?: string
  }
  w9_document_uuid?: string
}

const STORAGE_KEY = 'clientOnboardingState'

export const useClientOnboarding = () => {
  const { userInfo } = useAuth()
  const navigate = useNavigate()

  const [formData, setFormData] = useState<ClientOnboardingData>(() => {
    const savedState = localStorage.getItem(STORAGE_KEY)
    if (savedState) {
      return JSON.parse(savedState)
    }

    return {
      company_name: '',
      entity_structure: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'United States',
      },
    }
  })

  const handleComplete = async () => {
    if (!userInfo?.id) return

    const formattedAddress = [
      formData.address.street,
      formData.address.city,
      formData.address.state,
      formData.address.zipCode,
      formData.address.country,
    ]
      .filter(Boolean)
      .join(', ')

    try {
      const userRef = doc(db, 'users', userInfo.id)
      await updateDoc(userRef, {
        company_name: formData.company_name,
        entity_structure: formData.entity_structure,
        address: {
          ...formData.address,
          formattedAddress,
        },
        needsOnboarding: false,
        onboardingCompletedAt: new Date().toISOString(),
      })

      // Clear local storage after successful completion
      localStorage.removeItem(STORAGE_KEY)

      // Navigate to dashboard
      navigate('/client')
    } catch (error) {
      console.error('Error updating user data:', error)
    }
  }

  const isStepComplete = (step: number) => {
    switch (step) {
      case 0:
        return formData.company_name.trim() !== '' && formData.entity_structure.trim() !== ''
      case 1:
        return (
          formData.address.street.trim() !== '' &&
          formData.address.city.trim() !== '' &&
          formData.address.state.trim() !== '' &&
          formData.address.zipCode.trim() !== ''
        )
      case 2:
        return true // Document upload step
      default:
        return false
    }
  }

  const handleFormChange = (field: keyof ClientOnboardingData, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
    // Save to localStorage
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...formData, [field]: value }))
  }

  const handleAddressChange = (field: keyof ClientOnboardingData['address']) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: event.target.value,
      },
    }))
    // Save to localStorage
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...formData,
        address: {
          ...formData.address,
          [field]: event.target.value,
        },
      })
    )
  }

  return {
    formData,
    handleComplete,
    isStepComplete,
    handleFormChange,
    handleAddressChange,
  }
} 