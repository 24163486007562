import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  Avatar,
  InputAdornment,
  CircularProgress,
  Box,
  Typography,
  Divider,
  IconButton,
} from '@mui/material'
import { Search, PersonRemove } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { listUsersForAssignment } from 'core/api/users/listUsersForAssignment'
import { collection, getDocs } from 'firebase/firestore'
import { db } from 'config/firebase'

interface AssignUserDialogProps {
  open: boolean
  onClose: () => void
  onAssign: (userId: string) => void
  onRemove: (userId: string) => void
  currentUserId?: string
  vehicleId: string
  collectionName: string
}

interface UserForAssignment {
  id: string
  name: string
  email: string
  company_name?: string
  dealership_name?: string
}

interface AssignedUser {
  userId: string
  assignedAt: string
  userInfo: {
    name: string
    email: string
    company?: string
    dealership?: string
  }
}

const AssignUserDialog: React.FC<AssignUserDialogProps> = ({
  open,
  onClose,
  onAssign,
  onRemove,
  currentUserId,
  vehicleId,
  collectionName,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const { data: users, isLoading: isLoadingUsers } = useQuery<UserForAssignment[], Error>(
    'usersForAssignment', 
    listUsersForAssignment
  )
  
  // Query for assigned users
  const { data: assignedUsers, isLoading: isLoadingAssigned } = useQuery<AssignedUser[]>(
    ['assignedUsers', vehicleId],
    async () => {
      const assignedUsersRef = collection(db, collectionName, vehicleId, 'assigned_users')
      const snapshot = await getDocs(assignedUsersRef)
      return snapshot.docs.map(doc => ({
        ...doc.data()
      })) as AssignedUser[]
    },
    {
      enabled: open
    }
  )

  const filteredUsers = users?.filter((user: UserForAssignment) => {
    const searchLower = searchTerm.toLowerCase()
    const isAlreadyAssigned = assignedUsers?.some(assignedUser => assignedUser.userId === user.id)
    return !isAlreadyAssigned && (
      (user.name?.toLowerCase() || '').includes(searchLower) ||
      (user.email?.toLowerCase() || '').includes(searchLower) ||
      (user.company_name?.toLowerCase() || '').includes(searchLower) ||
      (user.dealership_name?.toLowerCase() || '').includes(searchLower)
    )
  })

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manage Vehicle Users</DialogTitle>
      <DialogContent>
        {/* Currently Assigned Users Section */}
        <Box mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            Currently Assigned Users
          </Typography>
          {isLoadingAssigned ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress size={20} />
            </Box>
          ) : !assignedUsers?.length ? (
            <Typography color="text.secondary" variant="body2">
              No users currently assigned
            </Typography>
          ) : (
            <List>
              {assignedUsers.map((user) => (
                <ListItem
                  key={user.userId}
                  sx={{ 
                    bgcolor: 'background.paper',
                    mb: 1,
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Avatar sx={{ mr: 2 }}>{user.userInfo.name?.[0] || '?'}</Avatar>
                  <ListItemText 
                    primary={user.userInfo.name || 'Unnamed User'}
                    secondary={
                      <>
                        {user.userInfo.email}
                        {user.userInfo.company && <><br />{user.userInfo.company}</>}
                        {user.userInfo.dealership && <><br />{user.userInfo.dealership}</>}
                        <br />
                        Assigned: {new Date(user.assignedAt).toLocaleDateString()}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      aria-label="remove" 
                      onClick={() => onRemove(user.userId)}
                      color="error"
                    >
                      <PersonRemove />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Add New Users Section */}
        <Typography variant="subtitle1" gutterBottom>
          Add New User
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {isLoadingUsers ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : !filteredUsers?.length ? (
          <Box p={2}>
            <Typography>No available users found</Typography>
          </Box>
        ) : (
          <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
            {filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                disablePadding
              >
                <ListItemButton onClick={() => onAssign(user.id)}>
                  <Avatar sx={{ mr: 2 }}>{user.name?.[0] || '?'}</Avatar>
                  <ListItemText 
                    primary={user.name || 'Unnamed User'}
                    secondary={
                      <>
                        {user.email}
                        {user.company_name && <><br />{user.company_name}</>}
                        {user.dealership_name && <><br />{user.dealership_name}</>}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignUserDialog