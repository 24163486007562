import React, { useState, useRef, useEffect } from 'react'
import { Box, Typography, Paper, Container, Select, MenuItem, Divider, useTheme } from '@mui/material'
import { useBankAgent } from 'hooks/bank/useBankAgent'
import { FreeTechLogoLoading } from 'components/FreeTechLogoLoading'
import { ApplicationStatus } from '@otw/models'
import BankApplicationBoard from './components/BankApplicationBoard'
import FilterListIcon from '@mui/icons-material/FilterList'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { animate, spring } from 'motion'
import { ApplicationStatusTimeline } from 'components/ApplicationStatusTimeline'
import { getStatusColor, getStatusLabel, APPLICATION_STATUS_COLORS } from '../../../core/constants/applicationStatus'

const BankHome = (): React.ReactNode => {
  const { bankData, bankLoading, applications, applicationsLoading, applicationsError, bankError } = useBankAgent()
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus | 'all'>('all')
  const theme = useTheme()

  // Show loading state if either data is loading OR if we don't have the data yet
  if (bankLoading || applicationsLoading) {
    return <FreeTechLogoLoading />
  }

  if (applicationsError || bankError || !applications || !bankData) {
    return (
      <Box sx={{ textAlign: 'center', py: 8 }}>
        <Typography variant='h6' color='error'>
          Error loading data
        </Typography>
        <Typography variant='body2' color='text.secondary' sx={{ mt: 1 }}>
          {applicationsError?.message || bankError?.message || 'An unexpected error occurred'}
        </Typography>
      </Box>
    )
  }

  const filteredApplications = applications.filter(app => {
    if (statusFilter === 'all') return true
    return app.status === statusFilter
  })

  return (
    <Container maxWidth='xl'>
      <Box sx={{ overflow: 'hidden' }}>
        <Box mb={4}>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant='h3'
              sx={{
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Welcome, {bankData.name} Agent
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant='body1' color='text.secondary'>
              Review and process applications assigned to your bank. View documents, add notes, and manage
              applications efficiently.
            </Typography>
          </Box>
        </Box>

        <ApplicationStatusTimeline role='bank' />

        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
              mt: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AssignmentIcon
                sx={{
                  fontSize: 32,
                  color: theme.palette.primary.main,
                }}
              />
              <Box>
                <Typography variant='h5' sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
                  Applications
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Manage and process financing applications
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FilterListIcon color='action' />
              <Select
                value={statusFilter}
                onChange={e => setStatusFilter(e.target.value as ApplicationStatus | 'all')}
                size='small'
                sx={{
                  minWidth: 200,
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  '& .MuiSelect-select': {
                    py: 1,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                    borderWidth: 1,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.light,
                    borderWidth: 1,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                }}
              >
                <MenuItem value="all">
                  <Typography>All Applications</Typography>
                </MenuItem>
                {Object.keys(APPLICATION_STATUS_COLORS).map((status) => (
                  <MenuItem 
                    key={status} 
                    value={status}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: getStatusColor(status as ApplicationStatus).main,
                      }}
                    />
                    <Typography>{getStatusLabel(status as ApplicationStatus)}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <BankApplicationBoard applications={filteredApplications} />
        </Box>
      </Box>
    </Container>
  )
}

export default BankHome
