import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  Paper,
  Stack,
  Tabs,
  Tab,
  TextField,
  Chip,
  Collapse,
  IconButton,
  Divider,
  Button,
} from '@mui/material'
import {
  getFirestore,
  collection,
  getDocs,
  Timestamp,
  query,
  limit,
  startAfter,
  orderBy as fsOrderBy,
} from 'firebase/firestore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import EmailIcon from '@mui/icons-material/Email'
import SmsIcon from '@mui/icons-material/Sms'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import CircularProgress from '@mui/material/CircularProgress'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from '@tanstack/react-query'

const db = getFirestore()

interface SmsMessage {
  body?: string
  to?: string
  delivery?: {
    info?: {
      status?: string
      dateCreated?: Timestamp
    }
  }
}

interface EmailMessage {
  message?: {
    subject?: string
    html?: string
    to?: string | string[]
  }
  to?: string | string[]
  delivery?: {
    response?: string
    state?: string
    endTime?: Timestamp
  }
}

// Add these interfaces for query results
interface QueryResult {
  messages: (SmsMessage | EmailMessage)[]
  lastDoc: any
}

// Add this custom hook at the top of the file
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

const MessageLog: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 500) // 500ms delay
  const [expandedMessages, setExpandedMessages] = useState<Set<number>>(new Set())
  const PAGE_SIZE = 10

  const {
    data: smsData,
    fetchNextPage: fetchNextSmsPage,
    hasNextPage: hasNextSmsPage,
    isLoading: isSmsLoading,
  } = useInfiniteQuery<QueryResult>({
    queryKey: ['messages', 'sms'],
    initialPageParam: null,
    queryFn: async ({ pageParam }) => {
      const smsCollection = collection(db, 'messages')
      let q = query(smsCollection, fsOrderBy('delivery.info.dateCreated', 'desc'), limit(PAGE_SIZE))

      if (pageParam) {
        q = query(q, startAfter(pageParam))
      }

      const snapshot = await getDocs(q)
      const lastDoc = snapshot.docs[snapshot.docs.length - 1]
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as SmsMessage),
      }))

      return { messages, lastDoc }
    },
    getNextPageParam: (lastPage: QueryResult) => lastPage.lastDoc || undefined,
    staleTime: 5 * 60 * 1000, // 5 minutes
  })

  const {
    data: emailData,
    fetchNextPage: fetchNextEmailPage,
    hasNextPage: hasNextEmailPage,
    isLoading: isEmailLoading,
  } = useInfiniteQuery<QueryResult>({
    queryKey: ['messages', 'email'],
    initialPageParam: null,
    queryFn: async ({ pageParam }) => {
      const emailCollection = collection(db, 'mail')
      let q = query(emailCollection, fsOrderBy('delivery.endTime', 'desc'), limit(PAGE_SIZE))

      if (pageParam) {
        q = query(q, startAfter(pageParam))
      }

      const snapshot = await getDocs(q)
      const lastDoc = snapshot.docs[snapshot.docs.length - 1]
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as EmailMessage),
      }))

      return { messages, lastDoc }
    },
    getNextPageParam: (lastPage: QueryResult) => lastPage.lastDoc || undefined,
    staleTime: 5 * 60 * 1000, // 5 minutes
  })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
    setSearchQuery('')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const toggleMessageExpand = (index: number) => {
    const newExpanded = new Set(expandedMessages)
    if (newExpanded.has(index)) {
      newExpanded.delete(index)
    } else {
      newExpanded.add(index)
    }
    setExpandedMessages(newExpanded)
  }

  const formatDate = (timestamp?: Timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A'
  }

  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'delivered':
      case 'completed':
        return 'success'
      case 'failed':
      case 'error':
        return 'error'
      case 'pending':
        return 'warning'
      default:
        return 'default'
    }
  }

  const handleToggleAllMessages = () => {
    const messages = getAllMessages(selectedTab === 0 ? 'sms' : 'email')
    const indices = Array.from({ length: messages.length }, (_, i) => i)

    if (expandedMessages.size > 0) {
      setExpandedMessages(new Set())
    } else {
      setExpandedMessages(new Set(indices))
    }
  }

  const handleSearch = async () => {
    if (!debouncedSearchQuery) return

    const collectionName = selectedTab === 0 ? 'messages' : 'mail'
    const dbCollection = collection(db, collectionName)
    const q = query(dbCollection)
    const snapshot = await getDocs(q)
    const allMessages = snapshot.docs.map(doc => ({
      id: doc.id,
      ...(doc.data() as SmsMessage | EmailMessage),
    }))

    const filteredMessages = allMessages.filter(message => {
      if (selectedTab === 0) {
        const smsMessage = message as SmsMessage
        return (
          smsMessage.body?.toLowerCase().includes(debouncedSearchQuery) ||
          smsMessage.to?.toLowerCase().includes(debouncedSearchQuery) ||
          smsMessage.delivery?.info?.status?.toLowerCase().includes(debouncedSearchQuery)
        )
      } else {
        const emailMessage = message as EmailMessage
        return (
          emailMessage.message?.subject?.toLowerCase().includes(debouncedSearchQuery) ||
          emailMessage.message?.html?.toLowerCase().includes(debouncedSearchQuery) ||
          (Array.isArray(emailMessage.message?.to)
            ? emailMessage.message?.to.some((to: string) => to.toLowerCase().includes(debouncedSearchQuery))
            : emailMessage.message?.to?.toLowerCase().includes(debouncedSearchQuery)) ||
          emailMessage.delivery?.state?.toLowerCase().includes(debouncedSearchQuery)
        )
      }
    })
  }

  const getAllMessages = (type: 'sms' | 'email') => {
    const data = type === 'sms' ? smsData : emailData
    return data?.pages.flatMap(page => page.messages) || []
  }

  const filteredMessages = useMemo(() => {
    const messages = getAllMessages(selectedTab === 0 ? 'sms' : 'email')
    if (!debouncedSearchQuery) return messages

    const query = debouncedSearchQuery.toLowerCase()
    return messages.filter(message => {
      if (selectedTab === 0) {
        const smsMessage = message as SmsMessage
        return (
          smsMessage.body?.toLowerCase().includes(query) ||
          smsMessage.to?.toLowerCase().includes(query) ||
          smsMessage.delivery?.info?.status?.toLowerCase().includes(query)
        )
      } else {
        const emailMessage = message as EmailMessage
        // Check email recipients (both in root and message object)
        const recipientsMatch = (() => {
          const toField = emailMessage.to || emailMessage.message?.to
          if (Array.isArray(toField)) {
            return toField.some(recipient => recipient.toLowerCase().includes(query))
          }
          return toField?.toLowerCase().includes(query)
        })()

        return (
          recipientsMatch ||
          emailMessage.message?.subject?.toLowerCase().includes(query) ||
          emailMessage.message?.html?.toLowerCase().includes(query) ||
          emailMessage.delivery?.state?.toLowerCase().includes(query)
        )
      }
    })
  }, [debouncedSearchQuery, selectedTab, getAllMessages])

  return (
    <Box sx={{ padding: 2 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h4' gutterBottom>
            Message History
          </Typography>
          <Button
            variant='outlined'
            startIcon={expandedMessages.size > 0 ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            onClick={handleToggleAllMessages}
          >
            {expandedMessages.size > 0 ? 'Collapse All' : 'Expand All'}
          </Button>
        </Stack>

        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab icon={<SmsIcon />} label='SMS' />
          <Tab icon={<EmailIcon />} label='Email' />
        </Tabs>

        <TextField
          label='Search'
          variant='outlined'
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ mb: 3 }}
          fullWidth
        />

        <Stack spacing={2}>
          <InfiniteScroll
            dataLength={filteredMessages.length}
            next={selectedTab === 0 ? fetchNextSmsPage : fetchNextEmailPage}
            hasMore={selectedTab === 0 ? !!hasNextSmsPage : !!hasNextEmailPage}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress />
              </Box>
            }
            scrollableTarget='messageContainer'
          >
            {selectedTab === 0
              ? filteredMessages.map((message, index) => {
                  const smsMessage = message as SmsMessage
                  return (
                    <Paper
                      key={index}
                      variant='outlined'
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                      onClick={() => toggleMessageExpand(index)}
                    >
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Box flex={1}>
                          <Stack direction='row' spacing={2} alignItems='center'>
                            <Typography variant='subtitle1'>To: {smsMessage.to}</Typography>
                            <Chip
                              label={smsMessage.delivery?.info?.status || 'Pending'}
                              color={getStatusColor(smsMessage.delivery?.info?.status || '')}
                              size='small'
                            />
                            <Typography variant='body2' color='text.secondary'>
                              {formatDate(smsMessage.delivery?.info?.dateCreated)}
                            </Typography>
                          </Stack>
                        </Box>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation()
                            toggleMessageExpand(index)
                          }}
                        >
                          {expandedMessages.has(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Stack>

                      <Collapse in={expandedMessages.has(index)}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant='body1'>{smsMessage.body}</Typography>
                      </Collapse>
                    </Paper>
                  )
                })
              : filteredMessages.map((message, index) => {
                  const emailMessage = message as EmailMessage
                  const recipients = emailMessage.to || emailMessage.message?.to
                  const formattedRecipients = Array.isArray(recipients) ? recipients.join(', ') : recipients || 'N/A'

                  return (
                    <Paper
                      key={index}
                      variant='outlined'
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                      onClick={() => toggleMessageExpand(index)}
                    >
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Box flex={1}>
                          <Stack direction='row' spacing={2} alignItems='center'>
                            <Typography variant='subtitle1'>To: {formattedRecipients}</Typography>
                            <Chip
                              label={emailMessage.delivery?.state || 'Pending'}
                              color={getStatusColor(emailMessage.delivery?.state || '')}
                              size='small'
                            />
                            <Typography variant='body2' color='text.secondary'>
                              {formatDate(emailMessage.delivery?.endTime)}
                            </Typography>
                          </Stack>
                          <Typography variant='subtitle2' color='text.secondary'>
                            Subject: {emailMessage.message?.subject || 'N/A'}
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation()
                            toggleMessageExpand(index)
                          }}
                        >
                          {expandedMessages.has(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Stack>

                      <Collapse in={expandedMessages.has(index)}>
                        <Divider sx={{ my: 2 }} />
                        <Box
                          dangerouslySetInnerHTML={{ __html: emailMessage.message?.html || '' }}
                          sx={{
                            '& a': { color: 'primary.main' },
                            '& img': { maxWidth: '100%', height: 'auto' },
                          }}
                        />
                        {emailMessage.delivery?.response && (
                          <>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant='caption' color='text.secondary'>
                              Response: {emailMessage.delivery.response}
                            </Typography>
                          </>
                        )}
                      </Collapse>
                    </Paper>
                  )
                })}
          </InfiniteScroll>

          {((selectedTab === 0 && !isSmsLoading && getAllMessages('sms').length === 0) ||
            (selectedTab === 1 && !isEmailLoading && getAllMessages('email').length === 0)) && (
            <Typography align='center' color='text.secondary'>
              No messages found
            </Typography>
          )}
        </Stack>
      </Paper>
    </Box>
  )
}

export default MessageLog
