import { db } from 'config/firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'

interface InventoryItem {
  vin: string
  stock_number: string
  make: string
  model: string
  size: string
  fuel: string
  miles: number
  year: number
  condition: string
  location: string
  who: string
  type: string
  status: string
  seller_asking_price: string
  url?: string
}

interface ShieldInventoryItem extends InventoryItem {
  email: string
  phone_number: string
}

interface MasterInventoryItem extends InventoryItem {
  show_on_website: boolean
  optic_list_price: string
  profit: string
  sold_date: string
}

const convertToCSV = (items: (MasterInventoryItem | ShieldInventoryItem)[], uType: string): string => {
  const isMasterInventory = 'optic_list_price' in items[0]
  const headers = isMasterInventory
    ? [
        'VIN',
        'Stock Number',
        'Make',
        'Model',
        'Size',
        'Fuel',
        'Mileage',
        'Year',
        'Condition',
        'Location',
        'Who',
        'Type',
        'Status',
        'Sale Price',
        'URL',
        'Show On Website',
        'Sold Date',
      ]
    : [
        'VIN',
        'Stock Number',
        'Make',
        'Model',
        'Size',
        'Fuel',
        'Mileage',
        'Year',
        'Condition',
        'Location',
        'Seller Name',
        'Email',
        'Phone',
        'Type',
        'Status',
        'Price',
        'URL',
      ]

  // Add "Buy Price" and "Profit" headers only for non-babyAdmins
  if (isMasterInventory && uType !== 'babyAdmin') {
    headers.splice(13, 0, 'Buy Price')
    headers.splice(15, 0, 'Profit')
  }

  const escapeCommas = (value: string | number | undefined | boolean) => {
    if (value === undefined || value === null) return ''
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value}"`
    }
    return value.toString()
  }

  const rows = items.map(item => {
    let row
    if (isMasterInventory) {
      const masterItem = item as MasterInventoryItem
      row = [
        escapeCommas(masterItem.vin),
        escapeCommas(masterItem.stock_number),
        escapeCommas(masterItem.make),
        escapeCommas(masterItem.model),
        escapeCommas(masterItem.size),
        escapeCommas(masterItem.fuel),
        escapeCommas(masterItem.miles),
        escapeCommas(masterItem.year),
        escapeCommas(masterItem.condition),
        escapeCommas(masterItem.location),
        escapeCommas(masterItem.who),
        escapeCommas(masterItem.type),
        escapeCommas(masterItem.status),
        escapeCommas(masterItem.optic_list_price),
        escapeCommas(masterItem.url),
        escapeCommas(masterItem.show_on_website),
        escapeCommas(masterItem.sold_date),
      ]

      // Add "Buy Price" and "Profit" values only for non-babyAdmins
      if (uType !== 'babyAdmin') {
        row.splice(13, 0, escapeCommas(masterItem.seller_asking_price))
        row.splice(15, 0, escapeCommas(masterItem.profit))
      }
    } else {
      const shieldItem = item as ShieldInventoryItem
      row = [
        escapeCommas(shieldItem.vin),
        escapeCommas(shieldItem.stock_number),
        escapeCommas(shieldItem.make),
        escapeCommas(shieldItem.model),
        escapeCommas(shieldItem.size),
        escapeCommas(shieldItem.fuel),
        escapeCommas(shieldItem.miles),
        escapeCommas(shieldItem.year),
        escapeCommas(shieldItem.condition),
        escapeCommas(shieldItem.location),
        escapeCommas(shieldItem.who),
        escapeCommas(shieldItem.email),
        escapeCommas(shieldItem.phone_number),
        escapeCommas(shieldItem.type),
        escapeCommas(shieldItem.status),
        escapeCommas(shieldItem.seller_asking_price),
        escapeCommas(shieldItem.url),
      ]
    }

    return row
  })

  const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n')
  return csvContent
}

const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadShareableInventoryCsv = async (
  collectionName: 'master_inventory' | 'shield_inventory',
  ids: string[],
  uType: string,
) => {
  const inventoryCollection = collection(db, collectionName)
  const batchSize = 30
  const inventoryItems: (MasterInventoryItem | ShieldInventoryItem)[] = []

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize)
    const inventoryQuery = query(inventoryCollection, where('__name__', 'in', batchIds))
    const querySnapshot = await getDocs(inventoryQuery)

    querySnapshot.forEach(doc => {
      const data = doc.data()
      const baseItem: InventoryItem = {
        vin: data.vin || '',
        who: data.who || '',
        seller_asking_price: data.seller_asking_price || '',
        stock_number: data.stock_number || '',
        make: data.make || '',
        model: data.model || '',
        size: data.size || '',
        fuel: data.fuel || '',
        miles: data.miles || 0,
        year: data.year || '',
        condition: data.condition || '',
        location: data.location || '',
        type: data.type || '',
        status: data.status || '',
        url: data.url || '',
      }

      const item =
        collectionName === 'master_inventory'
          ? ({
              ...baseItem,
              optic_list_price: data.optic_list_price || '',
              show_on_website: data.show_on_website || '',
              sold_date: data.sold_date || '',
              profit: data.profit || '',
            } as MasterInventoryItem)
          : ({
              ...baseItem,
              email: data.email || '',
              phone_number: data.phone_number || '',
            } as ShieldInventoryItem)

      inventoryItems.push(item)
    })
  }

  const csvContent = convertToCSV(inventoryItems, uType)
  downloadCSV(csvContent, 'inventory_data.csv')
}
