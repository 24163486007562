import { Add, DirectionsCar, History, PhotoAlbumOutlined } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Chip, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { PageTitle } from 'components/PageTitle'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { assignUserToVehicle } from 'core/api/inventory/assignUserToVehicle'
import { removeUserFromVehicle } from 'core/api/inventory/removeUserFromVehicle'
import { setAccessCode } from 'core/api/photoUploader/setAccessCode'
import { db } from 'config/firebase'
import {
  PhotoUpload as PhotoUploadModel,
  PhotoUploadVehicle,
  UserData,
  VehicleWithUser,
} from 'types/photoUpload'
import { sendPhotoUploadLink } from 'core/utils/sendPhotoUploadLink'
import { addDoc, collection, doc, onSnapshot, serverTimestamp } from 'firebase/firestore'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AssignUserDialog from '../InventoryManagement/components/Dialogs/AssignUserDialog'
import PhotoUploadDialog from '../InventoryManagement/components/Dialogs/PhotoUploadDialog'
import VehicleForm from '../InventoryManagement/components/Forms/VehicleForm'
import InitiatePhotoUploadDialog from './components/InitiatePhotoUploadDialog'
import { PhotoUploadHistory } from './components/PhotoUploadHistory'
import ViewPhotoSubmissionsDialog from './components/ViewPhotoSubmissionsDialog'
import { Vehicle } from '@otw/models'


const PhotoUpload = () => {
  const { showSnackbar } = useSnackbar()
  const [selectedUpload, setSelectedUpload] = useState<PhotoUploadModel | null>(null)
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false)
  const [selectedVehicleForAssignment, setSelectedVehicleForAssignment] = useState<string | null>(null)
  const [isPhotoUploadDialogOpen, setIsPhotoUploadDialogOpen] = useState(false)
  const [selectedVehicleForUpload, setSelectedVehicleForUpload] = useState<Vehicle | null>(null)
  const [isInitiateDialogOpen, setIsInitiateDialogOpen] = useState(false)
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false)
  const [isVehicleFormOpen, setIsVehicleFormOpen] = useState(false)
  const { userInfo } = useAuth()
  const [photoUploads, setPhotoUploads] = useState<PhotoUploadModel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const theme = useTheme()
  const isMobile = useIsMobile()

  useEffect(() => {
    
    setIsLoading(true)

    const users: { [key: string]: UserData } = {}
    const vehicles: { [key: string]: VehicleWithUser } = {}
    const userVehicleStatuses: { [key: string]: { [key: string]: any } } = {}

    const unsubscribeUsers = onSnapshot(
      collection(db, 'users'),
      snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'removed') {
            delete users[change.doc.id]
          } else {
            const userData = change.doc.data()
            if (userData.role !== 'transporter') {
              users[change.doc.id] = {
                id: change.doc.id,
                email: userData.email,
                phone: userData.phone,
                role: userData.role,
                ...userData,
              }
            }
          }
        })
        updatePhotoUploads()
      },
      error => {
        console.error('Users listener error:', error)
        setError(error)
        setIsLoading(false)
      },
    )

    const unsubscribeVehicles = onSnapshot(
      collection(db, 'master_inventory'),
      snapshot => {
        snapshot.docChanges().forEach(change => {
          const data = change.doc.data()
          if (change.type === 'removed') {
            delete vehicles[change.doc.id]
          } else if (data.assigned_user) {
            //@ts-ignore
            vehicles[change.doc.id] = {
              id: change.doc.id,
              ...data,
            }
            setupVehicleStatusListener(data.assigned_user.id, change.doc.id)
          }
        })
        updatePhotoUploads()
      },
      error => {
        console.error('Vehicles listener error:', error)
        setError(error)
        setIsLoading(false)
      },
    )

    const vehicleStatusListeners = new Map<string, () => void>()

    const setupVehicleStatusListener = (userId: string, vehicleId: string) => {
      const listenerKey = `${userId}_${vehicleId}`

      if (vehicleStatusListeners.has(listenerKey)) {
        vehicleStatusListeners.get(listenerKey)?.()
        vehicleStatusListeners.delete(listenerKey)
      }

      const unsubscribe = onSnapshot(
        doc(db, 'users', userId, 'vehicles', vehicleId),
        doc => {
          if (!userVehicleStatuses[userId]) {
            userVehicleStatuses[userId] = {}
          }
          userVehicleStatuses[userId][vehicleId] = doc.data()
          updatePhotoUploads()
        },
        error => {
          console.error(`Vehicle status listener error for ${userId}/${vehicleId}:`, error)
        },
      )

      vehicleStatusListeners.set(listenerKey, unsubscribe)
    }

    const updatePhotoUploads = () => {
      const uploads = new Map<string, PhotoUploadModel>()

      Object.values(vehicles).forEach(vehicle => {
        const userId = vehicle.assigned_user?.id
        if (!userId || !users[userId]) return

        if (!uploads.has(userId)) {
          const userVehicles = Object.values(vehicles).filter(vehicle => vehicle.assigned_user?.id === userId)
          const submittedCount = userVehicles.reduce(
            (count, vehicle) => count + (userVehicleStatuses[userId]?.[vehicle.id]?.uploadId ? 1 : 0),
            0,
          )

          let status = 'Not submitted'
          if (submittedCount > 0) {
            if (submittedCount === userVehicles.length) {
              status = 'Fully submitted'
            } else {
              status = `${submittedCount}/${userVehicles.length} submitted`
            }
          }

          uploads.set(userId, {
            id: userId,
            email: users[userId].email || 'No email',
            phone: users[userId].phone || '',
            role: users[userId].role,
            status: status as 'Submitted' | 'Not submitted' | 'Pending' | 'Complete' | 'In Progress',
            submissionCount: submittedCount,
            dateCreated: new Date().toISOString(),
            vehicles: [],
          })
        }

        const upload = uploads.get(userId)!
        const vehicleStatus = userVehicleStatuses[userId]?.[vehicle.id]

        const vehicleWithStatus = {
          ...vehicle,
          status: vehicleStatus?.uploadId ? 'Submitted' : ('Not submitted' as 'Submitted' | 'Not submitted'),
        }

        upload.vehicles.push(vehicleWithStatus)
      })

      setPhotoUploads(Array.from(uploads.values()))
      setIsLoading(false)
    }

    return () => {
      unsubscribeUsers()
      unsubscribeVehicles()
      vehicleStatusListeners.forEach(unsubscribe => unsubscribe())
    }
  }, [])

  useEffect(() => {
    console.log('PhotoUploads data changed:', {
      isLoading,
      dataLength: photoUploads?.length,
      firstItem: photoUploads?.[0],
    })
  }, [isLoading, photoUploads])

  const ActionCellRenderer = (props: any) => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button
        variant='contained'
        size='small'
        onClick={() => {
          setSelectedUpload(props.data)
          setIsViewDialogOpen(true)
        }}
        disabled={!props.data.vehicles?.length}
      >
        View All Photos
      </Button>
      <IconButton
        aria-label='Generate Photo Upload Link'
        onClick={() => {
          setSelectedVehicleForUpload({
            ...props.data.vehicles[0],
            assigned_user: {
              id: props.data.id,
              email: props.data.email,
              role: props.data.role,
            },
            allVehicles: props.data.vehicles,
          })
          setIsPhotoUploadDialogOpen(true)
        }}
        disabled={!props.data.email || !props.data.vehicles?.length}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        }}
      >
        <PhotoAlbumOutlined />
      </IconButton>
    </Box>
  )

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'email',
        headerName: 'Email',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'phone',
        headerName: 'Phone',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 130,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: any) => {
          const chipStyles = {
            borderRadius: '12px',
            padding: '2px 8px',
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '1.5',
            whiteSpace: 'nowrap',
          }

          if (params.value === 'Fully submitted') {
            return (
              <Box
                sx={{
                  ...chipStyles,
                  backgroundColor: 'success.main',
                  color: 'white',
                }}
              >
                {params.value}
              </Box>
            )
          }

          if (params.value === 'Not submitted') {
            return (
              <Box
                sx={{
                  ...chipStyles,
                  backgroundColor: 'error.main',
                  color: 'white',
                }}
              >
                {params.value}
              </Box>
            )
          }

          if (params.value.includes('/')) {
            return (
              <Box
                sx={{
                  ...chipStyles,
                  backgroundColor: 'warning.main',
                  color: 'warning.contrastText',
                }}
              >
                {params.value}
              </Box>
            )
          }

          return params.value
        },
      },
      {
        field: 'vehicles',
        headerName: 'Vehicle Count',
        width: 130,
        valueGetter: (params: ValueGetterParams) => params.data?.vehicles?.length || 0,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'submissionCount',
        headerName: 'Photo Submissions',
        width: 130,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Actions',
        width: 150,
        cellRenderer: ActionCellRenderer,
        sortable: false,
        filter: false,
      },
    ],
    [],
  )

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      flex: 1,
    }),
    [],
  )

  const chipStyles = {
    borderRadius: '12px',
    padding: '2px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
  }

  const StatusChip = ({ status }: { status: string }) => (
    <Box
      sx={{
        ...chipStyles,
        backgroundColor: status === 'Submitted' ? 'success.main' : 'error.main',
        color: 'white',
      }}
    >
      {status}
    </Box>
  )

  const detailCellRenderer = useMemo(() => {
    return (params: ICellRendererParams) => {
      const detailGridOptions = {
        columnDefs: [
          { field: 'make', headerName: 'Make' },
          { field: 'model', headerName: 'Model' },
          { field: 'year', headerName: 'Year' },
          { field: 'vin', headerName: 'VIN' },
          {
            field: 'status',
            headerName: 'Status',
            cellRenderer: (params: any) => <StatusChip status={params.value} />,
            width: 130,
          },
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
        },
        rowData: params.data.vehicles,
        domLayout: 'autoHeight' as const,
        suppressHorizontalScroll: true,
      }

      return (
        <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
          <AgGridReact {...detailGridOptions} />
        </div>
      )
    }
  }, [])

  const isRowMaster = useCallback((dataItem: any) => {
    return dataItem ? dataItem.vehicles?.length > 0 : false
  }, [])

  const handleAssignUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await assignUserToVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User assigned successfully', 'success')
        setAssignUserDialogOpen(false)
        setSelectedVehicleForAssignment(null)
      } catch (error) {
        showSnackbar('Error assigning user', 'error')
      }
    }
  }

  const handleRemoveUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await removeUserFromVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User removed successfully', 'success')
      } catch (error) {
        showSnackbar('Error removing user', 'error')
      }
    }
  }

  const MobileCardView = () => (
    <Stack spacing={2} sx={{ p: 2 }}>
      {photoUploads.map(upload => (
        <Card
          key={upload.id}
          sx={{
            width: '100%',
            '&:hover': {
              boxShadow: 3,
            },
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
              <Box>
                <Typography variant='subtitle1' component='div' sx={{ fontWeight: 500 }}>
                  {upload.email}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {upload.phone || 'No phone'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  size='small'
                  aria-label='Generate Photo Upload Link'
                  onClick={() => {
                    setSelectedVehicleForUpload({
                      ...upload.vehicles[0],
                      assigned_user: {
                        id: upload.id,
                        email: upload.email,
                        //@ts-ignore
                        role: upload.role,
                      },
                      allVehicles: upload.vehicles,
                    })
                    setIsPhotoUploadDialogOpen(true)
                  }}
                  disabled={!upload.email || !upload.vehicles?.length}
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                    '&.Mui-disabled': {
                      backgroundColor: theme.palette.action.disabledBackground,
                    },
                  }}
                >
                  <Add fontSize='small' />
                </IconButton>

                <IconButton
                  size='small'
                  onClick={() => {
                    setSelectedUpload(upload)
                    setIsViewDialogOpen(true)
                  }}
                  disabled={!upload.vehicles?.length}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    '&.Mui-disabled': {
                      backgroundColor: theme.palette.action.disabledBackground,
                    },
                  }}
                >
                  <PhotoAlbumOutlined fontSize='small' />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <DirectionsCar sx={{ fontSize: '1rem', color: 'text.secondary' }} />
              <Typography variant='body2' color='text.secondary'>
                {upload.vehicles?.length || 0} vehicles
              </Typography>
              <Typography variant='body2' color='text.secondary' sx={{ mx: 1 }}>
                •
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {upload.submissionCount} submitted
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Chip
                size='small'
                label={upload.status}
                color={
                  upload.status === 'Submitted' ? 'success' : upload.status === 'Not submitted' ? 'error' : 'warning'
                }
              />
              {upload.vehicles
                ?.slice(0, 2)
                .map(vehicle => (
                  <Chip
                    key={vehicle.id}
                    size='small'
                    label={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                    variant='outlined'
                  />
                ))}
              {upload.vehicles?.length > 2 && (
                <Chip size='small' label={`+${upload.vehicles.length - 2} more`} variant='outlined' />
              )}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )

  const handleVehicleFormSubmit = async (data: { [key: string]: string | number | boolean }) => {
    try {
      if (!userInfo?.id) {
        showSnackbar('User not authenticated', 'error')
        return
      }

      const vehicleRef = await addDoc(collection(db, 'master_inventory'), {
        ...data,
        created_at: serverTimestamp(),
        assigned_user: {
          id: userInfo.id,
          email: userInfo.email,
          role: userInfo.role,
        },
      })

      const accessCode = `password`

      const uuid = await setAccessCode({
        accessCode,
        userId: userInfo.id,
        vehicles: [
          {
            id: vehicleRef.id,
            vin: data.vin as string,
            make: data.make as string,
            model: data.model as string,
            year: data.year as string,
          },
        ],
      })

      const link = getAccessLink(uuid)

      if (userInfo.phone) {
        await sendPhotoUploadLink({
          link,
          phoneNumber: userInfo.phone,
          email: userInfo.email,
        })
        showSnackbar('Vehicle added and photo upload link sent', 'success')
      } else {
        showSnackbar('Vehicle added successfully, but no phone number to send link', 'warning')
      }

      setIsVehicleFormOpen(false)
    } catch (error) {
      console.error('Error adding vehicle:', error)
      showSnackbar('Error adding vehicle', 'error')
    }
  }

  const generateStockNumber = () => {
    const timestamp = Date.now().toString()
    return `OT${timestamp.slice(-6)}`
  }

  const getAccessLink = (uuid: string) => {
    const baseUrl =
      process.env.VITE_ENV === 'production' ? 'https://otw-production-91ffb.web.app' : 'https://otw-stg.web.app'
    return `${baseUrl}/access/${uuid}`
  }

  return (
    <>
      <PageTitle
        title='Photo Uploader'
        subtitle='Manage photo upload links and submissions'
        bulletPoints={
          isMobile
            ? undefined
            : [
                'View all users with vehicles requiring photos',
                'Track photo submission status for each vehicle',
                'View submitted photos and vehicle details',
              ]
        }
        action={
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              '& .MuiButton-root': isMobile
                ? {
                    minWidth: 'unset',
                    px: 1,
                  }
                : {},
            }}
          >
            <Button variant='outlined' startIcon={<History />} onClick={() => setIsHistoryDialogOpen(true)}>
              {isMobile ? '' : 'HISTORY'}
            </Button>
            <Button variant='outlined' startIcon={<DirectionsCar />} onClick={() => setIsVehicleFormOpen(true)}>
              {isMobile ? '' : 'ADD VEHICLE'}
            </Button>
            <Button variant='contained' startIcon={<Add />} onClick={() => setIsInitiateDialogOpen(true)}>
              {isMobile ? 'NEW' : 'NEW PHOTO UPLOAD'}
            </Button>
          </Box>
        }
      />

      {error ? (
        <Box sx={{ p: 4, textAlign: 'center', color: 'error.main' }}>
          Error loading data: {(error as Error).message}
        </Box>
      ) : isLoading ? (
        <Box sx={{ p: 4, textAlign: 'center' }}>Loading...</Box>
      ) : (
        <>
          {isMobile ? (
            <MobileCardView />
          ) : (
            <Box className='ag-theme-alpine' sx={{ height: 600, width: '100%' }}>
              <AgGridReact
                rowData={photoUploads}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                suppressCellFocus={true}
                masterDetail={true}
                detailCellRenderer={detailCellRenderer}
                isRowMaster={isRowMaster}
                detailRowHeight={250}
                overlayNoRowsTemplate="<span style='padding: 10px'>No photo uploads found</span>"
                overlayLoadingTemplate="<span style='padding: 10px'>Loading...</span>"
                loading={isLoading}
              />
            </Box>
          )}
        </>
      )}

      {selectedUpload && (
        <ViewPhotoSubmissionsDialog
          open={isViewDialogOpen}
          onClose={() => {
            setIsViewDialogOpen(false)
            setSelectedUpload(null)
          }}
          vehicles={selectedUpload.vehicles}
          userId={selectedUpload.id}
        />
      )}

      <AssignUserDialog
        open={assignUserDialogOpen}
        onClose={() => {
          setAssignUserDialogOpen(false)
          setSelectedVehicleForAssignment(null)
        }}
        onAssign={handleAssignUser}
        onRemove={handleRemoveUser}
        currentUserId={
          selectedVehicleForAssignment
            ? photoUploads
                ?.find(upload => upload.vehicles?.some(v => v.id === selectedVehicleForAssignment))
                ?.vehicles?.find((v: PhotoUploadVehicle) => v.id === selectedVehicleForAssignment)?.assigned_user?.id
            : undefined
        }
        vehicleId={selectedVehicleForAssignment || ''}
        collectionName='master_inventory'
      />

      {selectedVehicleForUpload && (
        <PhotoUploadDialog
          open={isPhotoUploadDialogOpen}
          onClose={() => {
            setIsPhotoUploadDialogOpen(false)
            setSelectedVehicleForUpload(null)
          }}
          //@ts-ignore
          vehicle={selectedVehicleForUpload}
        />
      )}

      <InitiatePhotoUploadDialog open={isInitiateDialogOpen} onClose={() => setIsInitiateDialogOpen(false)} />

      <PhotoUploadHistory open={isHistoryDialogOpen} onClose={() => setIsHistoryDialogOpen(false)} />

      <VehicleForm
        inventoryCollection='master_inventory'
        open={isVehicleFormOpen}
        vehicle={null}
        onClose={() => setIsVehicleFormOpen(false)}
        onSubmit={handleVehicleFormSubmit}
        generateStockNumber={generateStockNumber}
      />
    </>
  )
}

export default PhotoUpload
