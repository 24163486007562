import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import ProtectedRoute from './ProtectedRoute'

const SuperUserRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userInfo } = useAuth()

  return (
    <ProtectedRoute>
      {userInfo?.role === 'admin' || userInfo?.role === 'babyAdmin' ? (
        children
      ) : (
        <Navigate to='/not-found' replace />
      )}
    </ProtectedRoute>
  )
}

export default SuperUserRoute
