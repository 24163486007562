import AddIcon from '@mui/icons-material/Add'
import CalculateIcon from '@mui/icons-material/Calculate'
import InfoIcon from '@mui/icons-material/Info'
import TimelineIcon from '@mui/icons-material/Timeline'
import FunctionsIcon from '@mui/icons-material/Functions'
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import NumberInput from 'components/NumberInput'
import { Formula, FormulaRule } from '@otw/models'
import React, { useState } from 'react'
import Calculator from '../../DepreciationCalculator/components/Calculator'
import FormulaHeatmap from '../components/FormulaHeatmap'
import RuleLine from '../components/RuleLine'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useFormulaRules } from 'hooks/graybook'

interface DepreciationFormulaBuilderProps {
  formula: Formula
  submitFormula: (
    formula: Omit<
      Formula,
      | 'id'
      | 'name'
      | 'createdAt'
      | 'updatedAt'
      | 'vehicleType'
      | 'vehicleSize'
      | 'vehicleMake'
      | 'vehicleModel'
      | 'vehicleFuel'
    >,
  ) => void
}

const MemoizedCalculator = React.memo(Calculator, (prevProps, nextProps) => {
  return (
    prevProps.formula.id === nextProps.formula.id &&
    prevProps.formula.minPrice === nextProps.formula.minPrice &&
    prevProps.formula.maxPrice === nextProps.formula.maxPrice &&
    prevProps.formula.wholesaleDiscount === nextProps.formula.wholesaleDiscount
  )
})

const DepreciationFormulaBuilder: React.FC<DepreciationFormulaBuilderProps> = ({ formula, submitFormula }) => {
  const isMobile = useIsMobile()
  const [minPrice, setMinPrice] = useState(formula.minPrice || 0)
  const [maxPrice, setMaxPrice] = useState(formula.maxPrice || 100000)
  const [wholesaleDiscount, setWholesaleDiscount] = useState(formula.wholesaleDiscount || 0)
  const [view, setView] = useState<'rules' | 'graph' | 'calculator'>(isMobile ? 'rules' : 'graph')
  const { rules, addRule, updateRule, deleteRule } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: {
      name: 'rules',
      parentDocId: formula.id,
    },
  })

  const handleSubmitFormula = () => {
    submitFormula({
      minPrice: minPrice || undefined,
      maxPrice,
      wholesaleDiscount: wholesaleDiscount || undefined,
    })
  }

  const RulesEditor = () => {
    const [tempMinPrice, setTempMinPrice] = useState(minPrice)
    const [tempMaxPrice, setTempMaxPrice] = useState(maxPrice)
    const [tempWholesaleDiscount, setTempWholesaleDiscount] = useState(wholesaleDiscount)
    const yearRules = rules?.filter(r => r.targetFactor === 'year') || []
    const mileageRules = rules?.filter(r => r.targetFactor === 'mileage') || []

    const canAddRule = (targetFactor: 'year' | 'mileage') => {
      const targetRules = rules?.filter(r => r.targetFactor === targetFactor) || []
      if (targetRules.length === 0) return true
      const lastRule = targetRules[targetRules.length - 1]
      return !!lastRule.upperBound
    }

    const addDepreciationRule = (targetFactor: 'year' | 'mileage') => {
      if (!canAddRule(targetFactor)) {
        return
      }

      const targetRules = rules?.filter(r => r.targetFactor === targetFactor) || []
      const defaultStartValue = targetFactor === 'year' ? new Date().getFullYear() : 0
      const lastTargetRule = targetRules[targetRules.length - 1]
      const startValue = lastTargetRule?.upperBound || defaultStartValue

      const newRule: Omit<FormulaRule, 'id' | 'createdAt' | 'createdBy'> = {
        inEditMode: true,
        targetFactor,
        operationType: 'subtract',
        operationValue: 0,
        lowerBound: startValue,
        status: 'active',
        order: (rules?.length || 0) + 1,
      }

      addRule(newRule)
    }

    const handleRuleSubmit = (rule: FormulaRule) => {
      updateRule({
        id: rule.id,
        data: rule,
      })
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Bounds Section */}
        <Box>
          <Typography variant='h6' gutterBottom>
            Price Bounds
          </Typography>
          {isMobile ? (
            <Paper sx={{ p: 2, mb: 2 }}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='space-around'
                sx={{ gap: 2 }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant='subtitle2'>Minimum Price</Typography>
                    <Tooltip title='Minimum allowable price after all depreciation rules' placement='top'>
                      <InfoIcon fontSize='small' color='action' />
                    </Tooltip>
                  </Box>
                  <NumberInput
                    value={tempMinPrice === 0 ? 0 : tempMinPrice}
                    onChange={value => {
                      setTempMinPrice(value === null ? 0 : value)
                    }}
                    onBlur={e => {
                      setMinPrice(tempMinPrice)
                    }}
                    slotProps={{
                      input: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      },
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant='subtitle2'>Maximum Price</Typography>
                    <Tooltip
                      title={`Maximum price for a new ${new Date().getFullYear()} vehicle with 0 miles driven`}
                      placement='top'
                    >
                      <InfoIcon fontSize='small' color='action' />
                    </Tooltip>
                  </Box>
                  <NumberInput
                    value={tempMaxPrice === 0 ? 0 : tempMaxPrice}
                    onChange={value => {
                      setTempMaxPrice(value === null ? 0 : value)
                    }}
                    onBlur={e => {
                      setMaxPrice(tempMaxPrice)
                    }}
                    slotProps={{
                      input: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          ) : (
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-around'
              sx={{
                p: 2,
                mb: 2,
                boxShadow: 1,
                borderRadius: 1,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant='subtitle2'>Minimum Price</Typography>
                  <Tooltip title='Minimum allowable price after all depreciation rules' placement='top'>
                    <InfoIcon fontSize='small' color='action' />
                  </Tooltip>
                </Box>
                <NumberInput
                  value={tempMinPrice === 0 ? 0 : tempMinPrice}
                  onChange={value => {
                    setTempMinPrice(value === null ? 0 : value)
                  }}
                  onBlur={e => {
                    setMinPrice(tempMinPrice)
                  }}
                  slotProps={{
                    input: {
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    },
                  }}
                />
              </Box>
              <Typography marginTop={2} variant='h3' color='text.secondary'>
                -
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant='subtitle2'>Maximum Price</Typography>
                  <Tooltip
                    title={`Maximum price for a new ${new Date().getFullYear()} vehicle with 0 miles driven`}
                    placement='top'
                  >
                    <InfoIcon fontSize='small' color='action' />
                  </Tooltip>
                </Box>
                <NumberInput
                  value={tempMaxPrice === 0 ? 0 : tempMaxPrice}
                  onChange={value => {
                    setTempMaxPrice(value === null ? 0 : value)
                  }}
                  onBlur={e => {
                    setMaxPrice(tempMaxPrice)
                  }}
                  slotProps={{
                    input: {
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* Depreciation Rules Section */}
        <Box>
          <Typography variant='h6' gutterBottom>
            Depreciation Rules
          </Typography>

          {/* Year Rules */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Typography variant='subtitle1'>Year Rules</Typography>
              <Tooltip
                title='Rules are cumulative. For example, if 2025-2024 depreciates by $1000/year and 2024-2021 depreciates by $2000/year, then a vehicle in 2023 will depreciate by $1000 + $2000 = $3000 per year'
                placement='top'
              >
                <InfoIcon fontSize='small' color='action' />
              </Tooltip>
            </Box>
            {yearRules.map(rule => (
              <Box key={rule.id} sx={{ mb: 2 }}>
                <RuleLine
                  rule={rule}
                  handleSumbitRule={handleRuleSubmit}
                  handleDeleteRule={rule => deleteRule(rule.id)}
                />
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => addDepreciationRule('year')}
              disabled={!canAddRule('year')}
            >
              Add Year Rule
            </Button>
          </Paper>

          {/* Mileage Rules */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Typography variant='subtitle1'>Mileage Rules</Typography>
              <Tooltip
                title='Rules are cumulative. For example, if 0-10,000 miles depreciates by $0.10/mile and 10,000-50,000 miles depreciates by $0.20/mile, then a vehicle at 30,000 miles will depreciate by $0.10 + $0.20 = $0.30 per mile'
                placement='top'
              >
                <InfoIcon fontSize='small' color='action' />
              </Tooltip>
            </Box>
            {mileageRules.map(rule => (
              <Box key={rule.id} sx={{ mb: 2 }}>
                <RuleLine
                  rule={rule}
                  handleSumbitRule={handleRuleSubmit}
                  handleDeleteRule={rule => deleteRule(rule.id)}
                />
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => addDepreciationRule('mileage')}
              disabled={!canAddRule('mileage')}
            >
              Add Mileage Rule
            </Button>
          </Paper>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant='subtitle2'>Wholesale Discount</Typography>
            <Tooltip title='Percentage discount applied for wholesale pricing' placement='top'>
              <InfoIcon fontSize='small' color='action' />
            </Tooltip>
          </Box>
          <NumberInput
            sx={{ width: '100px' }}
            value={tempWholesaleDiscount === 0 ? 0 : tempWholesaleDiscount}
            onChange={value => {
              setTempWholesaleDiscount(value === null ? 0 : value)
            }}
            onBlur={e => {
              setWholesaleDiscount(tempWholesaleDiscount)
            }}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              },
            }}
          />
        </Box>
      </Box>
    )
  }

  const FormulaVisualizer = () => {
    const yearRules = rules?.filter(r => r.targetFactor === 'year') || []
    const mileageRules = rules?.filter(r => r.targetFactor === 'mileage') || []

    return (
      <FormulaHeatmap
        yearRules={yearRules}
        mileageRules={mileageRules}
        maxPrice={maxPrice}
        minPrice={minPrice}
        wholesaleDiscount={wholesaleDiscount}
      />
    )
  }

  return (
    <Box sx={{ p: isMobile ? 0 : 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={(e, newView) => {
            if (newView !== null) {
              setView(newView)
            }
          }}
        >
          {isMobile && (
            <ToggleButton value='rules'>
              <Tooltip title='Edit Rules'>
                <FunctionsIcon />
              </Tooltip>
            </ToggleButton>
          )}
          <ToggleButton value='graph'>
            <Tooltip title='View Graph'>
              <TimelineIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='calculator'>
            <Tooltip title='View Calculator'>
              <CalculateIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        {!isMobile && (
          <Typography variant='h4' sx={{ fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
            Depreciation Formula Builder
          </Typography>
        )}
      </Box>

      {isMobile ? (
        <Box
          sx={{
            minHeight: 'calc(100vh - 200px)',
            width: '100%',
            maxWidth: '100vw',
            overflow: 'auto',
            px: 1,
          }}
        >
          {view === 'rules' && (
            <Box sx={{ width: '100%' }}>
              <RulesEditor />
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' color='primary' size='large' onClick={handleSubmitFormula}>
                  Submit Formula
                </Button>
              </Box>
            </Box>
          )}
          {view === 'graph' && (
            <Box sx={{ width: '100%' }}>
              <FormulaVisualizer />
            </Box>
          )}
          {view === 'calculator' && (
            <Box sx={{ width: '100%' }}>
              <MemoizedCalculator
                formula={{
                  ...formula,
                  minPrice: minPrice,
                  maxPrice: maxPrice,
                  wholesaleDiscount: wholesaleDiscount,
                }}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Paper sx={{ p: 2, height: '85vh', overflow: 'auto', flex: 1 }}>
            <RulesEditor />
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              <Button variant='contained' color='primary' size='large' onClick={handleSubmitFormula}>
                Submit Formula
              </Button>
            </Box>
          </Paper>
          <Paper sx={{ p: 2, height: '85vh', flex: 1 }}>
            {view === 'graph' ? (
              <FormulaVisualizer />
            ) : (
              <MemoizedCalculator
                formula={{
                  ...formula,
                  minPrice: minPrice,
                  maxPrice: maxPrice,
                  wholesaleDiscount: wholesaleDiscount,
                }}
              />
            )}
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default DepreciationFormulaBuilder
