import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { getPhotoUploadLink } from 'core/api/photoUploader/getPhotoUploadLink'
import { sendPhotoUploadLink } from 'core/utils/sendPhotoUploadLink'
import React from 'react'
import { setAccessCode as setAccessCodeAPI } from 'core/api/photoUploader/setAccessCode'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ContentCopy from '@mui/icons-material/ContentCopy'
import Typography from '@mui/material/Typography'
import { Vehicle } from '@otw/models/inventory'

interface PhotoUploadDialogProps {
  open: boolean
  onClose: () => void
  vehicle: Vehicle & { allVehicles?: Vehicle[] }
}

const PhotoUploadDialog: React.FC<PhotoUploadDialogProps> = ({ open, onClose, vehicle: row }) => {
  const [accessType, setAccessType] = React.useState('code')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [accessCode, setAccessCode] = React.useState('')
  const [generatedLink, setGeneratedLink] = React.useState<string>('')

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleAccessTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccessType(event.target.value)
  }

  const handleAccessCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value)
  }

  const getAccessLink = (uuid: string) => {
    const baseUrl = process.env.VITE_ENV === 'production' 
      ? 'https://otw-production-91ffb.web.app'
      : 'https://otw-stg.web.app'
    return `${baseUrl}/access/${uuid}`
  }

  const handleSendPhotoUpload = async () => {
    if (accessType === 'user') {
      if (email && !email.includes('@')) {
        alert('Please enter a valid email address.')
        return
      }
      if (phoneNumber && phoneNumber.length < 10) {
        alert('Please enter a valid phone number.')
        return
      }
    } else {
      if (!accessCode) {
        alert('Please enter an access code.')
        return
      }
    }

    try {
      if (!row) {
        console.error('No selected row to send photo upload link.')
        return
      }

      if (accessType === 'user') {
        const link = await getPhotoUploadLink({ 
          email,
          vehicleData: row 
        })
        await sendPhotoUploadLink({ link, email, phoneNumber })
        setPhoneNumber('')
        setEmail('')
        setAccessCode('')
      } else {
        const vehiclesToUse = row.allVehicles || [row]
        
        const uuid = await setAccessCodeAPI({ 
          accessCode,
          userId: row.assigned_user?.id,
          vehicles: vehiclesToUse.map(vehicle => ({
            id: vehicle.id,
            vin: vehicle.vin,
            make: vehicle.make || '',
            model: vehicle.model || '',
            year: vehicle.year?.toString() || ''
          }))
        })
        const link = getAccessLink(uuid)
        setGeneratedLink(link)
      }
    } catch (error) {
      console.error('Failed to send photo upload link:', error)
    }
  }

  const isSendDisabled = accessType === 'user' ? (!phoneNumber || !email) : !accessCode

  const handleClose = () => {
    setGeneratedLink('')
    setPhoneNumber('')
    setEmail('')
    setAccessCode('')
    onClose()
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink)
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Photo Upload Access</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Access Type</FormLabel>
          <RadioGroup
            aria-label="access-type"
            name="access-type"
            value={accessType}
            onChange={handleAccessTypeChange}
          >
            <FormControlLabel value="code" control={<Radio />} label="Code Based" />
            {/* <FormControlLabel value="user" control={<Radio />} label="User Based" /> */}
          </RadioGroup>
        </FormControl>

        {accessType === 'user' ? (
          <>
            <TextField
              autoFocus
              margin='dense'
              id='phone'
              label='Phone Number'
              type='text'
              fullWidth
              required
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <TextField
              margin='dense'
              id='email'
              label='Email Address'
              type='email'
              fullWidth
              required
              value={email}
              onChange={handleEmailChange}
            />
          </>
        ) : (
          <>
            <TextField
              autoFocus
              margin='dense'
              id='accessCode'
              label='Access Code'
              type='text'
              fullWidth
              required
              value={accessCode}
              onChange={handleAccessCodeChange}
              InputProps={{
                readOnly: !!generatedLink,
              }}
            />
            {generatedLink && (
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="Generated Link"
                  value={generatedLink}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton onClick={handleCopyLink} size="small" sx={{ mr: -1 }}>
                        <ContentCopy />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            )}
          </>
        )}
        <Typography variant="body2" color="textSecondary">
          <strong>Assigned To:</strong> {row.assigned_user?.email || 'Not Assigned'}
          {row.allVehicles && (
            <> - {row.allVehicles.length} vehicle{row.allVehicles.length !== 1 ? 's' : ''}</>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
        {(!generatedLink || accessType === 'user') && (
          <Button onClick={handleSendPhotoUpload} color='primary' disabled={isSendDisabled}>
            {accessType === 'user' ? 'Send' : 'Generate Link'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PhotoUploadDialog
