import React, { useState } from 'react'
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Grid,
  MenuItem,
  Alert,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useClientOnboarding } from 'hooks/client/useClientOnboarding'

const steps = [
  'Welcome',
  'Company Information',
  'Business Details',
  'Documents',
  'Financing Profile',
  'Additional Owners',
]

const entityStructures = ['Sole Proprietorship', 'LLC', 'Corporation', 'Partnership', 'S-Corporation']

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const ClientOnboarding: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [hasAdditionalOwners, setHasAdditionalOwners] = useState(false)
  const [formData, setFormData] = useState({
    company_name: '',
    entity_structure: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
    },
    phone: '',
    tax_id: '',
    website: '',
    documents: {
      business_license: '',
      tax_certificate: '',
      insurance_certificate: '',
    },
    financing_profile: {
      last_year_revenue: '',
      last_year_expenses: '',
      last_year_net_income: '',
      has_fleet: false,
      fleet_quantity: '',
      assets: {
        checking_savings: '',
        investments: '',
        vehicles: '',
        real_estate: '',
        company_equity: '',
        other_assets: '',
      },
      liabilities: {
        credit_cards: '',
        line_of_credit: '',
        vehicle_loans: '',
        mortgages: '',
        notes_payable: '',
        other_liabilities: '',
      },
    },
    additional_owners: [],
  })
  const [error, setError] = useState<string | null>(null)
  const { handleComplete } = useClientOnboarding()

  const validateStep = (step: number) => {
    switch (step) {
      case 0: // Welcome screen
        return true
      case 1: // Company Information
        return formData.company_name && formData.entity_structure
      case 2: // Business Details
        return (
          formData.address.street &&
          formData.address.city &&
          formData.address.state &&
          formData.address.zipCode &&
          formData.phone
        )
      case 3: // Documents
        return true // Documents are optional
      case 4: // Financing Profile
        return true // Financing profile is optional
      case 5: // Additional Owners
        return !hasAdditionalOwners || formData.additional_owners.length > 0
      default:
        return false
    }
  }

  const handleNext = async () => {
    if (!validateStep(activeStep)) {
      setError('Please fill in all required fields')
      return
    }
    setError(null)

    if (activeStep === steps.length - 1) {
      await handleComplete()
    } else {
      setActiveStep(prevStep => prevStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1)
    setError(null)
  }

  const handleFormChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleAddressChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: event.target.value,
      },
    }))
  }

  const handleDocumentChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      documents: {
        ...prev.documents,
        [field]: event.target.value,
      },
    }))
  }

  const handleFinancingChange = (field: string, value: string | boolean) => {
    setFormData(prev => ({
      ...prev,
      financing_profile: {
        ...prev.financing_profile,
        [field]: value,
      },
    }))
  }

  const handleAssetsChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      financing_profile: {
        ...prev.financing_profile,
        assets: {
          ...prev.financing_profile.assets,
          [field]: value,
        },
      },
    }))
  }

  const handleLiabilitiesChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      financing_profile: {
        ...prev.financing_profile,
        liabilities: {
          ...prev.financing_profile.liabilities,
          [field]: value,
        },
      },
    }))
  }

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
            <Typography variant='h5' gutterBottom>
              Welcome to the Onboarding Process
            </Typography>
            <Typography variant='body1' paragraph>
              We'll guide you through the following steps to complete your profile:
            </Typography>
            <Box sx={{ ml: 2 }}>
              <Typography variant='body1' paragraph>
                1. Verify your general company information and business details
              </Typography>
              <Typography variant='body1' paragraph>
                2. Upload necessary business documents
              </Typography>
              <Typography variant='body1' paragraph>
                3. Complete an optional financing profile to help expedite future financing requests
              </Typography>
              <Typography variant='body1' paragraph>
                4. Invite additional business owners if applicable
              </Typography>
            </Box>
            <Typography variant='body1' sx={{ mt: 2 }}>
              This process typically takes 10-15 minutes to complete. You can save your progress and return later if
              needed.
            </Typography>
          </Paper>
        )
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Company Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Company Name'
                  value={formData.company_name}
                  onChange={e => handleFormChange('company_name', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label='Entity Structure'
                  value={formData.entity_structure}
                  onChange={e => handleFormChange('entity_structure', e.target.value)}
                  required
                >
                  {entityStructures.map(structure => (
                    <MenuItem key={structure} value={structure}>
                      {structure}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        )
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Business Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Street Address'
                  value={formData.address.street}
                  onChange={handleAddressChange('street')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='City'
                  value={formData.address.city}
                  onChange={handleAddressChange('city')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label='State'
                  value={formData.address.state}
                  onChange={handleAddressChange('state')}
                  required
                >
                  {states.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='ZIP Code'
                  value={formData.address.zipCode}
                  onChange={handleAddressChange('zipCode')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  value={formData.phone}
                  onChange={e => handleFormChange('phone', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Tax ID (EIN)'
                  value={formData.tax_id}
                  onChange={e => handleFormChange('tax_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Website'
                  value={formData.website}
                  onChange={e => handleFormChange('website', e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        )
      case 3:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Documents
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>
              Please provide links to your business documents or upload them to your preferred storage service.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Business License'
                  value={formData.documents.business_license}
                  onChange={handleDocumentChange('business_license')}
                  placeholder='Enter document URL'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Tax Certificate'
                  value={formData.documents.tax_certificate}
                  onChange={handleDocumentChange('tax_certificate')}
                  placeholder='Enter document URL'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Insurance Certificate'
                  value={formData.documents.insurance_certificate}
                  onChange={handleDocumentChange('insurance_certificate')}
                  placeholder='Enter document URL'
                />
              </Grid>
            </Grid>
          </Box>
        )
      case 4:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Financing Profile
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 3 }}>
              This information helps expedite future financing requests. None of the assets listed will be pledged
              under any circumstances to obtain financing.
            </Typography>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Financial Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Last Year Revenue'
                  value={formData.financing_profile.last_year_revenue}
                  onChange={e => handleFinancingChange('last_year_revenue', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Last Year Expenses'
                  value={formData.financing_profile.last_year_expenses}
                  onChange={e => handleFinancingChange('last_year_expenses', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Net Income'
                  value={formData.financing_profile.last_year_net_income}
                  onChange={e => handleFinancingChange('last_year_net_income', e.target.value)}
                />
              </Grid>
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Fleet Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.financing_profile.has_fleet}
                      onChange={e => handleFinancingChange('has_fleet', e.target.checked)}
                    />
                  }
                  label='Do you have any trucks in your fleet?'
                />
              </Grid>
              {formData.financing_profile.has_fleet && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type='number'
                    label='Fleet Quantity'
                    value={formData.financing_profile.fleet_quantity}
                    onChange={e => handleFinancingChange('fleet_quantity', e.target.value)}
                  />
                </Grid>
              )}
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Assets
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(formData.financing_profile.assets).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    type='number'
                    label={key
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                    value={value}
                    onChange={e => handleAssetsChange(key, e.target.value)}
                  />
                </Grid>
              ))}
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Liabilities
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(formData.financing_profile.liabilities).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    type='number'
                    label={key
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                    value={value}
                    onChange={e => handleLiabilitiesChange(key, e.target.value)}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ mt: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={hasAdditionalOwners} onChange={e => setHasAdditionalOwners(e.target.checked)} />
                }
                label='I have additional owners with 20% or more ownership'
              />
            </Box>
          </Box>
        )
      case 5:
        return hasAdditionalOwners ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Additional Owners
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 3 }}>
              Please provide information for all owners with 20% or more ownership stake in the business.
            </Typography>
            {/* Additional owners form fields will be implemented here */}
          </Box>
        ) : null
      default:
        return null
    }
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 1000, mx: 'auto', p: 3 }}>
      <Typography variant='h4' gutterBottom align='center'>
        Complete Your Company Profile
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {renderStepContent(activeStep)}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        )}
        <Button variant='contained' color='primary' onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Complete' : 'Next'}
        </Button>
      </Box>
    </Box>
  )
}

export default ClientOnboarding
