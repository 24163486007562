import React from 'react'
import { Card, CardContent, Typography, Button, Box, Grid, Tooltip, IconButton } from '@mui/material'
import { Application } from '@otw/models'
import { CombinedUserData } from '@otw/models/user'
import InfoIcon from '@mui/icons-material/Info'

interface ApplicationCardProps {
  application: Application
  tabIndex: number
  users: CombinedUserData[]
  onViewDocument: (application: Application) => void
  onAssignAgent: (application: Application) => void
  onMarkCompleted: (applicationId: string) => void
  onAddNotes: (application: Application) => void
  onViewDocuments: (application: Application) => void
  onViewApprovalNotes: (application: Application) => void
  onAssociateVehicles: (application: Application) => void
}

const ApplicationCard: React.FC<ApplicationCardProps> = ({
  application,
  tabIndex,
  users,
  onViewDocument,
  onAssignAgent,
  onMarkCompleted,
  onAddNotes,
  onViewDocuments,
  onViewApprovalNotes,
  onAssociateVehicles,
}) => {
  const isSigningStatus = application.status === 'signing'

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Ready':
        return '#2196F3' // Blue
      case 'Corrections Needed':
        return '#F44336' // Red
      case 'Pending':
        return '#FFA000' // Amber
      case 'Viewed':
        return '#9C27B0' // Purple
      case 'Approved':
        return '#4CAF50' // Green
      case 'Denied':
        return '#D32F2F' // Dark Red
      case 'Completed':
        return '#757575' // Grey
      default:
        return 'text.primary'
    }
  }

  const userInfo = users?.find(user => user.email === application.user_email)
  const userName = userInfo
    ? `${userInfo.first_name} ${userInfo.last_name}`
    : application.user_name || 'Unknown User'

  return (
    <Card
      variant='outlined'
      sx={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        cursor: isSigningStatus ? 'default' : 'pointer',
        '&:hover': isSigningStatus ? {} : {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
        },
        position: 'relative',
      }}
      onClick={() => !isSigningStatus && onViewDocument(application)}
    >
      <CardContent>
        <Box
          display='flex'
          justifyContent='space-between'
          sx={{
            mb: 3,
            pb: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          }}
        >
          <Box>
            <Typography variant='overline' color='text.secondary'>
              Application ID
            </Typography>
            <Typography variant='h6' sx={{ fontWeight: 600 }}>
              {application.id}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant='overline' color='text.secondary'>
              Status
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: getStatusColor(application.status),
                fontWeight: 600,
              }}
            >
              {application.status}
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={3} sx={{ mb: isSigningStatus ? 5 : 3 }}>
          <Grid item xs={6}>
            <Typography variant='overline' color='text.secondary'>
              User
            </Typography>
            <Typography variant='body1'>{userName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='overline' color='text.secondary'>
              Bank Name
            </Typography>
            <Typography variant='body1'>{application.bank_id}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='overline' color='text.secondary'>
              Date Submitted
            </Typography>
            <Typography variant='body1'>{application.time_created}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='overline' color='text.secondary'>
              Bank Agent
            </Typography>
            <Typography variant='body1'>{application.bank_agent_id || 'No Agent Assigned'}</Typography>
          </Grid>
        </Grid>

        {isSigningStatus && (
          <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
            <Tooltip 
              title="You cannot view applications until they have been initially submitted by the client" 
              placement="top"
            >
              <IconButton size="small">
                <InfoIcon sx={{ color: 'warning.main' }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {!isSigningStatus && (
          <Box
            mt={2}
            display='flex'
            gap={1}
            justifyContent='center'
            flexWrap='wrap'
            sx={{
              '& .MuiButton-root': {
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 600,
                px: 2,
                py: 1,
                minWidth: 120,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
                },
              },
            }}
          >
            {tabIndex === 1 && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  onAssignAgent(application)
                }}
                variant='contained'
                color='primary'
                size='small'
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    color: '#fff',
                  },
                }}
              >
                Assign Agent
              </Button>
            )}

            {(tabIndex === 4 || tabIndex === 5) && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  onMarkCompleted(application.id)
                }}
                variant='contained'
                color='primary'
                size='small'
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    color: '#fff',
                  },
                }}
              >
                Mark Completed
              </Button>
            )}

            {tabIndex !== 4 && tabIndex !== 5 && tabIndex !== 6 && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  onAddNotes(application)
                }}
                variant='contained'
                color='primary'
                size='small'
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    color: '#fff',
                  },
                }}
              >
                Bank Notes
              </Button>
            )}

            <Button
              onClick={e => {
                e.stopPropagation()
                onViewDocuments(application)
              }}
              variant='contained'
              color='primary'
              size='small'
              sx={{
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  color: '#fff',
                },
              }}
            >
              <Typography variant='body2'>Additional Documents</Typography>
            </Button>

            {[4, 5, 6].includes(tabIndex) && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  onViewApprovalNotes(application)
                }}
                variant='contained'
                color='primary'
                size='small'
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    color: '#fff',
                  },
                }}
              >
                View Approval Terms
              </Button>
            )}

            {tabIndex === 6 && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  onAssociateVehicles(application)
                }}
                variant='contained'
                color='primary'
                size='small'
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    color: '#fff',
                  },
                }}
              >
                Associate Vehicles
              </Button>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default ApplicationCard
