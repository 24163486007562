import { ApplicationStatus } from '@otw/models'

export const STATUS_COLORS: Record<ApplicationStatus, string> = {
  draft: '#757575',
  ready: '#03A9F4',
  corrections_needed: '#F44336',
  pending: '#F9A825',
  viewed: '#9C27B0',
  approved: '#4caf50',
  denied: '#B71C1C',
  signing: '#FFA000',
} 