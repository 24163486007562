import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { Vehicle } from "../inventory";

const vehicleConverter: FirestoreDataConverter<Vehicle> = {
  toFirestore(vehicle: WithFieldValue<Vehicle>) {
    const { id, ...data } = vehicle;
    return data;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Vehicle {
    const data = snapshot.data(options);
    const safeNumber = (value: any): number | null => {
      if (!value) return null;
      const num = Number(value);
      return isNaN(num) ? null : num;
    };

    // if no data, return a new vehicle with all null values
    if (!data)
      return {
        id: snapshot.id,
        date_added: null,
        entered_by: "",
        vin: "",
        status: null,
        stock_number: "",
        year: null,
        make: null,
        model: null,
        size: null,
        type: null,
        miles: null,
        fuel: null,
        condition: null,
        optic_list_price: null,
        seller_asking_price: null,
        profit: null,
        payoff_amount: null,
        sold_date: null,
        who: null,
        sold_to: null,
        dealQuality: null,
        location: null,
        phone_number: null,
        email: null,
        truck_notes: null,
        follow_up_notes: null,
        link: null,
        photo_storage_path: null,
        show_on_website: false,
        updated_by: null,
        updated_at: null,
      };

    return {
      // Core identification fields
      id: snapshot.id,
      date_added: data.date_added || null,
      entered_by: data.entered_by || "",
      vin: data.vin || "",
      stock_number: data.stock_number || "",

      // Basic vehicle information
      year: safeNumber(data.year),
      make: data.make || null,
      model: data.model || null,
      size: data.size || null,
      type: data.type || null,
      miles: safeNumber(data.miles),
      fuel: data.fuel || null,
      condition: data.condition || null,

      // Financial information
      optic_list_price: safeNumber(data.optic_list_price),
      seller_asking_price: safeNumber(data.seller_asking_price),
      profit: safeNumber(data.profit),
      payoff_amount: safeNumber(data.payoff_amount),
      ...(data.repair_cost && { repair_cost: safeNumber(data.repair_cost) }),
      ...(data.shipping_cost && {
        shipping_cost: safeNumber(data.shipping_cost),
      }),

      // Sales information
      sold_date: data.sold_date || null,
      who: data.who || null,
      sold_to: data.sold_to || null,
      status: [
        "Pending Available",
        "Available",
        "Pending Sale",
        "Sold",
        "Sold elsewhere",
        "Active Deal",
      ].includes(data.status || "")
        ? (data.status as Vehicle["status"])
        : null,
      dealQuality: [
        "great",
        "good",
        "average",
        "belowAverage",
        "poor",
      ].includes(data.dealQuality || "")
        ? (data.dealQuality as Vehicle["dealQuality"])
        : null,

      // Contact and location details
      location: data.location || null,
      phone_number: data.phone_number || null,
      email: data.email || null,
      ...(data.assigned_user && { assigned_user: data.assigned_user }),

      // Notes and additional information
      truck_notes: data.truck_notes || null,
      follow_up_notes: data.follow_up_notes || null,
      link: data.link || null,

      // Photo and display properties
      show_on_website: Boolean(data.show_on_website),
      photo_storage_path: data.photo_storage_path || null,
      photos: {
        main: data.photos?.main || null,
        front: data.photos?.front || null,
        back: data.photos?.back || null,
        left: data.photos?.left || null,
        right: data.photos?.right || null,
        interior: data.photos?.interior || null,
        engine: data.photos?.engine || null,
      },
    };
  },
};

export { vehicleConverter };
