import {
  AttachMoney,
  CalendarToday,
  Delete,
  DirectionsCar,
  LocalGasStation,
  LocationOn,
  Phone,
  PhotoAlbumOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import { cargoVan, boxTruck, cutaway, stepVan } from 'assets'
import { useAuth } from 'contexts/AuthContext'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import {
  calculateProfit,
  formatDateTime,
  formatDistance,
  formatPhoneNumber,
  formatPrice,
  sanitizeNumber,
} from 'core/utils/inventoryUtils'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useInventory } from 'hooks/inventory/useInventory'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import React, { useCallback, useMemo, useState } from 'react'
import VehicleForm from '../Forms/VehicleForm'
import { Vehicle } from '@otw/models/inventory'

interface VehicleCardProps {
  collectionName: 'master_inventory' | 'shield_inventory'
  vehicle: Vehicle
  onDeleteClick: () => void
  onManagePhotosClick: () => void
  onShowHideOnWebsiteClick: () => void
  isAdmin?: boolean
  vehicles: Vehicle[]
}

const VehicleCard: React.FC<VehicleCardProps> = ({
  collectionName,
  vehicle,
  onDeleteClick,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
  isAdmin,
  vehicles,
}) => {
  const { userInfo } = useAuth()
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [optimisticShowOnWebsite, setOptimisticShowOnWebsite] = React.useState(vehicle.show_on_website)
  const [selectedVehicle, setSelectedVehicle] = React.useState<Vehicle | null>(null)
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const isMobile = useIsMobile()

  const { updateDocument } = useInventory(collectionName)

  React.useEffect(() => {
    setOptimisticShowOnWebsite(vehicle.show_on_website)
  }, [vehicle.show_on_website])

  const { data: photos, isLoading: isLoadingPhoto } = usePhotosForInventoryObject(collectionName, vehicle.id, {
    onlyMainPhoto: true,
    enabled: vehicle.photo_storage_path !== '',
  })

  const handleShowHideClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowConfirmDialog(true)
  }

  const handleConfirmShowHide = () => {
    setOptimisticShowOnWebsite(!optimisticShowOnWebsite)
    onShowHideOnWebsiteClick()
    setShowConfirmDialog(false)
  }
  const icon =
    vehicle.type === 'Box Truck'
      ? boxTruck
      : vehicle.type === 'Cutaway'
        ? cutaway
        : vehicle.type === 'Cargo Van'
          ? cargoVan
          : stepVan

  const handleClickVehicle = useCallback((vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleForm(true)
  }, []) // Empty

  const statusColor = useMemo(() => {
    switch (vehicle.status) {
      case 'Available':
        return '#2e7d32' // Dark green
      // @ts-expect-error some legacy data in the database
      case 'available':
        return '#2e7d32' // Dark green
      case 'Pending Available':
        return '#1976d2' // Blue
      case 'Sold':
        return '#d32f2f' // Dark red
      // @ts-expect-error some legacy data in the database
      case 'sold':
        return '#d32f2f' // Dark red
      case 'Pending Sale':
        return '#ef5350' // Light red
      case 'Sold elsewhere':
        return '#ef5350' // Light red
      default:
        return '#757575' // Gray
    }
  }, [vehicle.status])

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
    sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
    sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }

    try {
      updateDocument({
        id: selectedVehicle.id,
        data: newValues,
      })
      setOpenVehicleForm(false)
    } catch (error) {
      setOpenVehicleForm(false)
      console.error('Error updating vehicle:', error)
    }
  }

  const VehiclePhoto = () => {
    if (isLoadingPhoto) {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress sx={{ mt: 2 }} />
        </Box>
      )
    }

    return (
      <>
        {photos && photos.length > 0 ? (
          <CardMedia
            component='img'
            srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
            src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
            alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            loading='lazy'
            sx={{
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            sx={{ bgcolor: 'background.default' }}
          >
            <img
              src={icon}
              alt='No Photo Available'
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        )}
      </>
    )
  }

  // TODO: feedback for stock number or vin on card
  const MobileVehicleCard = () => {
    return (
      <Card
        sx={{
          mb: 2,
          height: '220px',
        }}
        onClick={() => handleClickVehicle(vehicle)}
      >
        <Box height='80%' display='flex' flexDirection='row'>
          <Box sx={{ width: '40%', height: '100%', position: 'relative' }}>
            <VehiclePhoto />
            {vehicle.status && (
              <Typography
                variant='caption'
                sx={{
                  position: 'absolute',
                  bottom: 4,
                  right: 4,
                  color: 'white',
                  backgroundColor: statusColor,
                  fontSize: '0.65rem',
                  fontWeight: 'medium',
                  padding: '2px 6px',
                  borderRadius: '4px',
                  opacity: 0.9,
                }}
              >
                {vehicle.status}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, width: '60%' }}>
            <Typography component='div' variant='subtitle2' fontWeight='bold' noWrap>
              {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' alignItems='center' color='text.secondary' sx={{ gap: 1 }}>
                <LocationOn fontSize='small' />
                <Typography variant='body2' color='text.secondary'>
                  {vehicle.location || 'N/A'}
                </Typography>
              </Box>
              <Typography variant='body2' color='text.primary'>
                {formatDistance(vehicle.miles)} miles
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.secondary' noWrap>
                {vehicle.who}
              </Typography>
              <Typography variant='body2' color='text.secondary' noWrap>
                {formatPhoneNumber(vehicle.phone_number)}
              </Typography>
            </Box>
            <Box>
              {isAdmin && (
                <Typography variant='body2'>
                  <strong>Buy Price:</strong> {formatPrice(vehicle.seller_asking_price)}
                </Typography>
              )}
              <Typography variant='body2'>
                <strong>Sell Price:</strong>{' '}
                {vehicle.optic_list_price != null ? (
                  <span>
                    {formatPrice(vehicle.optic_list_price)}
                    {isAdmin && vehicle.seller_asking_price != null && Number(vehicle.profit) !== 0 && (
                      <span style={{ color: Number(vehicle.profit) > 0 ? 'green' : 'red' }}>
                        {' '}
                        ({formatPrice(vehicle.profit)})
                      </span>
                    )}
                  </span>
                ) : (
                  'N/A'
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between', px: 1, py: 0.5 }}>
          {isAdmin && (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                onDeleteClick()
              }}
              size='small'
            >
              <Delete />
            </IconButton>
          )}
          <IconButton
            onClick={e => {
              e.stopPropagation()
              onManagePhotosClick()
            }}
            size='small'
          >
            <PhotoAlbumOutlined />
          </IconButton>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              onShowHideOnWebsiteClick()
            }}
            size='small'
          >
            {vehicle.show_on_website ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </CardActions>
      </Card>
    )
  }

  return (
    <>
      {isMobile ? (
        <MobileVehicleCard />
      ) : (
        <Card
          sx={{
            minWidth: 275,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            '&:hover': { boxShadow: 6 },
            color: 'text.primary',
          }}
          onClick={() => handleClickVehicle(vehicle)}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: '2px solid',
              borderColor: 'primary.light',
              opacity: 0,
              transition: 'opacity 0.3s',
              '&:hover': { opacity: 1 },
            }}
          />
          <CardHeader
            sx={{ flexShrink: 0 }}
            title={
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
              </Box>
            }
            subheader={
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='body2'>Stock Number: {vehicle.stock_number}</Typography>
              </Box>
            }
          />
          <Box sx={{ height: 200, flexShrink: 0, position: 'relative' }}>
            <VehiclePhoto />
            {vehicle.status && (
              <Typography
                variant='caption'
                sx={{
                  position: 'absolute',
                  bottom: 4,
                  right: 4,
                  color: 'white',
                  backgroundColor: statusColor,
                  fontWeight: 'medium',
                  padding: '2px 6px',
                  borderRadius: '4px',
                  opacity: 0.9,
                }}
              >
                {vehicle.status}
              </Typography>
            )}
          </Box>
          <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                  <DirectionsCar fontSize='small' /> Vehicle Info
                </Typography>
                <Typography variant='body2'>
                  <strong>VIN:</strong> {vehicle.vin}
                </Typography>
                <Typography variant='body2'>
                  <strong>Stock #:</strong> {vehicle.stock_number}
                </Typography>
                <Typography variant='body2'>
                  <strong>Type:</strong> {vehicle.type}
                </Typography>
                <Typography variant='body2'>
                  <strong>Size:</strong> {vehicle.size}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                  <LocalGasStation fontSize='small' /> Performance
                </Typography>
                <Typography variant='body2'>
                  <strong>Fuel:</strong> {vehicle.fuel}
                </Typography>
                <Typography variant='body2'>
                  <strong>Miles:</strong> {formatDistance(vehicle.miles)}
                </Typography>
              </Grid>
              {isAdmin && (
                <Grid item xs={6}>
                  <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                    <Phone fontSize='small' /> Contact
                  </Typography>
                  <Typography variant='body2'>
                    <strong>Contact:</strong> {vehicle.who}
                  </Typography>
                  <Typography variant='body2'>
                    <strong>Location:</strong> {vehicle.location}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                  <AttachMoney fontSize='small' /> Pricing
                </Typography>
                {isAdmin && (
                  <Typography variant='body2'>
                    <strong>Buy Price:</strong>{' '}
                    {vehicle.seller_asking_price != null ? formatPrice(vehicle.seller_asking_price) : 'N/A'}
                  </Typography>
                )}
                <Typography variant='body2'>
                  <strong>Sell Price:</strong>{' '}
                  {vehicle.optic_list_price != null ? formatPrice(vehicle.optic_list_price) : 'N/A'}
                </Typography>
                {isAdmin && (
                  <Typography variant='body2'>
                    <strong>Potential Profit:</strong> {vehicle.profit != null ? formatPrice(vehicle.profit) : 'N/A'}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' alignItems='center' gap={2}>
                  <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                    <CalendarToday fontSize='small' /> <strong>Added:</strong> {formatDateTime(vehicle.date_added)}
                  </Typography>
                  {vehicle.sold_date && (
                    <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                      <CalendarToday fontSize='small' /> <strong>Sold:</strong>{' '}
                      {formatDateTime(vehicle.sold_date) || 'N/A'}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ flexShrink: 0, borderTop: 1, borderColor: 'divider' }}>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <IconButton
                aria-label='Delete Vehicle'
                onClick={event => {
                  event.stopPropagation()
                  onDeleteClick()
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                aria-label='Manage Photos'
                onClick={event => {
                  event.stopPropagation()
                  onManagePhotosClick()
                }}
                disabled={!userInfo?.email_verified}
              >
                <PhotoAlbumOutlined />
              </IconButton>
              <Switch
                checked={optimisticShowOnWebsite}
                icon={<VisibilityOff />}
                checkedIcon={<Visibility />}
                onClick={handleShowHideClick}
                color={optimisticShowOnWebsite ? 'success' : 'error'}
              />
            </Box>
          </CardActions>
        </Card>
      )}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <DialogTitle>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</DialogTitle>
        <DialogContent>
          Are you sure you want to {optimisticShowOnWebsite ? 'hide' : 'show'} this vehicle
          {optimisticShowOnWebsite ? ' from' : ' on'} the website?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmShowHide} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <VehicleForm
        open={openVehicleForm}
        vehicle={selectedVehicle}
        onClose={() => {
          setOpenVehicleForm(false)
        }}
        onSubmit={handleEditVehicle}
        inventoryCollection={collectionName}
        generateStockNumber={() =>
          generateOpticStockNumber(
            (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
        }
        isEdit={true}
      />
    </>
  )
}

export default VehicleCard
