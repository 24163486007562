import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'
import { useAuth } from 'hooks/auth/useAuth'
import { useMFA } from 'hooks/auth/useMFA'
import { useNavigate } from 'react-router-dom'

export const MFAWarning: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [acknowledged, setAcknowledged] = useState(false)
  const { userInfo } = useAuth()
  const navigate = useNavigate()
  const { isCurrentUserMfaEnrolled } = useMFA()

  useEffect(() => {
    // Check if the warning has been shown this session
    const hasShownWarning = sessionStorage.getItem('mfaWarningShown')

    // Show warning if user is logged in, doesn't have MFA enabled, and hasn't seen warning this session
    if (
      userInfo &&
      !isCurrentUserMfaEnrolled() &&
      !hasShownWarning &&
      userInfo.email !== 'colton@optictruckworks.com'
    ) {
      setOpen(true)
    }
  }, [userInfo])

  const handleAcknowledge = () => {
    sessionStorage.setItem('mfaWarningShown', 'true')
    setOpen(false)
  }

  const handleEnableMFA = () => {
    setOpen(false)
    navigate('/account-settings')
  }

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: 'warning.dark',
        }}
      >
        <SecurityIcon color='warning' />
        Security Recommendation
      </DialogTitle>

      <DialogContent>
        <Alert severity='warning' sx={{ mb: 2 }}>
          Your account is not protected by Two-Factor Authentication (2FA)
        </Alert>


        <Typography variant='body1' paragraph>
          To better protect your account and maintain the security of sensitive data, we strongly recommend enabling
          Two-Factor Authentication (2FA).
        </Typography>

        <FormControlLabel
          control={
            <Checkbox checked={acknowledged} onChange={e => setAcknowledged(e.target.checked)} color='warning' />
          }
          label={
            <Typography variant='body2' color='text.secondary'>
              I understand the security risks of not enabling 2FA and wish to proceed anyway
            </Typography>
          }
          sx={{ mt: 1 }}
        />
      </DialogContent>

      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={handleAcknowledge} color='inherit' disabled={!acknowledged}>
          Proceed Without 2FA
        </Button>
        <Button
          onClick={handleEnableMFA}
          variant='contained'
          color='warning'
          sx={{
            '&:hover': {
              bgcolor: 'warning.dark',
            },
          }}
        >
          Set up 2FA Now
        </Button>
      </DialogActions>
    </Dialog>
  )
}
