import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Application } from '@otw/models'
import React from 'react'
// import { approveApplication, denyApplication } from "core/api/application/bank"; // Replace with actual import path

interface AddNotesDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
}

const ViewApprovalTerms: React.FC<AddNotesDialogProps> = ({ application, isOpen, onClose }) => {
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Add Notes</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Application ID'
          margin='dense'
          value={application?.id || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Client Email'
          margin='dense'
          value={application?.user_email || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Time Created'
          margin='dense'
          value={application?.time_created || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Status'
          margin='dense'
          value={application?.status || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Optic Notes'
          multiline
          rows={2}
          margin='dense'
          value={application?.notes || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />

        <TextField
          fullWidth
          label='Decision Notes'
          multiline
          rows={4}
          margin='dense'
          value={application?.bank_decision_notes || ''}
          variant='outlined'
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewApprovalTerms
