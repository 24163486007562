import {
  InputAdornment,
  TextField,
  type TextFieldProps
} from '@mui/material'
import * as React from 'react'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'

const NumericFormatCustom = React.forwardRef<HTMLInputElement, NumericFormatProps>(
  function NumericFormatCustom({ value, decimalScale, ...rest }, ref) {
    return (
      <NumericFormat
        {...rest}
        value={value ?? ''} // We can't ever pass null to value because it breaks the shrink state of the label, so we pass empty string instead
        getInputRef={ref}
        thousandSeparator=','
        decimalSeparator='.'
        allowLeadingZeros
        fixedDecimalScale={decimalScale !== undefined && decimalScale !== 0}
        decimalScale={decimalScale ?? 2}
      />
    )
  },
)

type NumberInputProps = Omit<TextFieldProps, 'onChange'> & {
  max?: number
  min?: number
  numericFormatProps?: NumericFormatProps
  onChange?: (value: number | null) => void
  step?: number
  value?: number | null
  decimalScale?: number
}

const NumberInput = React.forwardRef<HTMLDivElement, NumberInputProps>(function NumberInput(props, ref) {
  const {
    disabled = false,
    max = Infinity,
    min = -Infinity,
    numericFormatProps: numericFormatPropsProp,
    onChange,
    size,
    slotProps,
    step = 1,
    value: valueProp,
    decimalScale,
    ...rest
  } = props
  const isControlled = valueProp !== undefined && onChange !== undefined

  // We use an internal state when the component is uncontrolled
  const [fallbackValue, setFallbackValue] = React.useState(valueProp)
  const value = isControlled ? valueProp : fallbackValue
  const setValue = isControlled ? onChange : setFallbackValue

  const increment = () => {
    // If we increment when the input is empty, we consider the previous value to be 0
    const newValue = (value != null && !Number.isNaN(value) ? value : 0) + step

    if (newValue > max) {
      return
    }

    setValue(newValue)
  }

  const decrement = () => {
    // If we decrement when the input is empty, we consider the previous value to be 0
    const newValue = (value != null && !Number.isNaN(value) ? value : 0) - step

    if (newValue < min) {
      return
    }

    setValue(newValue)
  }

  const numericFormatProps: NumericFormatProps = {
    // We set a default to avoid displaying floating point errors when using a decimal step
    decimalScale: decimalScale ?? 0,

    // react-number-format doesn't provide min or max props so we do the checking here instead
    isAllowed: ({ floatValue }) => {
      if (floatValue == null) {
        return true
      }

      return floatValue >= min && floatValue <= max
    },

    // Only add the min, max and step html attributes when the value isn't the default one
    max: max !== Infinity ? max : undefined,
    min: min !== -Infinity ? min : undefined,
    step: step !== 1 ? step : undefined,

    // Allow to increment with ArrowUp and decrement with ArrowDown
    onKeyDown: event => {
      if (event.key === 'ArrowUp') {
        increment()
      } else if (event.key === 'ArrowDown') {
        decrement()
      }
    },

    onValueChange: ({ floatValue }) => {
      // When incrementing or decrementing, the value prop is already up to date
      // so we make sure the value needs to be updated to prevent an unnecessary re-render
      if (floatValue === value) {
        return
      }

      setValue(floatValue ?? null)
    },
    value,

    ...numericFormatPropsProp,
  }


  return (
    <TextField
      {...rest}
      ref={ref}
      value={value ?? ''} // We can't ever pass null to value because it breaks the shrink state of the label, so we pass empty string instead
      disabled={disabled}
      size={size}
      InputProps={{
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }}
      slotProps={{
        ...slotProps,
        input: {
          inputComponent:  NumericFormatCustom as any,
          ...slotProps?.input,
        },
        htmlInput: { ...slotProps?.htmlInput, ...numericFormatProps } as any,
      }}
    />
  )
})

export default NumberInput
