import React, { useState } from 'react'
import {
  Box,
  Button,
  Badge,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import { useNavigate } from 'react-router-dom'
import { Payment, Assignment, Description } from '@mui/icons-material'
import { useNotifications } from 'hooks/useNotifications'
import { useAuth } from 'hooks/auth/useAuth'
import { useUsers } from 'hooks/useUsers'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getIcon, getNotificationMessage } from 'core/notifications/getNotificationMessage'
import { handleNotificationClick } from 'core/notifications/handleNotificationClick'
import { INotification } from '@otw/models'
import { useInventory } from 'hooks/inventory/useInventory'

const NotificationAccordion: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { userInfo } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { notifications, markAsRead, markAllAsRead } = useNotifications(userInfo?.id || '')
  const { users } = useUsers()
  const { vehicles } = useInventory('master_inventory')
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const unreadNotifications = notifications.filter(n => !n.read)

  const handleMarkAsRead = (event: React.MouseEvent, notificationId?: string) => {
    event.stopPropagation()
    if (notificationId) {
      markAsRead.mutate(notificationId)
    } else {
      console.error('No notification ID provided')
    }
  }

  return (
    <Box>
      <Button
        onClick={handleClick}
        sx={{
          padding: 0,
          minWidth: 'auto',
          marginRight: { xs: 0.5, sm: 1 },
        }}
      >
        <Badge badgeContent={unreadNotifications.length} color='error' overlap='circular'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <svg width='0' height='0'>
              <linearGradient id='mailIconGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
                <stop offset='20%' stopColor={theme.palette.secondary.main} />
                <stop offset='30%' stopColor={theme.palette.secondary.light} />
                <stop offset='100%' stopColor={theme.palette.secondary.light} />
              </linearGradient>
            </svg>
            <MailIcon
              sx={{
                fill: 'url(#mailIconGradient)',
                fontSize: 24,
              }}
            />
          </Box>
        </Badge>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: { xs: '100vw', sm: '420px' },
            maxWidth: { xs: '100vw', sm: '420px' },
            maxHeight: { xs: 'calc(100vh - 64px)', sm: 'calc(100vh - 100px)' },
            overflowY: 'auto',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              position: { xs: 'fixed', sm: 'absolute' },
              top: { xs: '64px', sm: 'auto' },
              left: { xs: '0', sm: 'unset' },
              right: { xs: '0', sm: 'unset' },
              transform: {
                xs: 'none !important',
                sm: 'translate(-32px, 0) !important',
              },
              borderRadius: { xs: 0, sm: 1 },
              boxShadow: theme => theme.shadows[8],
            },
          },
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box
            sx={{
              px: { xs: 1.5, sm: 2 },
              py: { xs: 1, sm: 1.5 },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
              Notifications
            </Typography>
            <Button
              size='small'
              onClick={() => markAllAsRead.mutate()}
              disabled={markAllAsRead.isPending || unreadNotifications.length === 0}
              sx={{ color: 'primary.main' }}
            >
              Mark all as read
            </Button>
          </Box>
        </Box>
        <List sx={{ py: 0 }}>
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map(notification => (
              <ListItem
                key={notification.id}
                component='button'
                onClick={() => {
                  const destination = handleNotificationClick(notification)
                  if (destination) {
                    navigate(destination)
                  }
                }}
                sx={{
                  display: 'flex',
                  width: '100%',
                  p: { xs: 1.5, sm: 2 },
                  textAlign: 'left',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: { xs: 32, sm: 40 } }}>
                  {React.createElement(getIcon(notification.type) || 'div', {
                    sx: {
                      color: 'primary.main',
                      fontSize: { xs: 20, sm: 24 },
                    },
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={getNotificationMessage(notification, users, vehicles || [])}
                  secondary={notification.createdAt ? notification.createdAt.toLocaleString() : 'No date'}
                  primaryTypographyProps={{
                    variant: 'body2',
                    fontWeight: 500,
                    color: 'text.primary',
                    fontSize: { xs: '0.8125rem', sm: '0.875rem' },
                  }}
                  secondaryTypographyProps={{
                    variant: 'caption',
                    fontSize: { xs: '0.75rem', sm: '0.8125rem' },
                  }}
                />
                <Button
                  onClick={e => handleMarkAsRead(e, notification.id)}
                  sx={{
                    minWidth: 'auto',
                    p: { xs: 0.25, sm: 0.5 },
                    ml: 1,
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: { xs: 18, sm: 20 } }} />
                </Button>
              </ListItem>
            ))
          ) : (
            <ListItem sx={{ py: { xs: 3, sm: 4 } }}>
              <ListItemText
                sx={{ textAlign: 'center' }}
                primary={<Typography color='text.secondary'>No notifications</Typography>}
              />
            </ListItem>
          )}
        </List>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose()
              navigate('/notification-center')
            }}
            sx={{
              justifyContent: 'center',
              py: { xs: 1, sm: 1.5 },
              fontWeight: 500,
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.light',
                color: 'white',
              },
            }}
          >
            See All
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}

export default NotificationAccordion
