import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!currentUser && !isLoading) {
      navigate('/', { state: { from: location }, replace: true })
    }
    if (isLoading) {
      return
    }
  }, [currentUser, isLoading, navigate, location])

  if (isLoading) {
    return null
  }

  if (!currentUser) {
    return <Navigate to='/' state={{ from: location }} replace />
  }

  // if (!isLoadinguserInfo?.disabled === false) {
  //   return <Navigate to="/account-deactivated"
  //    replace />;
  // }

  return <>{children}</>
}

export default ProtectedRoute
