import {
  INotification,
  ApplicationCreatedNotificationData,
  ApplicationStatusChangeNotificationData,
  InventorySoldNotificationData,
  Vehicle,
} from '@otw/models'
import { PrivateUserInfoType } from '@otw/models/user/user'
import { NotificationType } from '@otw/models/notifications/enums'
import {
  Payment as PaymentIcon,
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Comment as CommentIcon,
  Receipt as ReceiptIcon,
} from '@mui/icons-material'

const iconMap: Record<string, typeof DescriptionIcon> = {
  [NotificationType.user_set_initial_password]: ReceiptIcon,
}

export const getIcon = (type: string) => {
  return iconMap[type] || DescriptionIcon
}

type NotificationHandler = (data: any, users: PrivateUserInfoType[], vehicles: Vehicle[]) => string

const getUserFullName = (userId: string, users: PrivateUserInfoType[]) => {
  const user = users.find(u => u.id === userId)
  return user ? `${user.first_name} ${user.last_name}` : 'Unknown User'
}

const notificationHandlers: Record<string, NotificationHandler> = {
  [NotificationType.user_set_initial_password]: data => data.title || 'User logged in and set initial password.',

  [NotificationType.application_created_event]: (data: ApplicationCreatedNotificationData, users) => {
    return `${getUserFullName(data.userId, users)} has submitted an application`
  },

  [NotificationType.application_status_change_event]: (data: ApplicationStatusChangeNotificationData, users) => {
    return `${getUserFullName(data.userId, users)} has had their application status updated`
  },

  [NotificationType.inventory_sold_event]: (data: InventorySoldNotificationData, users, vehicles) => {
    const vehicle = vehicles.find(v => v.id === data.vehicleId)
    return `#${vehicle?.stock_number} was marked as Sold by ${getUserFullName(data.userId, users)}`
  },
}

export const getNotificationMessage = (
  notification: INotification,
  users: PrivateUserInfoType[],
  vehicles: Vehicle[],
): string => {
  const handler = notificationHandlers[notification.type]
  if (handler) {
    return handler(notification.data, users, vehicles)
  }
  return notification.data.title || 'New notification'
}
