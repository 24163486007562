import { PandaDocument } from '@otw/models'
import { functionsBaseUrl } from 'config/functionsBaseUrl'
import { getHeaders } from 'core/getHeaders'

export const pandaFunctions = {
  shared: {
    getViewableDocument: async (applicationId: string): Promise<string> => {
      const url = `${functionsBaseUrl}/panda/shared/getViewableDocument`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ applicationId }),
      })

      if (!response.ok) {
        throw new Error('Failed to get viewable document')
      }

      const data = await response.blob()

      const outputUrl = URL.createObjectURL(data)

      console.log('outputUrl', outputUrl)

      if (!outputUrl) {
        throw new Error('Failed to get viewable document')
      }

      return outputUrl
    },
    getApplicationDocuments: async (applicationId: string): Promise<Record<string, string>> => {
      const url = `${functionsBaseUrl}/panda/shared/getApplicationDocuments`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ applicationId }),
      })

      if (!response.ok) {
        throw new Error('Failed to get application documents')
      }

      const data = await response.json()

      return data
    },
  },
  bank: {},

  client: {
    generateApplication: async (): Promise<string> => {
      const url = `${functionsBaseUrl}/panda/client/generateApplication`
      const method = 'GET'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
      })

      if (!response.ok) {
        throw new Error('Failed to generate application')
      }

      const data = await response.json()

      if (!data.url) {
        throw new Error('Failed to generate application')
      }

      return data.url
    },
    submitApplication: async (pandaPayload: PandaDocument): Promise<void> => {
      const url = `${functionsBaseUrl}/panda/client/submitApplication`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ pandaPayload }),
      })

      if (!response.ok) {
        throw new Error('Failed to complete application')
      }
    },
  },
  admin: {
    sendApplicationBackForCorrections: async (applicationId: string, correctionNotes: string): Promise<void> => {
      const url = `${functionsBaseUrl}/panda/admin/sendApplicationBackForCorrections`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ applicationId, correctionNotes }),
      })
    },
  },
}
