import React from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Grid,
  Link,
  IconButton,
} from "@mui/material";
import { Security as SecurityIcon } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { useMFA } from "hooks/auth/useMFA";
import { authTextFieldStyle } from "core/theme";
import { useAuth } from "hooks/auth/useAuth";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";



export const LoginMFACode: React.FC = () => {
  const navigate = useNavigate();
  const { verifyCodeAndSignIn, resolver, handleLoginWithMfaCancel } = useMFA();
  const { isLoading, setIsLoading } = useAuth();
  const [error, setError] = React.useState<string | null>(null);
  const [verificationCode, setVerificationCode] = React.useState("");

  React.useEffect(() => {
    if (!resolver) {
      navigate("/login", { replace: true });
    }
  }, [resolver, navigate]);

  const handleVerifyCode = async () => {
    if (!resolver) return;

    // setIsLoading(true);
    setError(null);

    try {
      await verifyCodeAndSignIn(verificationCode);
      // The auth state change will handle navigation
    } catch (err: any) {
      setError("Failed to verify code. Please try again.");
    } finally {
      //   setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleVerifyCode();
  };

  const handleCancel = async () => {
    setIsLoading(true);
    await handleLoginWithMfaCancel();
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
        mt: 4,
        px: 2,
      }}
    >
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            mb: { xs: 1, md: 2 },
          }}
        >
          <SecurityIcon sx={{ fontSize: 40 }} />
          <Typography variant="h4">Two-Factor Authentication</Typography>
        </Box>
        {/* <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "16px",
            marginY: "20px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "0.9rem",
              marginBottom: "4px",
            }}
          >
            Verifying login for:
          </Typography>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.9)",
              fontWeight: 500,
              fontSize: "1.1rem",
              wordBreak: "break-all",
            }}
          >
            {resolver?.hints[0]?.displayName || "Unknown User"}
          </Typography>
        </Box> */}
        <Typography variant="body1" color="textSecondary">
          Enter the 6-digit code from your authenticator app
        </Typography>
      </Box>

      <Box component="form" noValidate onSubmit={handleSubmit}>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TextField
          required
          fullWidth
          margin="normal"
          label="Verification Code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          inputProps={{ maxLength: 6 }}
          placeholder="000000"
          autoComplete="one-time-code"
          sx={{
            ...authTextFieldStyle,
            mb: 3,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "black",
              },
            },
          }}
          autoFocus
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={verificationCode.length !== 6 || isLoading}
          sx={{
            mt: 2,
            mb: 2,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            "&:hover": {
              bgcolor: 'primary.dark',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Verify Code"
          )}
        </Button>

        <Grid container sx={{ mt: 2 }} justifyContent="center">
          <Grid item>
            <Box
              onClick={handleCancel}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                padding: "8px 16px",
                cursor: "pointer",
                transition: "all 0.2s",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  border: "1px solid rgba(0, 0, 0, 0.24)",
                },
              }}
            >
              <ArrowBackIcon sx={{ fontSize: 20, marginRight: 1 }} />
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Cancel
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginMFACode;
